<div class="btn-group" mdbDropdown *ngIf="user">
    <a mdbDropdownToggle mdbWavesEffect type="button" class="dropdown-toggle waves-light" mdbWavesEffect
       style="height: auto; width: 50px">
      <img src="/assets/images/avatar.jpeg" alt=""
           class="img-fluid rounded-circle z-depth-0" ></a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" (click)="forgotPassword(user)">{{ user.name }}</a>
        <div class="dropdown-divider"></div>
        <a type="button" class="dropdown-item" (click)="logout()">logout</a>
      </div>
</div>