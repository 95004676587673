import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClientXsrfModule,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AuthModule } from "./auth/auth.module";
import { AgmCoreModule } from "@agm/core";
import { AppComponent } from "./app.component";
import {
    MDBSpinningPreloader,
    MDBBootstrapModulesPro,
    ToastModule,
} from "ng-uikit-pro-standard";
import { RootComponent } from "./components/root/root.component";
import { WebsiteHomePageComponent } from "./pages/website-home-page/website-home-page.component";
import { DashboardHomePageComponent } from "./pages/dashboard-home-page/dashboard-home-page.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { HeaderModule } from "./header/header.module";
import { FooterModule } from "./footer/footer.module";
import { BlocksModule } from "./blocks/blocks.module";
import { MobileModule } from "./mobile/mobile.module";
import { AuthInterceptor } from "./auth/services/auth.interceptor";
import { TopupComponent } from "./advertiser/profile/topup/topup.component";
import { TopupAdminComponent } from "./admin/profile/topup/topup.component";
import { PlanUpgradeComponent } from "./advertiser/profile/plan-upgrade/plan-upgrade.component";
// import { RemoveemojisDirective } from './directives/removeemojis.directive';

@NgModule({
    declarations: [
        AppComponent,
        RootComponent,
        WebsiteHomePageComponent,
        DashboardHomePageComponent,
        PageNotFoundComponent,
        TopupComponent,
        TopupAdminComponent,
        PlanUpgradeComponent
        // RemoveemojisDirective,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        AppRoutingModule,
        SharedModule,
        ToastModule.forRoot(),
        MDBBootstrapModulesPro.forRoot(),
        AgmCoreModule.forRoot({
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
            apiKey: "Your_api_key",
        }),
        HeaderModule,
        MobileModule,
        FooterModule,
        BlocksModule,
        AuthModule,
        NgbModule,
    ],
    providers: [
        MDBSpinningPreloader,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
