<div class="container" style="padding: 0;">
  <div class="row align-content-center" *ngIf="showHeader">
    <div class="col-12 price__tag">
      <div class="row"> 
        <div class="col" *ngIf="basicPostPrice?.price > 0"> 
          Basic Post: RM {{basicPostPrice.price}}
        </div>
        <div class="col" *ngIf="basicPostPrice?.price == 0"> 
          Basic Post: Free
        </div>
      </div>
      <div class="row">
        <div class="col">
          Valid For: {{basicPostPrice?.validity}} days
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row"> -->
    <!-- <div class="col-3"></div> -->
    <!-- <div class="col-8 text-center">
      <h5 class="yp-h5">Boost your ads:</h5>
    </div>
  </div> -->
  <div class="row boost-add">
    <div class="col-3"></div>
    <div class="col-sm-12 col-lg-6" style="padding:20px">
        <!-- <div class="col-3"></div> -->
        <div class="">
          <h5 class="yp-h5">Boost your ads:</h5>
        </div>
      <div class="pricing-by-post" [formGroup]="PBPFormGroup">
        <div class="pricing-by-post__item">
          <ng-container *ngFor="let addonPlan of addonPlans">
            <div class="" [ngClass]="{}">
              <div class="pricing-by-post__item-radio mb-2">

                <div class="form-check" style="padding-left:0px">
                  <!-- <input type="radio" class="form-check-input with-gap" id="{{addonPlan.name}}" mdbInput formControlName="payByPostPackage" [value]="addonPlan.id"> -->
                  <label class="form-check-label" [for]="addonPlan.category">
                    <div class="label" style="word-break:break-all;width:50%"> {{ addonPlan?.label }}</div>
                    <div class="pricing-by-post__item-title--link" style="text-align: right;width:50%">
                      <a type="button" (click)="openExampleModal(addonPlan?.label)">View sample</a>
                    </div>
                  </label>
                  <mdb-select [allowClear]="true" [enableSelectAll]="false" [notFoundMsg]="'Category not found'"
                    [customClass]="'categories-multi-select'" optionHeight="49" [options]="addonPlan.selection" (deselected)="deselectaddOnPlan($event)"
                    [multiple]="false" [outline]="true" [filterEnabled]="true" (selected)="getSub($event)" mdbValidate>
                  </mdb-select>
                  <!-- <mdb-select [allowClear]="true"-2 [enableSelectAll]="false" [notFoundMsg]="'Category not found'"
                        [customClass]="'categories-multi-select'" optionHeight="49" [multiple]="false" [outline]="true" [filterEnabled]="true" formControlName="mainCategory"
                        (selected)="getSub($event)" mdbValidate>
                          <mdb-select [allowClear]="true"-option *ngFor="let option of categoryOptions" [value]="option.value">{{ option.label }}</mdb-select-option>
                        </mdb-select-2> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div mdbModal #sampleModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="mysampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">Sample</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="sampleModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="/assets/images/{{ sampleImagePath }}" style="width: 100%; margin: auto;" *ngIf="sampleImagePath != ''">
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="sampleModal.hide()" mdbWavesEffect>Close</button>
        <!-- <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>Save changes</button> -->
      </div>
    </div>
  </div>
</div>