import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Product } from 'src/app/shared/interfaces/product';
import { CartItem } from 'src/app/shared/interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';

interface CartTotal {
  title: string;
  price: number;
  type: 'shipping'|'fee'|'tax'|'other';
}

interface CartData {
  items: CartItem[];
  quantity: number;
  subtotal: number;
  totals: CartTotal[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})

export class CartService {

  private data: CartData = {
    items: [],
    quantity: 0,
    subtotal: 0,
    totals: [],
    total: 0
  };

  private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
  private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
  private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
  private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
  private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
  private onAddingSubject$: Subject<Product> = new Subject();

  get items(): ReadonlyArray<CartItem> {
      return this.data.items;
  }

  get quantity(): number {
      return this.data.quantity;
  }

  readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
  readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
  readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
  readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
  readonly total$: Observable<number> = this.totalSubject$.asObservable();

  readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();


  constructor(@Inject(PLATFORM_ID) private platformId: any) {

    if (isPlatformBrowser(this.platformId)) {
      this.load();
    }

   }

  // Add to the cart
   add(product: Product, quantity: number, options: { name: string, value: string }[] = []): Observable<CartItem> {
     return timer(1000).pipe(map(() => {

      //  event emit that something added to cart
      this.onAddingSubject$.next(product);

      // check if the item new item or already in cart.
      let item: CartItem = this.items.find(eachItem => {
        // if product id different on the product id existed then obviously new cart item as well as options are different.
        if ( eachItem.product.id !== product.id || eachItem.options.length !== options.length ) {
          return false;
        }

        // assume same product id and same length of optionas array but details inside array different then consider as new item as well
        if (eachItem.options.length > 0) {
          for (const option of options) {
            if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
              return false;
            }
          }
        }

        // the item considered as existed already
        return true;
      });

      // if item new item just push to the cart data else just increase quantity of the item
      if (item) {
        item.quantity += quantity;
      } else {
        item = {product, quantity, options};

        this.data.items.push(item);
      }

      // Save and recalcalation the cart
      this.save();
      this.calc();

      // return the item
      return item;

     }));
   }

  // Update Cart
   update(updates: {item: CartItem, quantity: number}[]): Observable<void> {
     return timer(1000).pipe(
       map(() => {

        updates.forEach(update => {
          const item = this.items.find(eachItem => eachItem === update.item);

          if (item) {
            item.quantity = update.quantity;
          }

        });

        this.save();
        this.calc();

       })
     );
   }


  //  Delete From the cart
   remove(item: CartItem): Observable<void> {
     return timer(100).pipe(
       map(() => {
        this.data.items = this.data.items.filter(eachItem => eachItem !== item);

        this.save();
        this.calc();
       })
     );
   }

  // Private and helper functions
   private calc() {
      // Quantity and subtotal for each item in the cart data
      let quantity = 0;
      let subtotal = 0;

      this.items.forEach(item => {
        quantity += item.quantity;
        subtotal += item.product.price * item.quantity;
      });

      // Totals array.
      const totals: CartTotal[] = [];

      totals.push({
        title: 'Shipping',
        price: 25,
        type: 'shipping'
      });
      totals.push({
          title: 'Tax',
          price: subtotal * 0.20,
          type: 'tax'
      });

      // Cart Total. total for each item (subtotal) + totals array amount.
      const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price , 0);

      // assign each of this vars to cart Data.
      this.data.quantity = quantity;
      this.data.subtotal = subtotal;
      this.data.total = total;
      this.data.totals = totals;

      // emit the new event to update cart
      this.itemsSubject$.next(this.data.items);
      this.quantitySubject$.next(this.data.quantity);
      this.subtotalSubject$.next(this.data.subtotal);
      this.totalsSubject$.next(this.data.totals);
      this.totalSubject$.next(this.data.total);
   }

   private save(): void {
     localStorage.setItem('cartItems', JSON.stringify(this.data.items));
   }

   private load(): void {
     const cartItem = localStorage.getItem('cartItems');

     if (cartItem) {
       this.data.items = JSON.parse(cartItem);
     }
   }

 }
