<div class="site-header">

    <div class="site-header__row">
        <div class="site-header__left">
            <!-- Logo -->
            <div class="site-header__logo">
                <a routerLink="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100.5" height="40.935" viewBox="0 0 132.5 69.935">
                        <g id="Group_1324" data-name="Group 1324" transform="translate(-51.887 -102.807)">
                        <path id="Path_1" data-name="Path 1" d="M412.905,340.34l2.136-4.272c.65.3,1.244.607,1.866.843a11.281,11.281,0,0,0,6.5.584,6.969,6.969,0,0,0,5.291-4.766,33.153,33.153,0,0,0,.855-3.416.776.776,0,0,0-.04-.225c-.137.113-.252.191-.347.289a9.332,9.332,0,0,1-5.1,2.743,8.347,8.347,0,0,1-6.056-.971,7.193,7.193,0,0,1-3.255-4.9,13.039,13.039,0,0,1,.376-6.364,15.525,15.525,0,0,1,4.256-7.2,12.041,12.041,0,0,1,7.366-3.46,7.333,7.333,0,0,1,4.646.933,4.7,4.7,0,0,1,1.746,1.926c.083.165.173.326.321.6.4-1.021.575-2,.886-2.921a26.458,26.458,0,0,1,4.949,0c-.023.137-.036.281-.072.42-1.085,4.2-1.865,8.458-2.715,12.7-.488,2.435-.942,4.877-1.485,7.3a17.984,17.984,0,0,1-3.044,7.046,11.721,11.721,0,0,1-5.991,4.128,17.868,17.868,0,0,1-6.366.774,14.638,14.638,0,0,1-6.066-1.421C413.361,340.625,413.177,340.5,412.905,340.34Zm18.855-22.631-.062-.01c0-.131,0-.261,0-.392a3.715,3.715,0,0,0-3.87-3.733,6.115,6.115,0,0,0-4.5,2.071,10.34,10.34,0,0,0-2.529,4.921,8.543,8.543,0,0,0-.111,4.209,3.969,3.969,0,0,0,4.373,3.217,6.232,6.232,0,0,0,5.617-4.8c.3-1.251.539-2.517.792-3.779C431.583,318.848,431.664,318.277,431.76,317.709Z" transform="translate(-296.381 -169.43)"/>
                        <path id="Path_2" data-name="Path 2" d="M605.361,183.5c-.428-5.45-1.239-10.834-1.087-16.293l-.151-.025c-.09.214-.176.429-.271.641-1.019,2.286-1.98,4.6-3.074,6.849-1.381,2.839-2.873,5.624-4.311,8.435-.136.267-.283.459-.627.457-1.534-.011-3.067,0-4.6-.008a2.3,2.3,0,0,1-.365-.067c-.051-.43-.11-.85-.15-1.272-.088-.91-.166-1.821-.252-2.731q-.206-2.2-.416-4.4c-.087-.929-.171-1.858-.258-2.787q-.206-2.2-.416-4.4c-.125-1.337-.246-2.675-.371-4.013-.061-.65-.138-1.3-.187-1.949-.025-.331,0-.666,0-.995a40.35,40.35,0,0,1,5.753-.033c.012.181.032.379.037.577q.07,3.277.136,6.553.053,2.492.111,4.984c.024,1.026.069,2.052.074,3.078,0,.652-.051,1.3-.077,1.957a.738.738,0,0,0,.052.342c2.59-5.941,5.549-11.7,8.376-17.53h4.685c.482,5.854,1.09,11.667,1.183,17.529,2.184-6,5.039-11.69,7.582-17.531h5.626c-.1.237-.159.419-.247.588q-2.238,4.333-4.48,8.663-2.378,4.608-4.746,9.22c-.641,1.246-1.288,2.49-1.924,3.738-.144.282-.294.493-.666.49-1.515-.015-3.03-.006-4.544-.009A3.623,3.623,0,0,1,605.361,183.5Z" transform="translate(-440.793 -47.626)"/>
                        <path id="Path_3" data-name="Path 3" d="M145.059,330.113l-2.308,11.456a35.836,35.836,0,0,1-5.732.071c2.015-10.627,4.43-21.172,6.236-31.858h5.014l-.41,3.45a2.65,2.65,0,0,0,.342-.249A10.808,10.808,0,0,1,157,309.3a6.894,6.894,0,0,1,6.449,4.667,11.1,11.1,0,0,1,.586,5.127,17.9,17.9,0,0,1-1.707,6.12,13.565,13.565,0,0,1-7.886,7.226,10.516,10.516,0,0,1-3.244.579,7.726,7.726,0,0,1-3.65-.718,5.024,5.024,0,0,1-2.178-1.955c-.054-.09-.123-.171-.185-.256Zm1.234-6.007.065.013a3.957,3.957,0,0,0,4.064,4.45,6.206,6.206,0,0,0,4.444-1.69,10.029,10.029,0,0,0,2.852-5.054,8.909,8.909,0,0,0,.126-4.971,3.945,3.945,0,0,0-4.231-3.05,6.419,6.419,0,0,0-4.578,2.212,7.6,7.6,0,0,0-1.927,3.918C146.849,321.326,146.566,322.715,146.293,324.106Z" transform="translate(-69.89 -169.509)"/>
                        <path id="Path_4" data-name="Path 4" d="M448.013,181.894a11.6,11.6,0,0,1-4.284-.819,7.716,7.716,0,0,1-4.912-6.261,14.677,14.677,0,0,1,1.193-8.092,13.98,13.98,0,0,1,3.039-4.533,13.772,13.772,0,0,1,4.966-3.179,14.592,14.592,0,0,1,6.282-.848,8.967,8.967,0,0,1,5.294,2.048,7.726,7.726,0,0,1,2.431,3.943,12.011,12.011,0,0,1,.085,5.549,15.551,15.551,0,0,1-2.669,6.293,13.879,13.879,0,0,1-8.654,5.563C449.878,181.747,448.938,181.786,448.013,181.894Zm8.35-14.623a17.373,17.373,0,0,0-.309-1.958,3.752,3.752,0,0,0-3.234-3.012,5.941,5.941,0,0,0-5.173,2.025,10.931,10.931,0,0,0-2.505,4.7,10.23,10.23,0,0,0-.288,5.09,4.732,4.732,0,0,0,1.432,2.608,4.1,4.1,0,0,0,3.634.86,6.565,6.565,0,0,0,4.176-2.9A12.936,12.936,0,0,0,456.363,167.271Z" transform="translate(-317.527 -45.403)"/>
                        <path id="Path_5" data-name="Path 5" d="M55.788,161.117H62.1l2.122,16.439,8.119-16.438h6.086c-.32.579-.591,1.081-.872,1.578q-3.234,5.7-6.47,11.408c-2.016,3.561-4.036,7.121-6.351,10.5a31.241,31.241,0,0,1-4.305,5.224,16.139,16.139,0,0,1-7.481,4.1c-.195.045-.4.064-.688.11-.193-1.6-.252-3.152-.371-4.726.2-.078.351-.146.507-.2a13.2,13.2,0,0,0,5.056-2.92,23.038,23.038,0,0,0,1.868-2,2.512,2.512,0,0,0,.578-2.29c-.542-2.518-1.018-5.05-1.521-7.576q-1.257-6.317-2.513-12.634C55.826,161.515,55.815,161.331,55.788,161.117Z" transform="translate(0 -47.87)"/>
                        <path id="Path_6" data-name="Path 6" d="M309.181,332.44h-5.033a16.149,16.149,0,0,1-.007-2.44,3.36,3.36,0,0,0-.4.262,10.248,10.248,0,0,1-5.485,2.616,7.574,7.574,0,0,1-4.708-.56A4.709,4.709,0,0,1,290.819,328c-.089-3.706,1.731-6.319,4.831-8.13a16.042,16.042,0,0,1,6.441-1.973c1.2-.132,2.42-.161,3.631-.239.11-.007.22-.029.32-.043a2.882,2.882,0,0,0-1.665-4.021,7.276,7.276,0,0,0-2.827-.267,14.643,14.643,0,0,0-5.359,1.522,1.789,1.789,0,0,1-.27.078l-.365-3.654c.175-.1.326-.212.492-.288a19.194,19.194,0,0,1,6.315-1.638,14.734,14.734,0,0,1,4.845.246,5.684,5.684,0,0,1,4.131,3.222,7.422,7.422,0,0,1,.578,2.611,19.025,19.025,0,0,1-.6,4.989c-.566,2.838-1.157,5.671-1.695,8.514-.194,1.024-.265,2.071-.393,3.108C309.22,332.151,309.2,332.26,309.181,332.44Zm-3.75-11.031c-.279-.016-.463-.043-.645-.035-.615.029-1.233.046-1.844.111a10.13,10.13,0,0,0-3.911,1.106,4.282,4.282,0,0,0-2.308,3.072,4.2,4.2,0,0,0-.013,1.444,2.276,2.276,0,0,0,2.3,1.773,5.722,5.722,0,0,0,4.243-1.58,4.69,4.69,0,0,0,1.6-2.636c.166-.881.348-1.758.516-2.638C305.4,321.849,305.4,321.666,305.431,321.408Z" transform="translate(-196.151 -169.492)"/>
                        <path id="Path_7" data-name="Path 7" d="M207.514,180.391a13.336,13.336,0,0,1-2.84.88,22.748,22.748,0,0,1-6.235.551,10.62,10.62,0,0,1-5.469-1.626,7.038,7.038,0,0,1-3.221-5.459,15.548,15.548,0,0,1,5.358-13.424,12.05,12.05,0,0,1,8.528-3.149,9.99,9.99,0,0,1,3.09.464,6.357,6.357,0,0,1,4.212,4.387,12.205,12.205,0,0,1,.173,6.04,11.041,11.041,0,0,1-.747,2.568H195.284a1.448,1.448,0,0,0-.148.344,4.382,4.382,0,0,0,3.059,5.093,7.727,7.727,0,0,0,2.362.421,19.89,19.89,0,0,0,6.472-.861c.139-.043.284-.069.485-.118Zm-1.556-12.814a5.769,5.769,0,0,0,.14-2.393,3.3,3.3,0,0,0-2.933-3.066,5.673,5.673,0,0,0-4.178,1.106,8.475,8.475,0,0,0-2.811,3.9,4.257,4.257,0,0,0-.117.45Z" transform="translate(-113.124 -45.445)"/>
                        <path id="Path_8" data-name="Path 8" d="M585.425,322.714H570.262a4.322,4.322,0,0,0,1.862,4.957,6.968,6.968,0,0,0,2.986.872,19.41,19.41,0,0,0,6.964-.858,1.986,1.986,0,0,1,.217-.054,1,1,0,0,1,.174.01V331.5c-.095.041-.257.124-.427.183a19.641,19.641,0,0,1-5.249,1.111c-1.192.073-2.391.138-3.583.093a10.038,10.038,0,0,1-5.249-1.583,7.085,7.085,0,0,1-3.18-5.006,14.84,14.84,0,0,1,1.3-8.465,15.124,15.124,0,0,1,3-4.5,12.806,12.806,0,0,1,7.836-3.986,11.272,11.272,0,0,1,4.39.267,6.376,6.376,0,0,1,4.647,4.72,12.637,12.637,0,0,1,.076,5.994C585.872,321.1,585.64,321.871,585.425,322.714Zm-4.544-4.075a.757.757,0,0,0,.108-.172,5.317,5.317,0,0,0-.162-3.138,2.984,2.984,0,0,0-1.755-1.854,4.948,4.948,0,0,0-3.974.18,7.8,7.8,0,0,0-4,4.683,1.017,1.017,0,0,0,0,.3Z" transform="translate(-420.926 -169.475)"/>
                        <path id="Path_9" data-name="Path 9" d="M700.763,314.589a9.438,9.438,0,0,0-3.035-1.146,5.793,5.793,0,0,0-3.311.182,2.833,2.833,0,0,0-1.889,2.248,1.433,1.433,0,0,0,.375,1.345,4.382,4.382,0,0,0,1.076.789c.819.408,1.668.754,2.508,1.118a8.468,8.468,0,0,1,2.935,1.917,4.46,4.46,0,0,1,1.254,3.089,7.88,7.88,0,0,1-4.25,7.255,12.7,12.7,0,0,1-6.634,1.543,14.066,14.066,0,0,1-5.106-.884c-.464-.179-.9-.418-1.412-.656l2.018-4.2c.235.122.414.21.589.305a10.364,10.364,0,0,0,4.817,1.338,4.892,4.892,0,0,0,3.042-.8,2.805,2.805,0,0,0,1.211-2.565,1.552,1.552,0,0,0-.778-1.3,23.091,23.091,0,0,0-2.439-1.241,12.242,12.242,0,0,1-2.988-1.636,4.776,4.776,0,0,1-1.8-5.131,8.336,8.336,0,0,1,4.471-5.6,13.15,13.15,0,0,1,10.539-.373c.25.109.482.258.774.417Z" transform="translate(-518.343 -169.484)"/>
                        <path id="Path_10" data-name="Path 10" d="M381.287,135.9h-5.565a.721.721,0,0,1-.075-.07.109.109,0,0,1-.023-.051.833.833,0,0,1-.009-.221q3.266-16.224,6.538-32.448a2.231,2.231,0,0,1,.114-.3h5.552a.366.366,0,0,1,.059.048.116.116,0,0,1,.02.052.527.527,0,0,1,.016.165c-.193.99-.385,1.979-.585,2.968Q384.316,120.941,381.287,135.9Z" transform="translate(-265.764 0)"/>
                        <path id="Path_11" data-name="Path 11" d="M321.127,135.892h-5.62c-.048-.435,6.3-32.237,6.6-33.081h5.675Z" transform="translate(-216.421 -0.003)"/>
                        </g>
                    </svg>
                </a>
            </div>

            <!-- bars Menu -->
            <!-- <div class="site-header__bars"> -->
                <!-- <app-header-side-nav></app-header-side-nav> -->
                <!-- <app-icon name="bars" size="32x15" ></app-icon> -->
                <!-- <i class="fas fa-home" style="font-size: xx-large;cursor: pointer;" (click)="showConfirmationModal()"></i> -->
            <!-- </div> -->

        </div>
        <div class="site-header__right">
                <!-- Search -->
                <!-- <div class="site-header__search">
                    <app-header-search></app-header-search>
                </div> -->
                <div class="site-header__search">
                    
                </div>

                <div class="site-header__link">
                    <a href="{{ tutorialPageUrl }}" target="_blank">Need Help?</a>
                </div>

                <div class="site-header__link">
                    <a href="{{ faqPageUrl }}" target="_blank"><img src="assets/images/faq.png" width="50px"></a>
                </div>

                <!-- Website back Link -->
                <div class="site-header__link">
                    <!-- <a href="{{ host }}"  mdbTooltip="YellowPages Home Page" placement="top">Yellowpages</a> -->
                    <i class="fas fa-home" style="font-size: xx-large;cursor: pointer;" (click)="showConfirmationModal()"></i>
                </div>

                <!-- Icons (Messaging) -->
                <!-- <div class="site-header__icons">
                    <app-icon name="msg" size="22"  mdbTooltip="Messages" placement="top"></app-icon>
                    <div class="site-header__counter">14</div>
                </div> -->

                <!-- user Avatar dropdown -->
                <div class="site-header__user-avatar">
                    <app-header-user-dropdown></app-header-user-dropdown>
                </div>
            </div>
        </div>




</div>
<div class="modal fade" mdbModal id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="suspendAModelCenterTitle"
  aria-hidden="true" #confirmationModal="mdbModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideConfirmationModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="col-12" style="padding: 15px;">
            Please save all your works. Click 'Yes' if you are ready.
        </div>
        <div class="row justify-content-md-center">
            <div style="margin: auto;">
                <button type="button" mdbBtn style="background-color: black !important;" class="btn btn-primary" (click)="hideConfirmationModal()" mdbWavesEffect>CANCEL</button>
                <button type="button" mdbBtn  class="btn btn-primary" (click)="redirectToMainPage()" mdbWavesEffect>YES</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>