import { Injectable } from '@angular/core';
import { PaymentService } from 'src/app/shared/services/api/payment.service'

@Injectable({
    providedIn: 'root'
})

export class PaymentUtilsService {

    constructor(private paymentService: PaymentService,) { }

    doPaymentRequest(code: string, repay: boolean) {
        this.paymentService.getPaymentRequest(code, repay).subscribe(payment_request => {
            // console.log('payment util lah');
            // disabled showing payment details
            const payform = document.createElement('form');
            payform.hidden = true;
            payform.method = 'POST';
            payform.id = 'paymentform';
            payform.action = payment_request['payment_url'];
            Object.entries(payment_request['payload']).forEach(([key, value]) => {
                const payField = document.createElement('input');
                payField.type = 'text';
                payField.name = key;
                payField.value = '' + value;
                payform.appendChild(payField);
            });
            const payField = document.createElement('button');
            payField.type = 'submit';
            payField.value = 'submit';
            payform.appendChild(payField);
            // change this line on dev
            document.body.appendChild(payform);
            payform.submit();
        });
    }
}
