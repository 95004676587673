import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CurrencyFormatOptions } from 'src/app/shared/interfaces/currency-format-options';

interface CurrencyServiceData {
  options: CurrencyFormatOptions;
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  private currencyData: CurrencyServiceData = {
    options: {}
  };

  private currencyChangeSub$: Subject<CurrencyFormatOptions> = new Subject();
  currencyChangeObs$: Observable<CurrencyFormatOptions> = this.currencyChangeSub$.asObservable();

  get options(): CurrencyFormatOptions {
    return this.currencyData.options;
  }

  set options(val: CurrencyFormatOptions) {
    this.currencyData.options = val;
    this.currencyChangeSub$.next(val);
  }

  constructor() {
   }

}
