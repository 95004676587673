<mdb-accordion [multiple]="false">
    <mdb-accordion-item [collapsed]="true" [customClass]="'side-nav-link-item'">
        <a routerLink="{{link.url}}" routerLinkActive="side-nav-link-item__active" [routerLinkActiveOptions]="{exact: true}">
            <mdb-accordion-item-head [indicator]="link.hasSubLink" [customClass]="'side-nav-link-item__head'">
                <div style="display: inline-flex;">
                    <span class="mr-3 icon"><span class="material-icons">{{link.icon}}</span></span>


                    <span class="d-none d-xl-inline " style="padding-top: 10px;">{{ link.title }}</span>
                </div>
            </mdb-accordion-item-head>
        </a>
        <mdb-accordion-item-body *ngIf="link.hasSubLink && link.subLinks.length > 0">
            <ul>
                <li *ngFor="let sub of link.subLinks">
                    <a routerLink="{{sub.url}}" routerLinkActive="side-nav-link-item__active" mdbWavesEffect>{{ sub.title }}</a>
                </li>
            </ul>
        </mdb-accordion-item-body>
    </mdb-accordion-item>
</mdb-accordion>