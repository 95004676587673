import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddOns, AddOnsGroup } from 'src/app/shared/interfaces/addOns';
import { AddOnsService } from 'src/app/shared/services/api/add-ons.service';



@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss']
})
export class AddOnsComponent implements OnInit {

  @Input() addOnsGroup: AddOnsGroup[];
  @Input() addOnsFormGroup: FormGroup;
  @Output() resetAddOns: EventEmitter<boolean> = new EventEmitter();

  get ADS_POSTING() { return this.addOnsFormGroup.get('ADS_POSTING'); }
  get BUMPS_UP() { return this.addOnsFormGroup.get('BUMPS_UP'); }
  get FEATURED_POST_HOME() { return this.addOnsFormGroup.get('FEATURED_POST_HOME'); }
  get FEATURED_BANNER_HOME() { return this.addOnsFormGroup.get('FEATURED_BANNER_HOME'); }
  get FEATURED_POST_CATEGORY() { return this.addOnsFormGroup.get('FEATURED_POST_CATEGORY'); }

  constructor() { 

  }

  ngOnInit(): void {

  }

  resetAddOnsForm(){
    this.resetAddOns.emit();
  }

}
