import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service';
import { environment } from 'src/environments/environment';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import {LocationService} from 'src/app/shared/services/api/location.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('forgetPassPopUp') forgetPassPopUp: ModalDirective;

    forgotForm: FormGroup = new FormGroup({
        email: new FormControl('', [ Validators.required, Validators.email ]),
        country: new FormControl(1, []),
        type: new FormControl('email', []),
        phone_number: new FormControl('', []),
        phone_number_country: new FormControl('60', []),
        code: new FormControl('', []),
        password: new FormControl('', [Validators.required]),
        password_confirmation: new FormControl('', [Validators.required]),
      });

    isLoading = false;

    homePageUrl = environment.homePage;
    faqPageUrl = environment.faqPageUrl;

    loginFormGroup: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });

    get email() { return this.loginFormGroup.get('email'); }
    get password() { return this.loginFormGroup.get('password'); }

    countries;
    verficationLoading = false;

    constructor(private auth: AuthService, private router: Router, private message: ToastService, private userUtilsService: UserUtilsService, protected changeDetectorRef: ChangeDetectorRef, private ls: LocationService,) {

    }

    ngOnInit(): void {
        this.auth.checkLogin();
        this.updateValidation(this.forgotForm, 'email');
        this.forgotForm.controls.type.valueChanges.subscribe(v => {
            this.updateValidation(this.forgotForm,  v);
          });
        this.ls.countries().subscribe(res => {
            this.countries = res.data;
        });
    }

    updateValidation(form, v): void {
        if (v === 'email') {
          form.get('email').setValidators([Validators.required, Validators.email]);
          form.get('phone_number').clearValidators();
          form.get('phone_number').updateValueAndValidity();
        } else {
          form.get('phone_number').setValidators([Validators.required]);
          form.get('email').clearValidators();
          form.get('email').updateValueAndValidity();
        }
        this.changeDetectorRef.detectChanges();
    }

    onLogin() {
        this.isLoading = true;
        // if (this.password.value) {
        //     sessionStorage.setItem('userPassword', this.password.value);
        //     sessionStorage.setItem('userEmail', this.email.value);
        // }
        this.auth.cookieInit().subscribe(res => {
            this.auth.login(this.email.value, this.password.value).subscribe(
                (data: any) => {
                    this.auth.getUser().subscribe(
                        (user: any) => {
                            if (!this.userUtilsService.verifyUserRole(user.roles, "admin") && !this.userUtilsService.verifyUserRole(user.roles, "advertiser")) {
                                this.message.error('Please upgrade to advertiser', null, {positionClass: 'md-toast-bottom-right'});
                                this.auth.logout();
                                this.isLoading = false;
                            } else {
                                if (this.userUtilsService.verifyUserRole(user.roles, "admin")) {
                                    this.router.navigate(['admin/dashboard']);
                                } else {
                                    this.router.navigate(['advertiser/dashboard']);
                                }

                                this.message.success('Welcome to dashboard', null, {positionClass: 'md-toast-bottom-right'});
                                this.isLoading = false;
                            }
                    });

                },
                errorMessage => {
                    this.message.error(errorMessage.error.message, null, {positionClass: 'md-toast-bottom-right'});
                    this.isLoading = false;
                }
            );
        });

    }

    openModal(){
        this.forgetPassPopUp.show();
    }

    forgot(): void {
        const formData = this.forgotForm.value;
        if (formData.phone_number) {
            formData.phone_number = formData.phone_number_country + formData.phone_number;
            delete formData.email;
        } else {
            delete formData.phone_number;
        }
        this.auth.reset(formData).subscribe(r => {
            this.message.success(r.message, null, {positionClass: 'md-toast-bottom-right'});
            this.forgetPassPopUp.hide();
        },
        err =>{
            this.message.error(err.error.message, null, {positionClass: 'md-toast-bottom-right'});
        });
    }

    setCountryCode(form, field1, field2): void {
        form.controls[field1].setValue(this.countries.find(country => country.id = form.controls[field2].value).calling_code);
    }

    getVerification(type): void {
        if (type === 'password')  {
            const formData = this.forgotForm.value;
            if (formData.phone_number) {
                formData.phone_number = formData.phone_number_country + formData.phone_number;
                delete formData.email;
            } else {
                delete formData.phone_number;
            }
            this.auth.password_verification(formData).subscribe(r => {
            this.verficationLoading = false;
            this.message.success(r.message, null, {positionClass: 'md-toast-bottom-right'});
            },
            err =>{
                this.message.error(err.error.message, null, {positionClass: 'md-toast-bottom-right'});
            });
        }
    }


}
