import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { BlocksModule } from 'src/app/blocks/blocks.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { HeaderUserDropdownComponent } from './components/header-user-dropdown/header-user-dropdown.component';
import { HeaderSideNavComponent } from './components/header-side-nav/header-side-nav.component';



@NgModule({
  declarations: [HeaderComponent, HeaderSearchComponent, HeaderUserDropdownComponent, HeaderSideNavComponent],
  imports: [
    CommonModule,
    SharedModule,
    MDBBootstrapModulesPro,
    BlocksModule,
    RouterModule
  ],
  exports: [HeaderComponent, HeaderSideNavComponent, HeaderSearchComponent]
})
export class HeaderModule { }
