import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PrincipalService } from 'src/app/core/principal.service';

@Injectable({ providedIn: 'root' })
export class CheckProfileCompleteService implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private principal: PrincipalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkProfileComplete();
    }

    checkProfileComplete(): Promise<boolean> {
        return Promise.resolve(
            this.principal.hasCompleteProfile().then(response => {
                if (response) {
                    return true;
                }

                // this.router.navigate(['accessdenied']);
                this.router.navigate(['/advertiser/change-password']);

                return false;
            })
        );
    }
}
