<!-- Plan details Row -->
<dl class="row">
    <dd class="col-sm-5">
        Choosen Plan:
        <span *ngIf="(pricingPost.item$|async).selectedPlan && (pricingPost.item$|async).selectedPlan.id == 1">
            <mdb-badge default="true">GOLD</mdb-badge>
        </span>
        <span *ngIf="(pricingPost.item$|async).selectedPlan && (pricingPost.item$|async).selectedPlan.id == 2">
            <mdb-badge primary="true">SILIVER</mdb-badge>
        </span>
        <span *ngIf="(pricingPost.item$|async).selectedPlan && (pricingPost.item$|async).selectedPlan.id == 3">
            <mdb-badge warning="true">BRONZE</mdb-badge>
        </span>
    </dd>
    <dd class="col-sm-7">
        <span *ngIf="(pricingPost.item$|async).selectedPlan let sPlan">
            {{ sPlan.tokens }} Tokens ( {{ sPlan.price}} RM)
        </span>
        <span *ngIf="(pricingPost.item$|async).selectedPBP let pbp">
            {{ (pricingPost.payableAmount$|async).tokensAmount  }} Tokens ( {{ (pricingPost.payableAmount$|async).moneyAmount }} RM)
        </span>
        
    </dd>
</dl>

<hr />
<!-- Current Post Details  -->
<dl class="row" *ngIf="(pricingPost.item$|async).currentPostPrice && (pricingPost.item$|async).currentPostPrice.length > 0">
    <dd class="col-sm-5">
        ( {{ (pricingPost.item$|async).currentPostPrice.length }} ) main Categories:
    </dd>
    <dd class="col-sm-7" *ngIf="postAmountToken || postAmountRM">
        {{ postAmountToken }} Tokens ({{ postAmountRM }} RM)
    </dd>
</dl>
<dl class="row" *ngIf="(pricingPost.item$|async).selectedAddOns.length > 0">
    <dd class="col-sm-5">
        AddOns Choosen: 
    </dd>
    <dd class="col-sm-7" *ngIf="addOnsAmountToken || addOnsAmountRM">
        {{ addOnsAmountToken }} Tokens ({{ addOnsAmountRM }} RM)
    </dd>
</dl>

<hr />
<dl class="row" *ngIf="(pricingPost.totalPostCost$|async) let totalPost">
    <dt class="col-sm-5">
        Total Cost of Post: 
    </dt>
    <dt class="col-sm-7">
        {{ totalPost.tokensAmount }} Tokens ({{ totalPost.moneyAmount }} RM)
    </dt>
</dl>

<hr />
<!-- Balance Details -->
<dl class="row" >
    <dd class="col-sm-5">
        New Balance: 
    </dd>
    <dd class="col-sm-7">
        {{ (pricingPost.futureBalance$ | async ).tokensAmount}} Tokens ({{ (pricingPost.futureBalance$ | async ).moneyAmount}} RM)
    </dd>
</dl>

<hr />
<!-- Payable amount details -->
<dl class="row">
    <dt class="col-sm-5">
        Total Payable amount:
    </dt>
    <dt class="col-sm-7">
        <span>({{ (pricingPost.futureBalance$ | async ).tokensAmount }} ) Tokens ({{ (pricingPost.futureBalance$ | async ).moneyAmount }} RM)</span>
        <!-- <span *ngIf="!(pricingPost.insufficient$|async)">0 Tokens (00.00 RM)</span> -->
    </dt>
</dl>