import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AddonUtilsService {

    constructor() { }

    convertAddonCategory(input: string) {
        if (input == 'BUMPS_UP') {
            return 'Bumps Up';
        }

        if (input == 'FEATURED_POST_HOME') {
            return 'Featured Post Home';
        }

        if (input == 'FEATURED_BANNER_HOME') {
            return 'Featured Banner Home';
        }

        if (input == 'FEATURED_POST_CATEGORY') {
            return 'Featured Post Category';
        }
    }
}