import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckCompany } from 'src/app/shared/interfaces/advertiser-profile';


@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  @Input() newCompanyFormGroup: FormGroup;

  @Output() companyCheck: EventEmitter<CheckCompany> = new EventEmitter();

  get name() { return this.newCompanyFormGroup.get('name'); }
  get company_no() { return this.newCompanyFormGroup.get('company_no'); }

  constructor() { }

  ngOnInit(): void {
  }

  onSubmitValues(values: CheckCompany) {
    this.companyCheck.emit(values);
  }

}
