

<div class="addresses-list" style="flex-wrap: nowrap; overflow: auto;">
    
    <!-- <a (click)="addressModal.show()" class="addresses-list__item addresses-list__item--new">
        <div class="addresses-list__plus"></div>
    </a>
    <div class="addresses-list__divider"></div> -->
    <div class="addresses-list__item address-card" style="min-width: 250px;" *ngFor="let address of newAddresses;let indexOfelement=index;">
        <!-- <div class="address-card__badge" *ngIf="address[0] !== ''">Default</div> -->
        <div class="address-card__body" style="min-width: 250px;">
            <div class="address-card__row">
                <div class="address-card__row-title"><b>Name</b></div>
                <div class="address-card__row-content">{{ address.name }}</div>
            </div>
            <div class="address-card__row">
                <div class="address-card__row-title"><b>Address
                </b></div>
                <!-- {{ address.country_id }} -->
                {{ address.address_line1 }} - {{ address.address_line2 }}
                <br> {{ address.postcode }}, {{ address?.city?.name || address.city}}, {{ address?.state?.name }}
                <br> 
                {{ address?.country?.name }}
            </div>
            <div class="address-card__row">
                <div class="address-card__row-title"><b>Phone Number</b></div>
                <div class="address-card__row-content">{{ address.company_phone_number }}</div>
            </div>
            <div class="address-card__row">
                <div class="address-card__row-title"><b>Fax Number</b></div>
                <div class="address-card__row-content">{{ address.fax_number }}</div>
            </div>
            <div class="address-card__row">
                <div class="address-card__row-title"><b>Mobile Number</b></div>
                <div class="address-card__row-content">{{ address.phone_number }}</div>
            </div>
            <div class="address-card__row">
                <div class="address-card__row-title">Email Address</div>
                <div class="address-card__row-content">{{ address.contact_email }}</div>
            </div>
            <div class="address-card__footer">
                <input type="radio" class="form-check-input with-gap" id="default{{indexOfelement}}" name="default" [checked]="address?.default==1"
                [value]="address.default" (click)="defaultSelection($event, indexOfelement)" mdbInput>
                <label class="form-check-label" for="default{{indexOfelement}}">Default</label>&nbsp;&nbsp;
                <a type="button" (click)="editAddress(indexOfelement)">Edit</a>&nbsp;&nbsp;
                <a type="button" (click)="removeAddress(indexOfelement)">Remove</a>
            </div>
        </div>
    </div>




</div>

<!-- Create New Company Modal -->
<!-- <div mdbModal #addressModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myaddressModalLabel"
    aria-hidden="true" [config]="{ ignoreBackdropClick: true}" (open)="initForm()" (closed)="onCloseModal()">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="addressModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="create-new-company modal-title w-100" id="myModalLabel">Add Post Contact Information</h4>
            </div>
            <div class="modal-body pb-3">
                <form [formGroup]="addressForm">
                    <div class="form-group">
                        <label for="address_line1">Address 1</label>
                        <input mdbInput type="text" class="form-control" id="addres1" formControlName="address_line1">
                    </div>

                    <div class="form-group">
                        <label for="address_line2">Address 2</label>
                        <input mdbInput type="text" class="form-control" id="addres2" formControlName="address_line2">
                    </div>

                    <div class="form-group">
                        <label for="country_id">Country</label>
                        <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true" [customClass]="'country-select'"
                            formControlName="country_id"></mdb-select>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="state_id">State
                            </label>
                            <mdb-select [allowClear]="true" [options]="optionsState" [outline]="true" [filterEnabled]="true" [customClass]="'state-select'"
                                  formControlName="state_id"></mdb-select>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="postcode">Postcode</label>
                            <input mdbInput type="text" class="form-control" id="postcode" formControlName="postcode">
                        </div>
                    </div>

                    <div class="form-row">

                        <div class="form-group col-md-6">
                            <label for="company_phone_number">Phone
                            </label>
                            <input mdbInput type="tel" class="form-control" id="company_phone_number" formControlName="company_phone_number">

                        </div>

                        <div class="form-group col-md-6">
                            <label for="phone_number">Mobile</label>
                            <input mdbInput type="tel" class="form-control" id="phone_number" formControlName="phone_number">
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="email">Email</label>
                        <input mdbInput type="email" class="form-control" id="email" formControlName="email">
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" size="md" mdbBtn color="dark" mdbWavesEffect (click)="addressModal.hide()">Cancel</button>
                <button type="button" size="md" mdbBtn color="primary" mdbWavesEffect [disabled]="!addressForm.valid" (click)="saveAddress()">Add Contact Information</button>
            </div>

        </div>
    </div>
</div> -->
