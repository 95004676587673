<div class="website-home-page" >

    <ng-container *ngIf="isNewUser; else existingUser">

        <div class="website-home-page__new-user">
            <div class="website-home-page__new-user--greetings">
                Hello 
            </div>

            <div class="website-home-page__new-user--icon">
                <app-icon name="dashboard-118" size="118"></app-icon>
                <!-- <p>Your dashboard looks empty.</p> -->
            </div>

            <div class="website-home-page__new-user--btns">
                <button type="button" mdbBtn color="orange" mdbWavesEffect block="true" class="website-home-page__new-user--btns-primary" >Create your Ad post</button>
                <button type="button" mdbBtn color="dark" mdbWavesEffect block="true">Add a new company profile</button>
            </div>

        </div>

    
    </ng-container>
    <ng-template #existingUser>

        <div class="website-home-page__existing-user">

            <div class="website-home-page__existing-user--name">
                Hello 
            </div>

            <div class="website-home-page__existing-user--profile">
                <app-profile-widget [posts]="posts"></app-profile-widget>
            </div>

            <div class="website-home-page__existing-user--inquiries">
               <app-inquiries-widget [inquiries]="inquiries"></app-inquiries-widget>
            </div>

            <div class="website-home-page__existing-user--traffic">
              <app-traffic-widget [traffics]="traffics"></app-traffic-widget>
            </div>

            <div class="website-home-page__existing-user--yPoints">
              <app-y-points-widget [yPoints]="yPoints"></app-y-points-widget>
            </div>

        </div>
        
    </ng-template>

</div>




