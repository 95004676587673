import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { apiUrl } from 'src/app/shared/data/token';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
// import { State } from '../../interfaces/state';
// import { any } from 'ng-uikit-pro-standard';
import { ApiTokenService } from 'src/app/auth/services/api-token.service';
import { Observable } from 'rxjs';
import { Category } from 'src/app/shared/interfaces/category';

@Injectable({
  providedIn: 'root',
})
export class SearchInvoiceService {
  constructor(private http: HttpClient, private apiHeader: ApiTokenService) {}

  exportInvoiceData(payLoad?: any) {
    return this.http
      .post(`${environment.apiUrl}/advertiser/search/invoice`, payLoad, {
        headers: this.apiHeader.fetchToken(),
        withCredentials: true,
        responseType: 'blob',
      })
      .pipe(map((res) => (res ? res : null)));
  }
  exportData(payLoad: any, path: any) {
    return this.http.post(`${environment.apiUrl}${path}`, payLoad, {
      headers: this.apiHeader.fetchToken(), withCredentials: true,
      responseType: 'blob'
    }).pipe(
      map(res => res ? res : null)
    );
  }
  downloadInvoice(path?: any) {
    path = `${environment.apiUrl}${path}`;
    return this.http
      .get(path, {
        headers: this.apiHeader.fetchToken(),
        withCredentials: true,
        responseType: 'blob',
      })
      .pipe(map((res) => (res ? res : null)));
  }

  fetchAllInvoces(data?: {}, path?: string) {
    path = `${environment.apiUrl}${path}`;
    return this.http
      .post(path, data, { headers: this.apiHeader.fetchToken() })
      .pipe(map((res) => (res.hasOwnProperty('data') ? res['data'] : null)));
  }
  getInvoceDataByID(path: string) {
    path = `${environment.apiUrl}${path}`;
    return this.http
      .get(path, { headers: this.apiHeader.fetchToken() })
      .pipe(map((res) => (res.hasOwnProperty('data') ? res['data'] : null)));
  }
}
