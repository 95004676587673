<!-- <form [formGroup]="addressForm">
  <input type="text" class="text" formControlName="index" hidden>
  <div class="form-group">
    <label for="address_line1">Address 1</label>
    <input mdbInput type="text" class="form-control" id="addres1" formControlName="address_line1">
  </div>

  <div class="form-group">
    <label for="address_line2">Address 2</label>
    <input mdbInput type="text" class="form-control" id="addres2" formControlName="address_line2">
  </div>

  <div class="form-row">

    <div class="form-group col-md-6">
      <label for="postcode">Postcode</label>
      <input mdbInput type="text" class="form-control" id="postcode" formControlName="postcode">
    </div>

    <div class="form-group col-md-6">
      <label for="state_id">City

      </label>
      <mdb-select [allowClear]="true" [options]="optionsCity" [outline]="true" [filterEnabled]="true" label="City"
        [customClass]="'city-select'" formControlName="city_id"></mdb-select>
    </div>
  </div>

  <div class="form-row">

    <div class="form-group col-md-6">
      <label for="state_id">State

      </label>
      <mdb-select [allowClear]="true" [options]="optionsState" [outline]="true" [filterEnabled]="true" label="State"
        [customClass]="'state-select'"   formControlName="state_id"></mdb-select>
    </div>

    <div class="form-group col-md-6">
      <label for="country_id">Country</label>
      <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true" 
        [customClass]="'country-select'" formControlName="country_id">
      </mdb-select>
    </div>
  </div>

  <div class="form-row">

    <div class="form-group col-md-6">
      <label for="company_phone_number">Phone

      </label>
      <input mdbInput type="tel" class="form-control" id="company_phone_number" formControlName="company_phone_number">

    </div>

    <div class="form-group col-md-6">
      <label for="phone_number">Mobile</label>
      <input mdbInput type="tel" class="form-control" id="phone_number" formControlName="phone_number">
    </div>
  </div>

  <div class="form-group">
    <label for="contact_email">Email</label>
    <input mdbInput type="email" class="form-control" id="contact_email" formControlName="contact_email">
  </div>
</form> -->

<form [formGroup]="addressForm">
  <input type="text" class="text" formControlName="index" hidden>
  <div class="form-group">
    <label for="address_line1">Address 1</label>
    <input mdbInput type="text" class="form-control" id="addres1" formControlName="address_line1">
  </div>

  <div class="form-group">
    <label for="address_line2">Address 2</label>
    <input mdbInput type="text" class="form-control" id="addres2" formControlName="address_line2">
  </div>

  <div class="form-row">
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="postcode">Postcode</label><strong class="red-text">*</strong>
      <input mdbInput type="number" class="form-control" id="postcode" formControlName="postcode"
        (focusout)="onPostCodeEnter($event)">
      <mdb-error style="top:auto;padding-left: inherit;"
        *ngIf="addressForm.controls.postcode.invalid && (addressForm.controls.postcode.dirty || addressForm.controls.postcode.touched)">
        <span *ngIf="addressForm.controls.postcode.errors && addressForm.controls.postcode.errors.required">
          Postcode can't be blank</span>
      </mdb-error>
    </div>
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="city_id">City
        <strong class="red-text">*</strong>
      </label>
      <!-- <mdb-select [allowClear]="true" [options]="optionsCity" [outline]="true" [filterEnabled]="true" label="City"
        [customClass]="'city-select'" formControlName="city_id"></mdb-select>
      <mdb-error style="top:auto;padding-left: inherit;position: relative"
        *ngIf="addressForm.get('city_id').invalid && (addressForm.get('city_id').dirty || addressForm.get('city_id').touched)">
        <span *ngIf="addressForm.get('city_id').errors && addressForm.get('city_id').errors.required">
          City is required, can't be blank</span>
      </mdb-error> -->
      <input mdbInput type="text" class="form-control" id="city" formControlName="city">
      <mdb-error style="position: initial;"
        *ngIf="addressForm.controls.city.invalid && (addressForm.controls.city.dirty || addressForm.controls.city.touched)">
        <span
          *ngIf="addressForm.controls.city.errors && addressForm.controls.city.errors.required">
          City is required, can't be blank </span>
      </mdb-error>
    </div>
  </div>
  <div class="form-row">
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="state_id">State
        <strong class="red-text">*</strong>
      </label>
      <mdb-select [allowClear]="true" [options]="optionsState" [outline]="true" [filterEnabled]="true" label="State"
        [customClass]="'state-select'"   formControlName="state_id"></mdb-select>
      <mdb-error style="top:auto;padding-left: inherit;position: relative"
        *ngIf="addressForm.get('state_id').invalid && (addressForm.get('state_id').dirty || addressForm.get('state_id').touched)">
        <span *ngIf="addressForm.get('state_id').errors && addressForm.get('state_id').errors.required">
          State is required, can't be blank</span>
      </mdb-error>
    </div>
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="country_id">Country</label><strong class="red-text">*</strong>
      <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true"
        [customClass]="'country-select'" formControlName="country_id">
      </mdb-select>
      <mdb-error style="top:auto;padding-left: inherit;position: relative"
        *ngIf="addressForm.get('country_id').invalid && (addressForm.get('country_id').dirty || addressForm.get('country_id').touched)">
        <span *ngIf="addressForm.get('country_id').errors && addressForm.get('country_id').errors.required">
          Country is required, can't be blank</span>
      </mdb-error>
    </div>
  </div>
  <div class="form-row">
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="company_phone_number">Phone Number
        <!-- <strong class="red-text">*</strong> -->
      </label>
      <input mdbInput type="number" class="form-control" id="company_phone_number"
        placeholder="603xxxxxxxx" formControlName="company_phone_number">
      <!-- <mdb-error style="top:auto;padding-left: inherit;"
        *ngIf="addressForm.controls.company_phone_number.invalid && (addressForm.controls.company_phone_number.dirty || addressForm.controls.company_phone_number.touched)">
        <span
          *ngIf="addressForm.controls.company_phone_number.errors && addressForm.controls.company_phone_number.errors.pattern">
          Phone Number should be 10 to 12 characters</span>
      </mdb-error> -->
    </div>
    <!-- Default input -->
    <div class="form-group col-md-6">
      <label for="phone_number">Mobile Number</label>
      <input mdbInput type="number" class="form-control" id="phone_number" formControlName="phone_number"
        placeholder="6011xxxxxxxx">
      <!-- <mdb-error style="top:auto; padding-left: inherit;"
        *ngIf="addressForm.controls.phone_number.invalid && (addressForm.controls.phone_number.dirty || addressForm.controls.phone_number.touched)">
        <span *ngIf="addressForm.controls.phone_number.errors && addressForm.controls.phone_number.errors.pattern">
          Mobile Number should be 10 to 12 characters</span>
      </mdb-error> -->
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="contact_email">Email</label>
      <input mdbInput type="email" class="form-control" id="contact_email" formControlName="contact_email"
        placeholder="abc@abc.com">
      <mdb-error style="top:auto;padding-left: inherit;"
        *ngIf="addressForm.controls.contact_email.invalid && (addressForm.controls.contact_email.dirty || addressForm.controls.contact_email.touched)">
        <span *ngIf="addressForm.controls.contact_email.errors && addressForm.controls.contact_email.errors.pattern">
          Invalid Email</span>
      </mdb-error>
    </div>
  </div>
</form>