import {Component, Input, OnInit} from '@angular/core';
import {IOption} from 'ng-uikit-pro-standard';
import {CityService} from 'src/app/shared/services/api/city.service';
import {StateService} from 'src/app/shared/services/api/state.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input('addressForm') addressForm: any;
  @Input('optionsCity') optionsCity: any;
  @Input('optionsState') optionsState: any;
  @Input('optionsCountry') optionsCountry: any;
  constructor(
    private stateSer: StateService,
    private cities: CityService,
  ) { }

  ngOnInit(): void {
  }

  getState() {
    this.stateSer.fetchAllState('states').subscribe(x => this.optionsState = x);
  }


  onPostCodeEnter(event) {
    this.addressForm.patchValue({postcode: event.target.value});
    
    this.stateSer.fetchAddressByPostCode(event.target.value).subscribe(x => {
      this.addressForm.patchValue({state_id: '' + x.state_id, country_id: '' + x.country_id, city: x.city});
    });
  }

}
