import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Product } from 'src/app/shared/interfaces/product';

interface WishlistData {
  items: Product[];
}

@Injectable({
  providedIn: 'root'
})
export class WishlistService implements OnDestroy {

  private data: WishlistData = {
    items: []
  };

  private destroy$: Subject<void> = new Subject();
  private itemsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  private onAddingSubject$: Subject<Product> = new Subject();

  readonly items$: Observable<Product[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
  readonly count$: Observable<number> = this.itemsSubject$.pipe(map(items => items.length));
  readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.load();
    }
   }


   add(product: Product): Observable<void> {
    // timer only for demo
    return timer(1000).pipe(map(() => {
        this.onAddingSubject$.next(product);

        const index = this.data.items.findIndex(item => item.id === product.id);

        if (index === -1) {
            this.data.items.push(product);
            this.save();
        }
      }));
    }

    remove(product: Product): Observable<void> {
      // timer only for demo
      return timer(1000).pipe(map(() => {
          const index = this.data.items.findIndex(item => item.id === product.id);

          if (index !== -1) {
              this.data.items.splice(index, 1);
              this.save();
          }
      }));
    }

  //  Private
  private save() {
    localStorage.setItem('wishlistItems', JSON.stringify(this.data.items));
    this.itemsSubject$.next(this.data.items);
  }

  private load() {
    const wishlistItems = localStorage.getItem('wishlistItems');

    if (wishlistItems) {
      this.data.items = JSON.parse(wishlistItems);
      this.itemsSubject$.next(this.data.items);
    }
  }


  // OnDestroy
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
