import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AddOns} from 'src/app/shared/interfaces/addOns';
import {CreateCompanyProfilePayload} from 'src/app/shared/interfaces/advertiser-profile';
import {IOCategory} from 'src/app/shared/interfaces/category';
import {PayByPost, PricingItem, ProfilePlan} from 'src/app/shared/interfaces/plan';

interface Amount {
  tokensAmount: number;
  moneyAmount: number;
}

interface Totals {
  title: string;
  type: string;
  price: number;
}

interface CartItem {
  currentProfile: CreateCompanyProfilePayload;
  currentPlan: ProfilePlan,
  currentPostPrice: IOCategory[];
  selectedPlan: PricingItem;
  selectedPBP: PayByPost;
  selectedAddOns: AddOns[];
  isPlan: boolean;
}

interface CartData {
  item: CartItem;
  totals: Totals[];
  insufficient: boolean;
  payableAmount: Amount;
  totalPostCost: Amount;
  futureBalance: Amount;
}

@Injectable({
  providedIn: 'root'
})
export class PricingPostService {

  private data: CartData = {
    item: {
      currentProfile: null,
      currentPlan: null,
      currentPostPrice: [],
      selectedPlan: null,
      selectedPBP: null,
      selectedAddOns: [],
      isPlan: false
    },
    totals: [],
    insufficient: false,
    payableAmount: {
      tokensAmount: 0,
      moneyAmount: 0
    },
    totalPostCost: {
      tokensAmount: 0,
      moneyAmount: 0
    },
    futureBalance: {
      tokensAmount: 0,
      moneyAmount: 0
    },
  };

  get item() {return this.data.item;};

  private itemSub$: BehaviorSubject<CartItem> = new BehaviorSubject(this.data.item);
  private totalsSub$: BehaviorSubject<Totals[]> = new BehaviorSubject(this.data.totals);
  private insufficientSub$: BehaviorSubject<boolean> = new BehaviorSubject(this.data.insufficient);
  private payableAmountSub$: BehaviorSubject<Amount> = new BehaviorSubject(this.data.payableAmount);
  private totalPostCostSub$: BehaviorSubject<Amount> = new BehaviorSubject(this.data.totalPostCost);
  private futureBalanceSub$: BehaviorSubject<Amount> = new BehaviorSubject(this.data.futureBalance);

  item$: Observable<CartItem> = this.itemSub$.asObservable();
  totals$: Observable<Totals[]> = this.totalsSub$.asObservable();
  insufficient$: Observable<boolean> = this.insufficientSub$.asObservable();
  payableAmount$: Observable<Amount> = this.payableAmountSub$.asObservable();
  totalPostCost$: Observable<Amount> = this.totalPostCostSub$.asObservable();
  futureBalance$: Observable<Amount> = this.futureBalanceSub$.asObservable();


  constructor() { }

  setCurrentProfile(currentProfile: CreateCompanyProfilePayload) {
    this.item.currentProfile = currentProfile;
    this.itemSub$.next(this.item);
  }

  setCurrentPlan(currentPlan: ProfilePlan, isPlan: boolean) {

    // Store current plan in CartItem
    this.item.currentPlan = currentPlan;
    // Store current plan status in the cartItem
    this.item.isPlan = isPlan;
    // trigger event to hold the current value same as in cartItem to publish to Obs needs
    this.itemSub$.next(this.item);

  }

  setCurrentPostPrice(currentPostPrice: IOCategory[]) {
    this.item.currentPostPrice = currentPostPrice;
    this.itemSub$.next(this.item);
    this.data.totals = [];
    this.calc();
  }

  setSelectedPlan(selectedPlan: PricingItem) {
    this.item.selectedPlan = selectedPlan;
    this.itemSub$.next(this.item);
    this.data.totals = [];
    this.calc();
  }

  setSelectedPBP(selectedPBP: PayByPost) {
    this.item.selectedPBP = selectedPBP;
    this.itemSub$.next(this.item);
    this.data.totals = [];
    this.calc();

  }

  setSelectedAddOns(selectedAddOns: AddOns[]) {
    this.item.selectedAddOns = selectedAddOns;
    this.itemSub$.next(this.item);
    this.data.totals = [];
    this.calc();
  }

  private setTotals() {

    const salesTax: Totals = {
      title: 'Sales Tax',
      type: 'tax',
      price: 0.06,
    };

    this.data.totals.push(salesTax);

    const othersAmount: Totals = {
      title: 'Others',
      type: 'other',
      price: 0.0,
    };

    this.data.totals.push(othersAmount);

    this.totalsSub$.next(this.data.totals);

  }


  private calc() {

    // Totals array setting
    this.setTotals();

    // Calc Payable Amount either (PBP Plan or Selected Plan or the current plan)

    if (this.item.isPlan) {
      this.data.payableAmount = {
        tokensAmount: this.item.currentPlan.token_count_left,
        moneyAmount: this.item.currentPlan.purchase_price
      };

    } else {

      if (this.item.selectedPlan !== null || this.item.selectedPBP !== null) {
        const selectedPlan = !!this.item.selectedPlan ? this.item.selectedPlan : null;
        const selectedPBP = !!this.item.selectedPBP ? this.item.selectedPBP : null;

        if (!!selectedPlan) {
          this.data.payableAmount.tokensAmount = selectedPlan.tokens;
          this.data.payableAmount.moneyAmount = selectedPlan.price;
        } else {
          this.data.payableAmount.tokensAmount = (selectedPBP?.bumps_up + selectedPBP?.featured_banner + selectedPBP?.featured_category + selectedPBP?.featured_home);
          this.data.payableAmount.moneyAmount = selectedPBP?.price;
        }

      }

    }
    this.payableAmountSub$.next(this.data.payableAmount);



    // Calc Post Total Cost 
    const categoriesCost = this.item.currentPostPrice.reduce((acc, val) => acc = acc + val.token, 0);
    const categoriesCostRM = this.item.currentPostPrice.reduce((acc, val) => acc = acc + val.price, 0);
    const addOnsCost = this.item.selectedAddOns.length > 0 ? this.item.selectedAddOns.reduce((a, v) => a = a + v.token_consume, 0) : 0;
    const addOnsCostRM = this.item.selectedAddOns.length > 0 ? this.item.selectedAddOns.reduce((a, v) => a = a + v.price, 0) : 0;
    // const totals = this.data.totals.reduce((a, v) => a = a + v.price, 0);

    this.data.totalPostCost = {
      tokensAmount: categoriesCost + addOnsCost,
      moneyAmount: categoriesCostRM + addOnsCostRM
    };
    this.totalPostCostSub$.next(this.data.totalPostCost);

    // Calc Future Balance
    this.data.futureBalance.tokensAmount = this.data.payableAmount.tokensAmount - this.data.totalPostCost.tokensAmount;
    this.data.futureBalance.moneyAmount = this.data.payableAmount.moneyAmount - this.data.totalPostCost.moneyAmount;
    this.futureBalanceSub$.next(this.data.futureBalance);

    // Set Insufficient status 
    this.data.futureBalance.tokensAmount < 0 ? this.data.insufficient = true : this.data.insufficient = false;
    this.insufficientSub$.next(this.data.insufficient);

  }


}
