import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Inquiry } from 'src/app/shared/interfaces/inquiry';
import { Post } from 'src/app/shared/interfaces/post';
import { Traffic } from 'src/app/shared/interfaces/traffic';
import { YPoints } from 'src/app/shared/interfaces/y-points';

@Component({
  selector: 'app-website-home-page',
  templateUrl: './website-home-page.component.html',
  styleUrls: ['./website-home-page.component.scss']
})
export class WebsiteHomePageComponent implements OnInit {

  isNewUser = false;

  // user = 'Aishah';

  elements: any = [
    {id: 1, first: 'Mark', last: 'Otto', handle: '@mdo'},
    {id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat'},
    {id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter'},
  ];

  headElements = ['ID', 'First', 'Last', 'Handle'];

  posts: any[] = [
    {id: '1', title: 'Aishah', class: '', type: 'individual'},
    {id: '2', title: 'Hor Siew Plumbing Sanitary Works', class: 'gold', type: 'company'},
    {id: '3', title: 'BMI Group', class: 'gold', type: 'company'},
    {id: '4', title: 'WAFA Jaya Sdn Bhd', class: 'gold', type: 'company'},
    {id: '5', title: 'EV | Esteem Ventures', class: 'gold', type: 'company'},
    {id: '6', title: 'Planworth Global Factoring', class: 'silver', type: 'company'},
    {id: '7', title: 'Hytech Consulting Management SDN BHD', class: 'silver', type: 'company'},
  ];


  inquiries: Inquiry[] = [
    {id:1 , sender: 'Benjamin Foo', subject: 'Enquired for Blood donation Selangor (PJ)  |  BMI Group', date: new Date(), star: false },
    {id:1 , sender: 'Haziq T', subject: 'Enquired for eSport League of Legends Dec 2020  |  Hytech Consulting Management Sdn Bhd', date: new Date(), star: true },
    {id:1 , sender: 'Kamala Giva', subject: 'Followed you!  |  Electroscon Sdn Bhd, Selangor', date: new Date(), star: true },
    {id:1 , sender: 'Nadia Zikri', subject: 'Applied for Customer Service Assistant  |  EV | Esteem Ventures', date: new Date(), star: false },

  ];

  traffics: Traffic[] = [
    {id: 1, postTitle: 'Hor Siew Plumbing Sanitary Works', impressions:107, view: 84, leads:55},
    {id: 2, postTitle: 'BMI Group', impressions:100, view: 74, leads:45},
    {id: 3, postTitle: 'WAFA Jaya Sdn Bhd', impressions:73, view: 64, leads:25},
    {id: 4, postTitle: 'EV | Esteem Ventures', impressions:86, view: 84, leads:15},
    {id: 5, postTitle: 'Planworth Global Factoring', impressions:97, view: 84, leads:56},
    {id: 6, postTitle: 'Hytech Consulting Management SDN BHD', impressions:10, view: 3, leads:0}
  ];

  yPoints: YPoints[] = [
    {id: 1, title: 'Aishah (Individual profile)', amount: 900 },
    {id: 2, title: 'Hor Siew Plumbing Sanitary Works', amount: 1150 },
    {id: 3, title: 'BMI Group', amount: 870 },
    {id: 4, title: 'WAFA Jaya Sdn Bhd', amount: 1200 },
    {id: 5, title: 'EV | Esteem Ventures', amount: 630 },
    {id: 6, title: 'Planworth Global Factoring', amount: 300 },
    {id: 6, title: 'Planworth Global', amount: 1300 },
    {id: 6, title: 'Planworth Factoring', amount: 3200 },
  ];


  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

}
