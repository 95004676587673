<div class="yPoints-widget">

    <mdb-card class="yPoints-widget__card">
        <mdb-card-header class="yPoints-widget__card-header">
            <div class="yPoints-widget__card-header--icon">
                <app-icon name="yPoints" size="25"></app-icon>
            </div>
            <div class="yPoints-widget__card-header--title">
                YPoints Balance
            </div>
            <div class="yPoints-widget__card-header--link">
                <a routerLink="/yPoints"> VIEW ALL </a>
            </div>
        </mdb-card-header>
        <ul class="list-group list-group-flush yPoints-widget__card-ul">
          <li class="list-group-item yPoints-widget__card-ul--li" *ngFor="let yPoint of yPoints">
              <div class="yPoints-widget__card-ul--li-title"> {{ yPoint.title }} </div>
              <div class="yPoints-widget__card-ul--li-points">
                  <div class="yPoints-widget__card-ul--li-points--amount">
                      {{ yPoint.amount }}
                  </div>
                  <div class="yPoints-widget__card-ul--li-points--icon">
                      <app-icon name="dot" size="20"></app-icon>
                  </div>
              </div>
          </li>
        </ul>
    </mdb-card>

</div>