<!-- {{billingFormGroup | json}} -->
<form [formGroup]="billingFormGroup">
  <div class="row">
    <dl class="col-lg-4 col-sm-12">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-4 mt-1 h5 text-left font-weight-bold">Billing Details</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="billing_name">Name <strong class="red-text">*</strong></label>
            <input mdbInput type="email" class="form-control" id="billing_name" formControlName="billing_name">
            <mdb-error style="position: inherit"
              *ngIf="billingFormGroup.controls.billing_name.invalid && (billingFormGroup.controls.billing_name.dirty || billingFormGroup.controls.billing_name.touched)">
              <span
                *ngIf="billingFormGroup.controls.billing_name.errors && billingFormGroup.controls.billing_name.errors.required">
                Name is required, can't be blank</span>
              <span
                *ngIf="billingFormGroup.controls.billing_name.errors && billingFormGroup.controls.billing_name.errors.minlength">
                Name at least should be 10 characters</span>
              <span
                *ngIf="billingFormGroup.controls.billing_name.errors && billingFormGroup.controls.billing_name.errors.maxlength">
                Name should be less then or equal to 50 characters</span>
            </mdb-error>
          </div>

          <div class="form-group">
            <label for="billing_address_line1">Address 1 <strong class="red-text">*</strong></label>
            <input mdbInput type="text" class="form-control" id="billing_address_line1"
              formControlName="billing_address_line1">
            <mdb-error style="position: inherit"
              *ngIf="billingFormGroup.controls.billing_address_line1.invalid && (billingFormGroup.controls.billing_address_line1.dirty || billingFormGroup.controls.billing_address_line1.touched)">
              <span
                *ngIf="billingFormGroup.controls.billing_address_line1.errors && billingFormGroup.controls.billing_address_line1.errors.required">
                Address 1 is required, can't be blank</span>
            </mdb-error>
          </div>

          <div class="form-group">
            <label for="billing_address_line2">Address 2</label>
            <input mdbInput type="text" class="form-control" id="billing_address_line2"
              formControlName="billing_address_line2">
            <mdb-error style="position: inherit"
              *ngIf="billingFormGroup.controls.billing_address_line2.invalid && (billingFormGroup.controls.billing_address_line2.dirty || billingFormGroup.controls.billing_address_line2.touched)">
              <span
                *ngIf="billingFormGroup.controls.billing_address_line2.errors && billingFormGroup.controls.billing_address_line2.errors.required">
                Address 2 is required, can't be blank</span>
            </mdb-error>
          </div>
          <div class="form-row">
            <!-- Default input -->
            <div class="form-group col-md-6">
              <label for="billing_postcode">Postcode <strong class="red-text">*</strong></label>
              <input mdbInput type="number" class="form-control" id="billing_postcode"
                formControlName="billing_postcode" (focusout)="onPostCodeEnter($event)">
              <mdb-error style="position: initial"
                *ngIf="billingFormGroup.controls.billing_postcode.invalid && (billingFormGroup.controls.billing_postcode.dirty || billingFormGroup.controls.billing_postcode.touched)">
                <span
                  *ngIf="billingFormGroup.controls.billing_postcode.errors && billingFormGroup.controls.billing_postcode.errors.required">
                  Postcode is required, can't be blank</span>
              </mdb-error>
            </div>
            <!-- Default input -->
            <div class="form-group col-md-6">
              <label for="billing_city_id">City <strong class="red-text">*</strong></label>
              <!-- <mdb-select [allowClear]="true" [options]="optionsSelectCity" [outline]="true" [filterEnabled]="true"
                label="City" [customClass]="'city-select'" formControlName="billing_city_id">
              </mdb-select>
              <mdb-error style="top:auto;padding-left: inherit;position: relative"
                *ngIf="billingFormGroup.get('billing_city_id').invalid && (billingFormGroup.get('billing_city_id').dirty || billingFormGroup.get('billing_city_id').touched)">
                <span
                  *ngIf="billingFormGroup.get('billing_city_id').errors && billingFormGroup.get('billing_city_id').errors.required">
                  City is required, can't be blank</span>
              </mdb-error> -->
              <input mdbInput type="text" class="form-control" id="billing_city" formControlName="billing_city">
              <mdb-error style="position: initial;"
                *ngIf="billingFormGroup.controls.billing_city.invalid && (billingFormGroup.controls.billing_city.dirty || billingFormGroup.controls.billing_city.touched)">
                <span
                  *ngIf="billingFormGroup.controls.billing_city.errors && billingFormGroup.controls.billing_city.errors.required">
                  City is required, can't be blank </span>
              </mdb-error>
            </div>
          </div>

          <div class="form-row">
            <!-- Default input -->
            <div class="form-group col-md-6">
              <label for="billing_state_id">State <strong class="red-text">*</strong></label>
              <mdb-select [allowClear]="true" [options]="optionsSelectState" [outline]="true" [filterEnabled]="true"
                [customClass]="'state-select'" formControlName="billing_state_id">
              </mdb-select>
              <mdb-error style="top:auto;padding-left: inherit;position: relative"
                *ngIf="billingFormGroup.get('billing_state_id').invalid && (billingFormGroup.get('billing_state_id').dirty || billingFormGroup.get('billing_state_id').touched)">
                <span
                  *ngIf="billingFormGroup.get('billing_state_id').errors && billingFormGroup.get('billing_state_id').errors.required">
                  State is required, can't be blank</span>
              </mdb-error>
            </div>
            <!-- Default input -->
            <div class="form-group col-md-6">
              <label for="billing_country_id">Country <strong class="red-text">*</strong></label>
              <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true"
                [customClass]="'country-select'" formControlName="billing_country_id"></mdb-select>
              <mdb-error style="top:auto;padding-left: inherit;position: relative"
                *ngIf="billingFormGroup.get('billing_country_id').invalid && (billingFormGroup.get('billing_country_id').dirty || billingFormGroup.get('billing_country_id').touched)">
                <span
                  *ngIf="billingFormGroup.get('billing_country_id').errors && billingFormGroup.get('billing_country_id').errors.required">
                  Country is required, can't be blank</span>
              </mdb-error>
            </div>
          </div>


        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mdb-checkbox formControlName="update_billing_info">Save this information for next time</mdb-checkbox>
        </div>
      </div>
    </dl>
    <dl class="col-lg-3 col-sm-12">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-4 mt-1 h5 text-left font-weight-bold">Payment Method <strong class="red-text">*</strong></h4>
        </div>
      </div>
      <div class="row">

        <div *ngIf="caliculatePrice?.payment_term && adsPlanForm?.value?.package" class="col-12">
          <input name="billing_payment_method" type="radio" class="form-check-input with-gap" id="radioWithGap4" checked
            required value="card" formControlName="billing_payment_method" (change)="removePromoCode(false)" />
          <label class=" form-check-label" for="radioWithGap4">Credit card / Debit card {{selectedDropdownType === "UPGRADEPLAN" ? '(Auto Renew)' : ''}}</label>
        </div>

        <div *ngIf="caliculatePrice?.payment_term == 'yr' || !adsPlanForm?.value?.package" class="col-12">
          <div *ngIf="caliculatePrice?.payment_term == 'yr'">
            <input name="billing_payment_method" type="radio" class="form-check-input with-gap" id="radioWithGap6"
            required value="fpx" formControlName="billing_payment_method" />
            <label class="form-check-label" for="radioWithGap6">Online Banking FPX (Subscribe for One Year only)</label>
          </div>
          <div *ngIf="!adsPlanForm?.value?.package">
            <input name="billing_payment_method" type="radio" class="form-check-input with-gap" id="radioWithGap6"
            required value="fpx" formControlName="billing_payment_method" />
            <label class="form-check-label" for="radioWithGap6">Online Banking FPX</label>
          </div>
        </div>
        <div class="col-12" *ngIf="adsPlanForm?.value?.package && isCompany && caliculatePrice?.payment_term == 'mo'">
          <input name="billing_payment_method" type="radio" class="form-check-input with-gap" id="radioWithGap2"
            required value="unifi" formControlName="billing_payment_method" />
          <label class="form-check-label" for="radioWithGap2">Unifi Billing</label>
        </div>
        <mdb-error style="position: inherit;margin-left: 20px;"
          *ngIf="billingFormGroup.controls.billing_payment_method.invalid">
          <span
            *ngIf="billingFormGroup.controls.billing_payment_method.errors && billingFormGroup.controls.billing_payment_method.errors.required">
            Please select a payment method </span>
        </mdb-error>
      </div>
      <div class="row" *ngIf="billingFormGroup.value.billing_payment_method == 'unifi' && caliculatePrice?.payment_term == 'mo'">
        <div class="col-12">
          <ng-container>
            <div class="form-group">
              <label for="unifi_id">Unifi Biz ID</label>
              <input mdbInput type="text" class="form-control" id="unifi_id" 
              formControlName="unifi_id" placeholder="ex. name@unifi.biz">
              <mdb-error style="position: inherit"
                *ngIf="billingFormGroup.controls.unifi_id.invalid && (billingFormGroup.controls.unifi_id.dirty || billingFormGroup.controls.unifi_id.touched)">
                <span
                  *ngIf="billingFormGroup.controls.unifi_id.errors && billingFormGroup.controls.unifi_id.errors.required">
                  Unifi Biz ID is required, can't be blank</span>
              </mdb-error>
            </div>
            <div class="form-group">
              <label for="unifi_name">Unifi Phone Number</label>
              <input mdbInput type="number" class="form-control" id="unifi_phone_number"
                formControlName="unifi_phone_number" placeholder="ex. 603xxxxxxxx">
              <mdb-error style="position: inherit"
                *ngIf="billingFormGroup.controls.unifi_phone_number.invalid && (billingFormGroup.controls.unifi_phone_number.dirty || billingFormGroup.controls.unifi_phone_number.touched)">
                <span
                  *ngIf="billingFormGroup.controls.unifi_phone_number.errors && billingFormGroup.controls.unifi_phone_number.errors.required">
                  Unifi Phone Number is required, can't be blank</span>
              </mdb-error>
            </div>
            <div class="form-group">
              <label for="unifi_name">Package Name</label>
              <input mdbInput type="text" class="form-control" id="unifi_name" 
              formControlName="unifi_name" placeholder="ex. unifi Biz 300Mbps">
              <mdb-error style="position: inherit"
                *ngIf="billingFormGroup.controls.unifi_name.invalid && (billingFormGroup.controls.unifi_name.dirty || billingFormGroup.controls.unifi_name.touched)">
                <span
                  *ngIf="billingFormGroup.controls.unifi_name.errors && billingFormGroup.controls.unifi_name.errors.required">
                  Package Name is required, can't be blank</span>
              </mdb-error>
            </div>
            <mdb-checkbox formControlName="unifi_authorized">I authorise Unifi/TM to apply the charges to the above Unifi/TM Account</mdb-checkbox>
          </ng-container>
        </div>
      </div>
    </dl>
    <dl class="col-lg-5 col-sm-12">
      <!--Card-->
      <mdb-card>
        <!--Card content-->
        <mdb-card-body>
          <h4 class="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h4>
          <hr />
          <dl class="row" *ngIf="caliculatePrice">
            <ng-container *ngIf="caliculatePrice.is_plan && caliculatePrice.plan">            
              <dd class="col-6">{{caliculatePrice.plan.name}} Plan</dd>
              <dd class="col-5 price" *ngIf="caliculatePrice.payment_term == 'yr'">RM {{caliculatePrice.plan.price}} (Yearly)</dd>
              <dd class="col-5 price" *ngIf="caliculatePrice.payment_term == 'mo'">RM {{caliculatePrice.plan.monthly_price}} (Monthly)</dd>
            </ng-container>
            <ng-container *ngFor="let item of caliculatePrice.chargeable_item">
              <dd class="col-8">
                <span *ngIf="caliculatePrice.is_token">{{item.token}} </span> 
                {{item.name}} 
                <span *ngIf="item.is_plan"> Plan </span> 
                <span *ngIf="item.is_plan && caliculatePrice.is_plan && caliculatePrice.payment_term == 'yr'">(Yearly)</span>
                <span *ngIf="item.is_plan && caliculatePrice.is_plan && caliculatePrice.payment_term == 'mo'">(Monthly)</span>
              </dd>
              <dd class="col-4 price justify-content-between d-flex" *ngIf="item.is_plan && caliculatePrice.payment_term == 'yr'"> <div>RM</div> <div>{{item.price}}</div></dd>
              <dd class="col-4 price justify-content-between d-flex" *ngIf="item.is_plan && caliculatePrice.payment_term == 'mo'"><div>RM</div> <div>{{item.monthly_price}}</div></dd>
              <dd class="col-4 price justify-content-between d-flex" *ngIf="!item.is_plan && item.amount"><div>RM</div> <div>{{item.amount}}</div></dd>
              <dd class="col-4 price justify-content-between d-flex" *ngIf="!item.is_plan && item.price"><div>RM</div> <div>{{item.price}}</div></dd>
              <ng-container *ngIf="activeProfileHasPlan || isPayPerPost">
                <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'cash'">{{item.amount | currency: 'RM '}}</dd>
                <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'token'">{{item.token == 0 ? 'FREE' : item.token}}
                  Tokens</dd>
              </ng-container>
              <ng-container *ngIf="!activeProfileHasPlan && !isPayPerPost">
                <dd class="col-4">{{item.token == 0 ? 'FREE' : item.token}}
                  Tokens</dd>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let item of caliculatePrice.included_item">
              <ng-container *ngIf="activeProfileHasPlan || isPayPerPost">
                <dd class="col-8">{{item.name}}</dd>
                <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'cash'">{{item.amount | currency: 'RM '}}</dd>
                <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'token'">{{item.token == 0 ? 'FREE' : item.token}}
                  Tokens</dd>
              </ng-container>
              <ng-container *ngIf="!activeProfileHasPlan && !isPayPerPost">
                <dd class="col-8">{{item.name}}</dd>
                <dd class="col-4">{{item.token == 0 ? 'FREE' : item.token}}
                  Tokens</dd>
              </ng-container>
            </ng-container>
          </dl>
          <hr />

          <!-- <dl class="row">
            <dt class="col-sm-8">
              Total
            </dt>
            <dt class="col-sm-4">
              $ 2000
            </dt>
          </dl> -->
          <ng-container *ngIf="caliculatePrice && caliculatePrice.pay_method == 'token'">
            <dl class="row">
              <dd class="col-8 font-weight-bold">Total Available</dd>
              <dd class="col-4 font-weight-bold price">{{caliculatePrice.token_available}} Tokens</dd>
            </dl>
            <dl class="row">
              <dd class="col-8 font-weight-bold">Total Consuming</dd>
              <dd class="col-4 font-weight-bold price">{{caliculatePrice.token_consumed}} Tokens</dd>
            </dl>
            <hr />
          </ng-container>
          <ng-container *ngIf="caliculatePrice && caliculatePrice.pay_method == 'cash'">
            <dl class="row">
              <dd class="col-8 font-weight-bold">Subtotal</dd>
              <dd class="col-4 font-weight-bold price justify-content-between d-flex"> <div>RM</div> <div>{{caliculatePrice.amount}}</div></dd>
            </dl>
            <dl class="row">
              <dd class="col-8 font-weight-bold ">Discount</dd>
              <dd class="col-4 font-weight-bold price justify-content-between d-flex"><div>RM</div><div>{{discountedAmount ? '-' + discountedAmount.toFixed(2) : 0}}</div> </dd>
            </dl>
            <dl class="row">
              <dd class="col-8 font-weight-bold">SST (8%)</dd>
              <dd class="col-4 font-weight-bold price justify-content-between d-flex"><div>RM</div> <div> {{caliculatePrice.sst_total}}</div></dd>
            </dl>
            <hr />
          </ng-container>
          <dl class="row" *ngIf="caliculatePrice && caliculatePrice.pay_method == 'cash'">
            <dt class="col-8">
              Total
              <span *ngIf="caliculatePrice.is_plan && caliculatePrice.payment_term == 'yr'">(Per Year)</span>
              <span *ngIf="caliculatePrice.is_plan && caliculatePrice.payment_term == 'mo'">(Per Month)</span>
            </dt>
            <dt class="col-4 price justify-content-between d-flex"><div>RM</div> <div>  {{caliculatePrice.subtotal}}</div></dt>
          </dl>
        </mdb-card-body>
      </mdb-card>
      <!--/.Card-->
      <br>
      <!-- <div class="row">
        <div class="col-6">
          <input mdbInput type="text" class="form-control" id="promo_code" formControlName="promo_code"
            placeholder="Promo Code">
        </div>
        <div class="col-6">
          <button type="button" class="yp-button blue sm font" 
            [disabled]="!billingFormGroup.valid" style="margin-top: 0;">APPLY</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <input mdbInput type="text" class="form-control" id="referral_code" formControlName="referral_code"
            placeholder="Referral ID">
        </div>
        <div class="col-6">
          <button type="button" class="yp-button blue sm font" 
            [disabled]="!billingFormGroup.valid" style="margin-top: 0;">APPLY</button>
        </div>
      </div> -->
      <div class="row" *ngIf="isFpxPayment">
        <div class="col-6">
          Promo Code:
          <input mdbInput type="text" class="form-control" id="promo_code" formControlName="promo_code"
            placeholder="eg. YPP50JAN">
        </div>
        <div class="col-6">
          <button *ngIf="!isPromoCodeApplied" [disabled]="isPromoCodeApplied" (click)="applyPromoCode()" type="button" class="yp-button blue sm font" style="margin-top:20px;">
            APPLY CODE
          </button>
          <button *ngIf="isPromoCodeApplied" (click)="removePromoCode(false)" type="button" class="yp-button red sm font" style="margin-top:20px;">
            REMOVE CODE
          </button>
        </div>
      </div>
      <div class="row" *ngIf="showSalesID">
        <div class="col-12">
          Referral:
          <input mdbInput type="text" class="form-control" id="referral" formControlName="referral"
            placeholder="Referral email" mdbValidate>
          <!-- <input mdbInput type="text" class="form-control" id="sales_id" formControlName="sales_id"
            pattern="^[a-zA-Z0-9]+$" placeholder="Sales ID" mdbValidate> -->
          <mdb-error style="position: initial"
            *ngIf="billingFormGroup.controls.referral.invalid">
            <span *ngIf="billingFormGroup.controls.referral.errors && billingFormGroup.controls.referral.errors.pattern">
              Only letters and numbers are allowed.</span>
          </mdb-error>
        </div>
      </div>
      <div class="row" *ngIf="showSalesID">
        <div class="col-12">
          Agent:
          <input mdbInput type="text" class="form-control" id="sales_id" formControlName="sales_id"
            placeholder="Sales ID" mdbValidate>
          <!-- <input mdbInput type="text" class="form-control" id="sales_id" formControlName="sales_id"
            pattern="^[a-zA-Z0-9]+$" placeholder="Sales ID" mdbValidate> -->
          <mdb-error style="position: initial"
            *ngIf="billingFormGroup.controls.sales_id.invalid">
            <span *ngIf="billingFormGroup.controls.sales_id.errors && billingFormGroup.controls.sales_id.errors.pattern">
              Only letters and numbers are allowed.</span>
          </mdb-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mdb-checkbox formControlName="t_c">I accept the<a href="https://solutions.yellowpages.my/tnc/Advertising%20Agreement%20TC_YP.pdf" target="_blank"> Terms & Conditions<strong class="red-text">*</strong></a></mdb-checkbox>
          <mdb-error style="position: initial"
            *ngIf="billingFormGroup.controls.t_c.invalid">
            <span *ngIf="billingFormGroup.controls.t_c.errors && billingFormGroup.controls.t_c.errors.required">
              Please accept Terms and Conditions</span>
          </mdb-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mdb-checkbox formControlName="faq">I have read and understand the<a href="https://assets.yellowpages.my/guides/Frequently_Asked_Questions_Yellow_Pages_2021_V6_29July.pdf" target="_blank"> FAQ  <strong class="red-text">*</strong></a></mdb-checkbox>
          <mdb-error style="position: initial"
            *ngIf="billingFormGroup.controls.faq.invalid">
            <span *ngIf="billingFormGroup.controls.faq.errors && billingFormGroup.controls.faq.errors.required">
              Please read the FAQ</span>
          </mdb-error>
        </div>
      </div>
    </dl>

  </div>
</form>