<mdb-card class="create-post__card" *ngIf="isFormVisible">
  <mdb-card-body class="create-post__card-body">
    <form [formGroup]="topupForm">
      <mdb-stepper #profileStepper [vertical]="true" [linear]="true" [disableWaves]="true">
        <mdb-step name="TOP UP TOKENS">
          <div class="row">
            <div class="form-group topup-dropdown col-md-6 col-lg-4 col-sm-12">
              <!-- <span for="topup">Top Up Amount</span> -->
              <mdb-select [allowClear]="true" id="topup" formControlName="token_id" [options]="optionsTopup"
                [outline]="true" [filterEnabled]="true" label="Top Up Amount"></mdb-select>
            </div>
            <div class="col-lg-4"></div>
            <div *ngIf="caliculatePrice" class="post-summary__summary col-md-6 col-lg-4 col-sm-12" style="padding: 0px;">
              <mdb-card>
              <mdb-card-body>
              <div class="post-summary__summary--header">
                Summary
              </div>
              <div>
                <!-- for Individual Pay Per Post -->
                <dl class="row">
                  <!-- <dd class="col-sm-12" *ngIf="caliculatePrice"> -->
                  <ng-container *ngIf="caliculatePrice.pay_method == 'token'">
                    <dd class="col-8"> {{caliculatePrice.plan.name}} Plan</dd>
                    <dd class="col-4">({{caliculatePrice.plan.token_count}} Tokens)</dd>
                  </ng-container>
                  <ng-container *ngFor="let item of caliculatePrice.chargeable_item">
                    <dd class="col-8">{{item.name}}</dd>
                    <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'cash'">{{item.amount | currency: 'RM '}}
                    </dd>
                    <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'token'">
                      {{item.token == 0 ? 'FREE' : item.token}}
                      Tokens</dd>
                  </ng-container>
                  <ng-container *ngFor="let item of caliculatePrice.included_item">
                    <dd class="col-8">{{item.name}}</dd>
                    <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'cash'">{{item.amount | currency: 'RM '}}
                    </dd>
                    <dd class="col-4" *ngIf="caliculatePrice.pay_method == 'token'">
                      {{item.token == 0 ? 'FREE' : item.token}}
                      Tokens</dd>
                  </ng-container>
                  <!-- </dd> -->
                </dl>
                <hr />
                <ng-container *ngIf="caliculatePrice && caliculatePrice.pay_method == 'cash'">
                  <dl class="row">
                    <dd class="col-8 font-weight-bold">Subtotal</dd>
                    <dd class="col-4 font-weight-bold"> RM {{caliculatePrice.subtotal}}</dd>
                  </dl>
                  <dl class="row">
                    <dd class="col-8 font-weight-bold">Discount</dd>
                    <dd class="col-4 font-weight-bold">RM {{0.00}}</dd>
                  </dl>
                  <dl class="row">
                    <dd class="col-8 font-weight-bold">SST (8%)</dd>
                    <dd class="col-4 font-weight-bold">RM {{caliculatePrice.sst_total}} </dd>
                  </dl>
                  <hr />
                </ng-container>
                <dl class="row" *ngIf="caliculatePrice && caliculatePrice.pay_method == 'cash'">
                  <dd class="col-8 font-weight-bold">Total</dd>
                  <dd class="col-4 font-weight-bold">RM {{caliculatePrice.amount}}</dd>
                </dl>
                <dl class="row" *ngIf="caliculatePrice && caliculatePrice.pay_method == 'token'">
                  <dd class="col-8 font-weight-bold">Total Available</dd>
                  <dd class="col-4 font-weight-bold">{{caliculatePrice.token_available}} Tokens</dd>
                </dl>

                <dl class="row" *ngIf="caliculatePrice && caliculatePrice.pay_method == 'token'">
                  <hr />
                  <dd class="col-8 font-weight-bold">Total Consuming</dd>
                  <dd class="col-4 font-weight-bold">{{caliculatePrice.token_consumed}} Tokens</dd>
                </dl>
              </div>
              </mdb-card-body>
              </mdb-card>
            </div>
          </div>


          <div class="next-btn">
            <button type="button" mdbBtn color="dark" mdbWavesEffect size="sm"
              (click)="gotoManageAdminProfile()">Back</button>
            <button type="button" mdbBtn color="primary" mdbWavesEffect size="sm"
              [disabled]="!topupForm.valid || !caliculatePrice" (click)="profileStepper.next()">Next</button>
          </div>
        </mdb-step>
        <mdb-step name="CHECKOUT">
          <app-check-out [isCompany]="true" [caliculatePrice]="caliculatePrice" [billingFormGroup]="billingFormGroup">
          </app-check-out>
          <div class="next-btn">
            <button type="button" mdbBtn color="dark" mdbWavesEffect size="sm"
              (click)="profileStepper.previous()">Back</button>
            <button type="button" mdbBtn color="primary" mdbWavesEffect size="sm" [disabled]="!billingFormGroup.valid"
              (click)="checkout()">CHECKOUT</button>
          </div>
        </mdb-step>
      </mdb-stepper>
    </form>

  </mdb-card-body>
</mdb-card>


<div class="text-center" *ngIf="!isFormVisible">
  <div class="row">
    <div class="col-12">
      <img src="/assets/images/success.png" alt="" width="50px" height="50px" *ngIf="isTopUpSuccess">
      <img src="/assets/images/failure.png" alt="" width="50px" height="50px" *ngIf="!isTopUpSuccess">
    </div>
  </div>
  <ng-container *ngIf="isTopUpSuccess">
    <div class="row">
      <div class="col-12 text-center bold">
        Token Top Up Successful!!
      </div>
      <!-- </div>
    <div class="row"> -->
      <div class="col-12 text-center">
        You have successfully topped up {{selectedTopUp.token_count}} Tokens to This Advertiser.
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="!isTopUpSuccess">
    <div class="col-12 text-center">
      <div class="col-12">
        <p>Unable to Top Up the tokens. Please Try again.</p>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-12">
      <!-- <b *ngIf="isProfileCreated">Payment successful!</b>
      <b *ngIf="!isProfileCreated">Payment failed!</b> -->
      <b>Company Name: {{profileData.name}}</b>
    </div>
    <div class="col-12">
      <b>Company No. / Business No.: {{profileData.company_no}}</b>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-12">
      <button class="btn-primary" [routerLink]="['/admin/create-post']"> CREATE NEW POST</button>
    </div>
    <div class="col-12">
      <a href="" [routerLink]="['/admin/manage-profile']"> BACK TO ALL ADVERTISER</a>
    </div>
  </div>
</div>