<form [formGroup]="claimCompanyForm">
    <!-- Grid row -->
    <div class="form-row">
        <!-- Default input -->
        <div class="form-group col-md-6">
            <label for="name">Company Name <strong class="red-text">*</strong></label>
            <input mdbInput type="text" class="form-control" id="name" formControlName="name">
        </div>
        <!-- Default input -->
        <div class="form-group col-md-6" formGroupName="address">
            <label for="BillingAddress1">Company Address 1 <strong class="red-text">*</strong></label>
            <input mdbInput type="text" class="form-control" id="BillingAddress1" formControlName="billingAddress1">
        </div>
    </div>
    <!-- Grid row -->

    <!-- Grid row -->
    <div class="form-row">
        <!-- Default input -->
        <div class="form-group col-md-6">
            <label for="companyNo">Company No <strong class="red-text">*</strong></label>
            <input mdbInput type="text" class="form-control" id="companyNo" formControlName="companyRN">
        </div>
        <!-- Default input -->
        <div class="form-group col-md-6" formGroupName="address">
            <label for="BillingAddress2">Company Address 2</label>
            <input mdbInput type="text" class="form-control" id="BillingAddress2" formControlName="billingAddress2">
        </div>
    </div>
    <!-- Grid row -->
    <!-- Grid row -->
    <div class="form-row">
        <!-- Default input -->
        <div class="form-group col-md-6">
            <label for="contactNumber">Contact Number <strong class="red-text">*</strong></label>
            <input mdbInput type="text" class="form-control" id="contactNumber" formControlName="companyContactNo">
        </div>
        <!-- Default input -->
        <div class="form-group col-md-6 d-flex align-items-center justify-content-center" formGroupName="address">
            <mdb-select [allowClear]="true" [options]="optionsState" [outline]="true" [filterEnabled]="true" [customClass]="'state-select'"   formControlName="state"></mdb-select>
            <mdb-select [allowClear]="true" [options]="optionsCity" [outline]="true" [filterEnabled]="true" [customClass]="'city-select'" formControlName="city"></mdb-select>
        </div>
    </div>
    <!-- Grid row -->
    <!-- Grid row -->
    <div class="form-row">
        <!-- Default input -->
        <div class="form-group col-md-6">
            <label for="inputEmail">Email Address<strong class="red-text">*</strong></label>
            <input mdbInput type="email" class="form-control" id="inputEmail" formControlName="companyEmail">
        </div>
        <!-- Default input -->
        <div class="form-group col-md-6 d-flex align-items-center justify-content-center" formGroupName="address">
            
            <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true" [customClass]="'country-select'" formControlName="country"></mdb-select>
            <label for="postcode">Postcode</label>
            <input mdbInput type="text" class="form-control" id="postcode" label="Postcode" formControlName="postcode">
        </div>
    </div>
    <!-- Grid row -->

    <!-- Grid row -->
    <div class="form-row">
        <!-- Default input -->
        <div class="form-group col-md-6">
            <label for="inputWebsite">Password</label>
            <input mdbInput type="text" class="form-control" id="inputWebsite" placeholder="company's website" formControlName="companyWebsite">
        </div>
        <!-- Default input -->
        <div class="form-group col-md-6">
            Please attach supporting documents to verify your
            Company Profile
            <p>e.g Business Registration Cert, Business License, Utilities Bill</p>
        </div>
    </div>
    <!-- Grid row -->

    <div class="new-company-form__btns">
        <button type="button" mdbBtn size="md" color="dark" mdbWavesEffect>Cancel</button>
        <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect  (click)="onSubmitClaim(claimCompanyForm.value)" [disabled]="!claimCompanyForm.valid">Proceed Claim</button>
    </div>

</form>