import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileMenuService {
  private isOpenSub$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isOpen$: Observable<boolean> = this.isOpenSub$.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  open() {
    if (isPlatformBrowser(this.platformId)) {
      const bodyWidth = document.body.offsetWidth;

      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = (document.body.offsetWidth - bodyWidth) + 'px';

      this.isOpenSub$.next(true);
    }
  }

  close() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '';

      this.isOpenSub$.next(false);
    }
  }

  toggle() {
    this.isOpenSub$.next(!this.isOpenSub$.value);
  }
}
