import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(
        private http: HttpClient
    ) { }

    getPaymentRequest(paymentCode: string, repay: boolean) {
        return this.http.post(`${environment.apiUrl}/advertiser/payment/request`, {payment_code: paymentCode, repay: repay}).pipe(
            map(res => res.hasOwnProperty('data') ? res['data'] : null)
          );
    }

    getPaymentStatusRequery(refNo:string){
        return this.http.post(`${environment.apiUrl}/advertiser/payment/query`, {code: refNo}).pipe(
            map(res => res.hasOwnProperty('data') ? res['data'] : null)
          );
    } 
}