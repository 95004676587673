import { Component, OnInit } from '@angular/core';
import { Link } from 'src/app/shared/interfaces/link';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service'
import { LinkedList } from 'ng-uikit-pro-standard/lib/free/utils';

@Component({
  selector: 'app-side-nav-block',
  templateUrl: './side-nav-block.component.html',
  styleUrls: ['./side-nav-block.component.scss']
})
export class SideNavBlockComponent implements OnInit {

  links: Link[] = [
    // {title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/dashboard' },
    // {title: 'Business Analytics', icon: 'business-analytics' , hasSubLink: false, subLinks: [], url: '/analytics' },
    // {title: 'Create Post', icon: 'create-post' , hasSubLink: false, subLinks: [], url: '/advertiser/post/create-post' },
    // {title: 'Manage Post', icon: 'manage-post' , hasSubLink: false, subLinks: [], url: '/admin/manage-post' },
    // {title: 'Manage Advertiser Profile', icon: 'profile' , hasSubLink: false, subLinks: [], url: '/dashboard/account' },
    // {title: 'Billing', icon: 'billing' , hasSubLink: false, subLinks: [], url: '/dashboard/billing' },
    // {title: 'YPoints', icon: 'yPoints' , hasSubLink: false, subLinks: [], url: '/dashboard/yPoints'},
  ];

  constructor(
    private userUtilsService: UserUtilsService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    sessionStorage.removeItem('perPage');

    this.auth.getUser().subscribe(res => {
      if (this.userUtilsService.verifyUserRole(res.roles, "admin")) {
        this.addAdminMenus();
      } else {
        let disabled: boolean = false;

        if (!this.userUtilsService.isProfileComplete(res)) {
          disabled = true;
        }

        this.addAdvertiserMenus(disabled);
      }
    });
  }

  addAdminMenus() {
    // console.log("Admin menu");
    this.links.push({title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/admin/dashboard' });
    this.links.push({title: 'Business Analytics', icon: 'analytics' , hasSubLink: true, subLinks: [
      {title: 'Demographics Analytics', url: '/admin/analytics/demographics' },
      {title: 'Behaviour Analytics', url: '/admin/analytics/behaviour' },
      {title: 'Geolocation Analytics', url: '/admin/analytics/geolocation' },
      {title: 'Technology Analytics', url: '/admin/analytics/technology' }
    ], url: '/admin/analytics' });
    // this.links.push({title: 'Create Post', icon: 'create-post' , hasSubLink: false, subLinks: [], url: '/advertiser/create-post' });
    this.links.push({title: 'Manage Post', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/admin/manage-post' });
    this.links.push({title: 'Manage Advertiser Profile', icon: 'account_circle' , hasSubLink: false, subLinks: [], url: '/admin/manage-profile' });
    this.links.push({title: 'Member Management', icon: 'groups' , hasSubLink: false, subLinks: [], url: '/admin/user-management' });
    this.links.push({title: 'Billing', icon: 'receipt_long' , hasSubLink: false, subLinks: [], url: '/admin/billing' });
    this.links.push({title: 'Report', icon: 'analytics' , hasSubLink: false, subLinks: [], url: '/admin/report' });
    // this.links.push({title: 'YPoints', icon: 'yPoints' , hasSubLink: false, subLinks: [], url: '/admin/yPoints'});
  }

  addAdvertiserMenus(disabled: boolean) {
    // console.log("Advertiser menu");

    if (disabled) {
      this.links.push({title: 'Dashboard', icon: '' , hasSubLink: false, subLinks: [], url: '/' });
      this.links.push({title: 'Business Analytics', icon: 'analytics' , hasSubLink: false, subLinks: [{title: 'Demographics Analytics', url: '/advertiser/analytics/demographics' },
      {title: 'Behaviour Analytics', url: '/advertiser/analytics/behaviour' },
      // {title: 'Geolocation Analytics', url: '/advertiser/analytics/geolocation' },
      // {title: 'Technology Analytics', url: '/advertiser/analytics/technology' }
      ], url: '/' });      this.links.push({title: 'Create Post', icon: 'drive_file_rename_outline' ,  hasSubLink: false, subLinks: [], url: '/' });
      this.links.push({title: 'Manage Post', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/' });
      this.links.push({title: 'Manage Advertiser Profile', icon: 'groups' , hasSubLink: false, subLinks: [], url: '/' });
      this.links.push({title: 'Billing', icon: 'receipt_long' , hasSubLink: false, subLinks: [], url: '/' });
      // this.links.push({title: 'YPoints', icon: 'yPoints' , hasSubLink: false, subLinks: [], url: '/advertiser/yPoints'});
    } else {
      this.links.push({title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/advertiser/dashboard' });
      this.links.push({title: 'Business Analytics', icon: 'analytics' , hasSubLink: true, subLinks: [
        {title: 'Demographics Analytics', url: '/advertiser/analytics/demographics' },
        {title: 'Behaviour Analytics', url: '/advertiser/analytics/behaviour' },
        // {title: 'Geolocation Analytics', url: '/advertiser/analytics/geolocation' },
        // {title: 'Technology Analytics', url: '/advertiser/analytics/technology' }
      ], url: '/advertiser/analytics' });
      this.links.push({title: 'Create Post', icon: 'drive_file_rename_outline' ,  hasSubLink: false, subLinks: [], url: '/advertiser/create-post' });
      this.links.push({title: 'Manage Post', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/advertiser/manage-post' });
      this.links.push({title: 'Manage Advertiser Profile', icon: 'groups' , hasSubLink: false, subLinks: [], url: '/advertiser/manage-profile' });
      this.links.push({title: 'Billing', icon: 'receipt_long' , hasSubLink: false, subLinks: [], url: '/advertiser/billing' });
      // this.links.push({title: 'YPoints', icon: 'yPoints' , hasSubLink: false, subLinks: [], url: '/advertiser/yPoints'});
    }
  }

}
