import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { IconComponent } from './components/icon/icon.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SideNavLinkItemComponent } from './components/side-nav-link-item/side-nav-link-item.component';
import { ProfileWidgetComponent } from './widgets/profile-widget/profile-widget.component';
import { InquiriesWidgetComponent } from './widgets/inquiries-widget/inquiries-widget.component';
import { TrafficWidgetComponent } from './widgets/traffic-widget/traffic-widget.component';
import { YPointsWidgetComponent } from './widgets/y-points-widget/y-points-widget.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PricingByPostComponent } from './components/pricing-by-post/pricing-by-post.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { PlanSummaryComponent } from './components/plan-summary/plan-summary.component';
import { PayByPostPlanSummaryComponent } from './components/pay-by-post-plan-summary/pay-by-post-plan-summary.component';
import { AddOnsComponent } from './components/add-ons/add-ons.component';
import { CheckOutComponent } from './components/check-out/check-out.component';
import { CreateNewCompanyComponent } from './components/create-new-company/create-new-company.component';
import { CompanyFormComponent } from './components/create-new-company/company-form/company-form.component';
import { CompanyResultComponent } from './components/create-new-company/company-result/company-result.component';
import { NewCompanyFormComponent } from './components/create-new-company/new-company-form/new-company-form.component';
import { ClaimCompanyProfileComponent } from './components/create-new-company/claim-company-profile/claim-company-profile.component';
import { ClaimCompanyFormComponent } from './components/create-new-company/claim-company-form/claim-company-form.component';
import { NoPlanSummaryComponent } from './components/no-plan-summary/no-plan-summary.component';
import { ViewPostComponent } from './components/view-post/view-post.component';
import { DndDirective } from './directives/dnd.directive';
import { AddressListComponent } from './components/address-list/address-list.component';
import { ExportPaginationComponent } from './../advertiser/export-pagination/export-pagination.component';
import { AddressFormComponent } from './../admin/profile/create-profile/address-form/address-form.component';

@NgModule({
  declarations: [
    ViewPostComponent,
    AddressListComponent,
    IconComponent,
    LoadingBarComponent,
    QuickviewComponent,
    CurrencyFormatPipe,
    SafeUrlPipe,
    SideNavLinkItemComponent,
    ProfileWidgetComponent,
    InquiriesWidgetComponent,
    TrafficWidgetComponent,
    YPointsWidgetComponent,
    PricingComponent,
    PricingByPostComponent,
    DisableControlDirective,
    PlanSummaryComponent,
    PayByPostPlanSummaryComponent,
    AddOnsComponent,
    CheckOutComponent,
    CreateNewCompanyComponent,
    CompanyFormComponent,
    CompanyResultComponent,
    NewCompanyFormComponent,
    ClaimCompanyProfileComponent,
    ClaimCompanyFormComponent,
    NoPlanSummaryComponent,
    DndDirective,
    ExportPaginationComponent,
    AddressFormComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro,
  ],
  exports: [
    ViewPostComponent,
    IconComponent,
    LoadingBarComponent,
    QuickviewComponent,
    SideNavLinkItemComponent,
    ProfileWidgetComponent,
    InquiriesWidgetComponent,
    YPointsWidgetComponent,
    TrafficWidgetComponent,
    PricingComponent,
    PricingByPostComponent,
    DisableControlDirective,
    CurrencyFormatPipe,
    SafeUrlPipe,
    PlanSummaryComponent,
    PayByPostPlanSummaryComponent,
    AddOnsComponent,
    CheckOutComponent,
    CreateNewCompanyComponent,
    ClaimCompanyProfileComponent,
    NoPlanSummaryComponent,
    DndDirective,
    AddressListComponent,
    ExportPaginationComponent
  ]
})
export class SharedModule { }
