import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {apiUrl} from '../../data/token';
import { environment } from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {State, RouteData, PromoCodeState, SelectedPlan, DiscountAmountState} from 'src/app/shared/interfaces/state';
import {IOption} from 'ng-uikit-pro-standard';
import {ApiTokenService} from 'src/app/auth/services/api-token.service';
import {Observable, BehaviorSubject, Subject} from 'rxjs';


const INITIAL_STATE: RouteData = {
  routeType: '',
};

const SELECTED_INITIAL_STATE: SelectedPlan = {
  data: {}
};

const INITIAL_PROMOCODDE_STATE: PromoCodeState = {
  isPromoCodeApplied: false,
}
const INITIAL_DISCOUNTAMOUNT_STATE: DiscountAmountState = {
  discountAmount: 0,
}
@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }
  
  private _routeDataState = new BehaviorSubject<RouteData>(INITIAL_STATE);
  private _isPromoCodeApplied = new BehaviorSubject<PromoCodeState>(INITIAL_PROMOCODDE_STATE);
  private _discountAmount = new BehaviorSubject<DiscountAmountState>(INITIAL_DISCOUNTAMOUNT_STATE);
  private _isMobileSelectedState =new BehaviorSubject<SelectedPlan>(SELECTED_INITIAL_STATE);
  private _activePlanState =new BehaviorSubject<SelectedPlan>(SELECTED_INITIAL_STATE);
  private _pppPlanState   =new Subject<any>();
  private _regularPlanState =new Subject<any>();
  private _paymentTermState =new Subject<any>();
  private testSubject =new Subject<any>();
  private _removePromoCodeTrigger = new Subject<any>();
  
  currentRouteType = this._routeDataState.asObservable(); 
  isPromoCodeAppliedState = this._isPromoCodeApplied.asObservable();
  currentDiscountAmount = this._discountAmount.asObservable();

  setRouteType(route: string) {
    const oldState = this._routeDataState.getValue()
    this._routeDataState.next({ ...oldState, routeType: route });
  }

  fetchAllState(route): Observable<IOption[]> {
    return this.http.get<IOption[]>(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(result => {
          if (result.hasOwnProperty('data')) {
            const obj = result['data'];
            return Object.keys(obj).map(key => obj[key]) as State[];
          }
        }),
        map(states => {
          return states.map(state => {
            const stateOption: IOption = {
              value: (state.id).toString(),
              label: state.name,
              icon: ''
            };
            return stateOption;
          });
        })
      );
  }

  fetchAddressByPostCode(postCode) {
    return this.http.get(`${environment.apiUrl}/postcode/${postCode}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'];
        }
      })
    );
  }

  // active plan
  setActivePlan(selectedActvProfile) {
    console.log(selectedActvProfile);
    this._activePlanState.next({data : selectedActvProfile});
  }

  getActivePlan(){
    return this._activePlanState.asObservable();
  }

  // onSelect pay per post plan
  onSelectPayPerPostPlan(pppPlanData){
    this._pppPlanState.next({data: pppPlanData});
  }

  getPayPerPostPlan() {
    return this._pppPlanState.asObservable();
  }

  // onSelect pricing plan cards
  onSelectRegularPlan(regularPlanData){
    this._regularPlanState.next({data: regularPlanData});
  }

  getRegularPlanObj() {
    return this._regularPlanState.asObservable();
  }

  // onChange payment term button switch/checkbox
  onSelectPaymentTerm(paymentTerm){
    this._paymentTermState.next({data: paymentTerm});
  }

  getPaymentTerm() {
    return this._paymentTermState.asObservable();
  }

  // onSelect pricing plan but wanted to remove promo code
  onSelectPricingPlan(item: object){
    this._removePromoCodeTrigger.next(item);
  }

  getSelectedPricingPlan(){
    return this._removePromoCodeTrigger.asObservable();
  }

  // to track what promo code was applied
  setPromoCode(isPromoApplied: boolean) {
    const oldState = this._isPromoCodeApplied.getValue()
    this._isPromoCodeApplied.next({ ...oldState, isPromoCodeApplied: isPromoApplied });
  }

  getPromoCode(){
    return this._isPromoCodeApplied.asObservable();
  }

  // to track currently selected plan 
  setCurrentSelectedPlan(selectedPlan) {
    const oldState = this._isPromoCodeApplied.getValue()
  }

  setSelectedPlan(selectedPlan) {
    this.testSubject.next({data: selectedPlan});
  }

  // set discounted amount
  discountAmount(discountAmount: number) {
    const oldState = this._discountAmount.getValue()
    this._discountAmount.next({ ...oldState, discountAmount: discountAmount });
  }

}

