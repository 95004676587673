<mdb-card class="view-post__card">
    <mdb-card-body>
        <div class="row">
            <section class="col-12 col-md-8" style="border-right:1px solid #eee">
                <div class="header">
                    <div class="like ">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" class="icon ">
            <path
              d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z"
              class=""></path>
          </svg>
                    </div>
                    <div class="logo ">
                        <!-- <img class="img-responsive img-fluid" src="https://assets.yellowpages.my/images/logo/thumb/webp/239554_photo_40494.webp"> -->
                        <img *ngIf="selectedImage?.image?.full" class="img-responsive img-fluid" [src]="host + selectedImage?.image?.full">
                    </div>
                    <div class="details ">
                        <div class="details_f ">
                            <div class="">
                                <div class="title  ">
                                    <h2 class="yp-h2">{{previewPostData?.title}}<span class="">(T.121799-A)</span></h2>
                                </div>
                                <div class="rating-container ">
                                    <div style="display: flex;">
                                        <div class="star " *ngFor="let star of stars">
                                            <div style="font-size: 0.7em;" [ngClass]="{'selected': (star <= previewPostData?.rating)}">
                                                <i class="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="rating location ">{{previewPostData?.rating || 0}} from 5 Reviews</div>
                                </div>
                                <div class="location">
                                    <div>{{previewPostData?.profile.name}}</div>
                                    <div *ngIf="previewPostData && previewPostData?.locations[0]">
                                        {{previewPostData?.locations[0]?.city?.name || previewPostData?.locations[0]?.city}}, {{previewPostData?.locations[0]?.state.name}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="badges">
                            <div class=" GOLD merchant-badge ng-star-inserted" style="">
                                <div class="icon_type "></div> GOLD
                            </div>
                        </div>

                        <!---->
                    </div>

                    <!-- <div class="rating-container">
          <div class="star" *ngFor="let star of stars">
            <div style="font-size: 0.7em;" [ngClass]="{'selected': (star <= previewPostData?.rating)}">
              <i class="fa fa-star"></i>
            </div>
          </div>
          <div class="rating location">{{previewPostData?.rating || 0}} from 5 Reviews</div>
        </div> -->
                    <!-- <div class="location">
          <div>{{previewPostData?.profile.name}}</div>
          <div *ngIf="previewPostData && previewPostData?.locations[0]">{{previewPostData?.locations[0]?.city?.name ||
            previewPostData?.locations[0]?.city}}, {{previewPostData?.locations[0]?.state.name}} </div>
        </div> -->
                </div>
                <div>
                    <button type="button" class="yp-button blue sm font">Enquiry Now</button>
                    <button type="button" class="yp-button white sm font">Call</button>
                </div>
                <p class="imgInfo">{{previewPostData?.summary}}</p>
                <div style="border-bottom: 1px solid #ececec;padding-bottom: 1em;">
                    <!-- <pre>{{previewPostData?.images | json }}</pre> -->
                    <div class="full-view">
                        <img *ngIf="selectedImage?.image?.full" class="main-image" [src]="host + selectedImage?.image?.full">
                        <video class="main-video" *ngIf="selectedImage?.mime_type?.includes('video')" controls>
            <source [src]="host + selectedImage?.video?.file" [type]="selectedImage.mime_type" />
            Browser not supported
          </video>
                    </div>
                    <div class="image-selector">
                        <ng-container *ngFor="let img of previewPostData?.images">
                            <img *ngIf="img?.mime_type?.includes('image') || img && img.image?.thumbnail" [ngClass]="{isActive: selectedImage.id == img.id}" [src]="host + img.image.thumbnail" (click)="this.selectedImage = img">

                            <video *ngIf="img?.mime_type?.includes('video')" controls (click)="this.selectedImage = img">
              <source [src]="host + img?.video?.file" [type]="img.mime_type"
                [ngClass]="{isActive: selectedImage.id == img.id}" />
              Browser not supported
            </video>

                        </ng-container>


                    </div>
                </div>
                <div class="yp-h2">About Us</div>
                <div class="location">{{previewPostData?.service.description}}</div>

                <!-- <div class="yp-h2">Specialized in :</div>
      <div class="location">Branded Multifunctional Copier
        Printer
        Original/OEM Toner/catridge/ink/master roll & spare parts
        RICOH
        CANON
        SHARP
      </div> -->

                <!-- <div class="yp-h2">Our Showroom :</div>
      <div class="location">
        Ground Floor, SL 331,
        Lot 6657, Tabuan Laru Commercial Centre,
        Off Jalan Setia Raja,
        93350 Kuching, Sarawak.
      </div> -->
            </section>

            <aside class="col-12 col-md-4 rightSec">
                <!-- <div class="row">
        <div class="col minPrice " *ngIf="previewPostData?.service.price_min">
          <label>From</label>
          <p class="RMValue">{{previewPostData?.service.price_min}}</p>
        </div>
        <div class="col minPrice " *ngIf="previewPostData?.service.price_max">
          <label>To</label>
          <p class="RMValue">{{previewPostData?.service.price_max}}</p>
        </div>
      </div> -->


                <div class="row" style="border: 1px solid rgba(0, 0, 0, 0.125);margin-left: 1px;margin-top: 15px;">
                    <div class="location address" style="float: left;width: 75%;">
                        <p>Earn 144 when you use this service</p>
                        <p>69 people uses this service</p>
                        <p>500 Views</p>
                        <p>Share Services</p>
                        <p>Report</p>
                    </div>
                    <div style="float: right;padding-top: 15%;
          ">
                        <a target="_blank" class="icon direction"></a>
                        <a target="_blank" class="icon waze"></a>
                    </div>
                </div>
                <div class="info-details ">
                    <div class="social ">
                        <div class="title ">Share</div><button sharebutton="facebook" class=" sb-facebook" aria-label="Share on Facebook" style="--button-color:#4267B2;"><img
              src="/assets/images/preview-post/facebook.svg" height="20px" alt="facebook" class=""></button><button sharebutton="twitter" class=" sb-twitter" aria-label="Share on Twitter" style="--button-color:#00acee;"><img src="/assets/images/preview-post/twitter.svg" height="20px"
              alt="twitter" class=""></button><button sharebutton="linkedin" class=" sb-linkedin" aria-label="Share on LinkedIn" style="--button-color:#006fa6;"><img
              src="/assets/images/preview-post/linkedin.svg" width="18px" alt="linkedin" class=""></button><button sharebutton="email" class=" sb-email" aria-label="Share link via email" style="--button-color:#FF961C;"><img src="/assets/images/preview-post/email.svg" height="20px" alt="email"
              class=""></button><button sharebutton="copy" class=" sb-copy" aria-label="Copy link" style="--button-color:#607D8B;"><img src="/assets/images/preview-post/copy.svg" height="20px" alt="copy"
              class=""></button>
                    </div>
                    <div class="other-info ">
                        <div class="views "><img src="/assets/images/preview-post/view.svg" width="22px" alt=""><strong style="">&nbsp;2910 </strong>&nbsp;Views</div>
                        <div class="report blue-hover "><img src="/assets/images/preview-post/alert.svg" height="20px" alt="">&nbsp;Report</div>
                    </div>
                </div>


                <div class="reviewContainer">
                    <div class="yp-h2">Reviews</div>
                    <div>
                        <!-- <label class="reviewLabel">Write a review</label> -->
                        <textarea placeholder="Your review" cols="45" rows="5" class="col-sm-12" style="padding: 8px;"></textarea>
                    </div>
                    <div class="rating-container">
                        <div style="display: flex;">
                            <div class="star" *ngFor="let star of stars">
                                <span style="font-size: 0.7em; padding: 0px 5px;" [ngClass]="{'selected': (star <= selectedReviewValue)}" (click)="countStar(star)">
              <i class="fa fa-star"></i>
            </span>
                                <!-- <span style="font-size: 0.7em; color: yellow;">
                <i class="fa fa-star-half"></i>
              </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="reviewButtons">
                        <label for="file" class="upload-photo full yp-button">
            <div class="icon-upload"></div>
          </label>
                        <button type="button" class="yp-button blue sm font">Submit</button>
                    </div>

                    <div class="feedback" *ngFor="let review of previewPostData?.reviews">
                        <p>Admin {{review.id}}</p>
                        <p>Admin</p>
                        <div class="rating-container">
                            <div class="rating-container" *ngFor="let star of stars">
                                <span style="font-size: 0.7em;" [ngClass]="{'selected': (star <= review.rating)}">
                <i class="fa fa-star"></i>
              </span>
                                <!-- <span style="font-size: 0.7em; color: yellow;">
                <i class="fa fa-star-half"></i>
              </span> -->
                            </div> &nbsp; {{review.rating}} / 5
                        </div>
                        <p>{{review.content}}</p>
                        <!-- <div class="feedbackImg">
            <img src="https://dummyimage.com/48x50/000/fff"/>
            <img src="https://dummyimage.com/48x50/000/fff"/>
          </div> -->
                        <!-- <p class="period">{{review.created_at}}</p> -->
                    </div>

                </div>
            </aside>

        </div>

    </mdb-card-body>
</mdb-card>