import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IOption} from 'ng-uikit-pro-standard';
import {pairwise, startWith} from 'rxjs/operators';
import {AddOnsService} from 'src/app/shared/services/api/add-ons.service';
import {CompaniesService} from 'src/app/shared/services/api/companies.service';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupAdminComponent implements OnInit {

  profileData: any;
  caliculatePrice: any;
  profile_id: string;
  tokensTopups: any;
  selectedTopUp: any;
  
  isFormVisible = true;
  isTopUpSuccess = false;

  constructor(
    private addOnsSer: AddOnsService,
    private route: ActivatedRoute,
    private router: Router,
    private compService: CompaniesService,

  ) { }
  optionsTopup: IOption[] = [
  ];

  topupForm: FormGroup = new FormGroup({
    token_id: new FormControl('', []),
  });

  billingFormGroup: FormGroup = new FormGroup({
    billing_name: new FormControl('', [Validators.required]),
    billing_address_line1: new FormControl('', [Validators.required]),
    billing_address_line2: new FormControl(''),
    billing_payment_method: new FormControl('', [Validators.required]),
    update_billing_info: new FormControl(''),
    // billing_city_id: new FormControl('', [Validators.required]),
    billing_city: new FormControl('', [Validators.required]),
    billing_state_id: new FormControl('', [Validators.required]),
    billing_country_id: new FormControl('1', [Validators.required]),
    billing_postcode: new FormControl('', [Validators.required]),
    unifi_id: new FormControl('NA', [Validators.required]),
    unifi_name: new FormControl('NA', [Validators.required]),
    unifi_phone_number: new FormControl('NA', [Validators.required]),
    unifi_authorized: new FormControl(true, [Validators.requiredTrue]),
    promo_code: new FormControl('', []),
    referral_code: new FormControl(),
    sales_id: new FormControl(),
    referral: new FormControl(),
    t_c: new FormControl('', Validators.requiredTrue),
    faq: new FormControl('', Validators.requiredTrue),
  });

  ngOnInit(): void {
    this.getTopup();
    this.listenForFormChanges();
    this.profile_id = this.route.snapshot.paramMap.get('id');
    this.getProfileDetails(this.profile_id);
  }

  getProfileDetails(profileId) {
    this.compService.getProfileDetails(profileId).subscribe(res => {
      this.profileData = res;
    }, err => {
      console.log(err);
    });
  }

  getTopup() {
    this.addOnsSer.fetchAllTopup(`token/price`)
      .subscribe((topup) => {
        this.tokensTopups = topup;
        this.optionsTopup = topup.map((d: any) => ({
          ...d,
          value: d.id, label: `Token ${d.token_count} - RM ${d.price}`
        }));
      });
  }


  listenForFormChanges() {
    this.topupForm.get('token_id').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([old_token_id, token_id]) => {

        this.calculateTopupPrice(token_id);
      });
  }

  calculateTopupPrice(token_id) {
    // this.caliculatePrice = null;
    this.selectedTopUp = this.tokensTopups.find(topup => topup.id === token_id);
    this.addOnsSer.calculateTopupPrice({token_id})
      .subscribe((summary) => {
        this.caliculatePrice = summary;
      });
  }

  checkout() {
    this.addOnsSer.postToken({
      profile_id: this.profile_id,
      ...this.topupForm.value,
      ...this.billingFormGroup.value
    }).subscribe((summary) => {
      this.isTopUpSuccess = true;
      this.isFormVisible = false;
    }, err => {
      this.isTopUpSuccess = false;
      this.isFormVisible = false;
    });
  }

  gotoManageAdminProfile() {
    this.router.navigate(['/admin/manage-profile']);
  }

}
