import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// import {apiUrl} from '../../data/token';
import { environment } from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {Post} from 'src/app/shared/interfaces/post';
import {PayByPost} from 'src/app/shared/interfaces/plan';
import {ApiTokenService} from 'src/app/auth/services/api-token.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }

  createPost(route: string, postData) {
    return this.http.post(`${environment.apiUrl}/${route}`, postData, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'] as Post;
        }
      })
    );
  }

  updatePost(route: string, data) {
    console.log(route);
    return this.http.put(`${environment.apiUrl}/${route}`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'] as Post;
        }
      })
    );
  }

  getPostId(route: string) {
    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'];
        }
      })
    );
  }

  fetchPayByPostPackages(route: string) {
    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'];
        }
      })
    );
  }
  // Not in Use
  // fetchPayByPostPackages(route: string) {
  //   return this.http.get(`${apiUrl}/${route}`, { headers: this.apiHeader.fetchToken() })
  //     .pipe(
  //       map(result => {
  //         if (result.hasOwnProperty('data')) {
  //           const obj = result['data'];
  //           return Object.keys(obj).map(k => obj[k]) as PayByPost[]
  //         }
  //       })
  //     );
  // }


  caliculatePrice(route: string, body) {
    return this.http.post(`${environment.apiUrl}/${route}`, body, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(result => {
          if (result.hasOwnProperty('data')) {
            const obj = result['data'];
            return obj;
          }
        })
      );
  }

  boostPlan(route: string, body) {
    return this.http.put(`${environment.apiUrl}/${route}`, body, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(result => {
          if (result.hasOwnProperty('data')) {
            const obj = result['data'];
            return obj;
          }
        })
      );
  }


  uploadFile(imageUploadURL, files, bodyTag = 'file', _params = {}) {
    let params = new HttpParams();
    Object.keys(_params).forEach((k) => {
      params = params.set(k, _params[k]);
    });

    console.log(files);
    let input = new FormData();
    // input.append("uploadfile", files[0]);
    input.append(bodyTag, files);


    return this.http.post(`${environment.apiUrl}/advertiser/${imageUploadURL}`, input, {
      params,
      // reportProgress: true,
      // observe: 'events'
    });

  }
  uploadLogo(imageUploadURL, files, bodyTag = 'file', _params = {}) {
    let params = new HttpParams();
    Object.keys(_params).forEach((k) => {
      params = params.set(k, _params[k]);
    });

    console.log(files);
    let input = new FormData();
    // input.append("uploadfile", files[0]);
    input.append(bodyTag, files);


    return this.http.post(`${environment.apiUrl}/advertiser/profile/logo`, input, {
      params,
      // reportProgress: true,
      // observe: 'events'
    });

  }

  uploadDocuments(files, bodyTag = 'file', _params = {}) {
    let params = new HttpParams();
    Object.keys(_params).forEach((k) => {
      params = params.set(k, _params[k]);
    });

    console.log(files);
    let input = new FormData();
    // input.append("uploadfile", files[0]);
    input.append(bodyTag, files);


    return this.http.post(`${environment.apiUrl}/advertiser/profile/document`, input, {
      params,
      // reportProgress: true,
      // observe: 'events'
    });

  }

  getBasicPostPrice(specialization_id, repost, type, profile_id, post_id) {
    const payload = {
      specialization_id: specialization_id,
      repost: repost,
      type: type,
      profile_id: profile_id,
      post_id: post_id,
    };
    return this.http.post(`${environment.apiUrl}/advertiser/post/basic/price`, payload, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'];
        }
      })
    );
  }

  applyPromoCode(route: string, body) {
    return this.http.post(`${environment.apiUrl}/${route}`, body, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(result => {
          if (result.hasOwnProperty('data')) {
            const obj = result['data'];
            return obj;
          }
        })
      );
  }

}
