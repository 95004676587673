<ng-container *ngIf="isResult; else newCompanyForm">
    <!-- Pricing package(plans) shall remove it -->
    <!-- <app-pricing 
    [adsPlanForm]="adsPlanForm" 
    [pricingPlans]="pricingPlans" 
    [isLoading]="isLoading" 
    [isResult]="isResult" 
    [isSuccess]="isSuccess" 
    [paymentResult]="paymentResult" 
    [isNewCompanyPayment]="isNewCompanyPayment"
    (onPaymentTrigger)="onPayment()"
    ></app-pricing>  -->


    <div class="payment-message" *ngIf="createCompanyResult">

        <div class="payment-message__title">
            <h5 *ngIf="isSuccess">Company Name: {{ createCompanyResult.profile.name }} Company No.: {{ createCompanyResult.profile.company_no }}</h5>
            <h5 *ngIf="!isSuccess">Creating company profile unsuccessfully..</h5>
        </div>
        <div class="payment-message__body">
            <p *ngIf="isSuccess">Company is now created successfully, will wait for approval, by the way you can continue creating post with draft profile and approval can be don for both.</p>
            <p *ngIf="!isSuccess">Creating company profile unsuccessfully</p>
        </div>
        <div class="payment-message__btns">
            <button type="button" size="md" mdbBtn color="primary" mdbWavesEffect (click)="closeModal()" *ngIf="isSuccess">continue with creating post</button>
            <button type="button" mdbBtn color="danger" mdbWavesEffect (click)="closeModal()" *ngIf="!isSuccess">Finish</button>
        </div>
    </div>


    
    
</ng-container>
<ng-template #newCompanyForm>
    <div class="create-new-company-form" [ngClass]="{'preloader-trigger': isLoading}">
        <div class="preloader">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
        </div>

        <form [formGroup]="createNewCompanyFormGroup">
            <!-- Grid row -->
            <div class="form-row">
                <!-- Default input -->
                <div class="form-group col-md-6">
                    <label for="name">Company Name </label>
                    <input mdbInput type="text" class="form-control" id="name" formControlName="name">
                </div>
                <!-- Default input -->
                <div class="form-group col-md-6" formGroupName="address">
                    <label for="BillingAddress1">Company Address 1 <strong class="red-text">*</strong></label>
                    <input mdbInput type="text" class="form-control" id="BillingAddress1" formControlName="billingAddress1">
                </div>
            </div>
            <!-- Grid row -->
        
            <!-- Grid row -->
            <div class="form-row">
                <!-- Default input -->
                <div class="form-group col-md-6">
                    <label for="companyNo">Company No </label>
                    <input mdbInput type="text" class="form-control" id="companyNo" formControlName="companyRN">
                </div>
                <!-- Default input -->
                <div class="form-group col-md-6" formGroupName="address">
                    <label for="BillingAddress2">Company Address 2</label>
                    <input mdbInput type="text" class="form-control" id="BillingAddress2" formControlName="billingAddress2">
                </div>
            </div>
            <!-- Grid row -->
            <!-- Grid row -->
            <div class="form-row new-company-form">
                <!-- Default input -->
                <div class="form-group col-md-6">
                    <label for="contactNumber">Contact Number <strong class="red-text">*</strong></label>
                    <input mdbInput type="text" class="form-control" id="contactNumber" formControlName="companyContactNo">
                </div>
                <!-- Default input -->
                <div class="form-group col-md-6 d-flex align-items-center justify-content-between" formGroupName="address">
                    
                    <mdb-select [allowClear]="true" [options]="optionsState" [outline]="true" [filterEnabled]="true" [customClass]="'state-select'"   formControlName="state"></mdb-select>
                    <mdb-select [allowClear]="true" [options]="optionsCity" [outline]="true" [filterEnabled]="true" [customClass]="'city-select'" formControlName="city"></mdb-select>
                </div>
            </div>
            <!-- Grid row -->
            <!-- Grid row -->
            <div class="form-row new-company-form">
                <!-- Default input -->
                <div class="form-group col-md-6">
                    <label for="inputEmail">Email Address</label>
                    <input mdbInput type="email" class="form-control" id="inputEmail" formControlName="companyEmail">
                </div>
                <!-- Default input -->
                <div class="form-group col-md-6 d-flex align-items-center justify-content-center" formGroupName="address">
                    
                    <mdb-select [allowClear]="true" [options]="optionsCountry" [outline]="true" [filterEnabled]="true" [customClass]="'country-select'" formControlName="country"></mdb-select>
                    <input mdbInput type="text" class="form-control mt-4" id="postcode" placeholder="Postcode" formControlName="postcode">
                </div>
            </div>
            <!-- Grid row -->
        
            <!-- Grid row -->
            <div class="form-row">
                <!-- Default input -->
                <div class="form-group col-md-6">
                    <label for="inputWebsite">Company Website</label>
                    <input mdbInput type="text" class="form-control" id="inputWebsite" formControlName="companyWebsite">
                </div>
                <!-- Default input -->
                <div class="form-group col-md-6 mt-3">
                    Please attach supporting documents to verify your
                    Company Profile
                    <p>e.g Business Registration Cert, Business License, Utilities Bill</p>
                </div>
            </div>
            <!-- Grid row -->
        
    
            <div class="new-company-form__btns">
                <button type="button" mdbBtn size="md" color="dark" mdbWavesEffect>back</button>
                <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect  (click)="onPayment()" [disabled]="!createNewCompanyFormGroup.valid">Proceed to select plan</button>
            </div>
        
        </form>

    </div>

</ng-template>
