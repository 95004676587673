import { Injectable, OnInit } from '@angular/core';
import { StorageService } from 'src/app/auth/services/storage.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class UserUtilsService implements OnInit {

    constructor(
        private storage: StorageService,
        private auth: AuthService
    ) {        
        // this.auth.initializeUserStorage();
        // this.user = this.storage.retrieve('user');
    }

    ngOnInit(): void {

    }

    checkUserIsAdmin() {
        const user = this.storage.retrieve('user');
        return (user['roles'].filter(role => role.name.toLowerCase() === 'admin').length > 0) ? true : false;
    }

    checkUserIsAdvertiser() {
        const user = this.storage.retrieve('user');
        return (user['roles'].filter(role => role.name.toLowerCase() === 'advertiser').length > 0) ? true : false;
    }

    verifyUserRole(roles: any, roleName: string) {
        // console.log(roles);
        // console.log(roleName);
        return (roles.filter(role => role.name.toLowerCase() === roleName).length > 0) ? true : false;
    }

    isProfileComplete(userObj: any) {
        if (this.verifyUserRole(userObj.roles, "admin")) {
            return true;
        }
        
        if (userObj.phone_number == "" || userObj.phone_number == null) {
            return false;
        }

        return true;
    }
}