import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckCompany, CheckCompanyRespone } from 'src/app/shared/interfaces/advertiser-profile';
import { PricingItem } from 'src/app/shared/interfaces/plan';
import { PlanService } from 'src/app/shared/services/api/plan.service';


@Component({
  selector: 'app-company-result',
  templateUrl: './company-result.component.html',
  styleUrls: ['./company-result.component.scss']
})
export class CompanyResultComponent implements OnInit {



  @Input() companyValues: CheckCompany; //new company form data 
  @Input() checkCompanyRespone: CheckCompanyRespone; // existing company respone
  @Input() isNewCompanyForm: boolean; // to create new company 
  @Input() canClaim: boolean; // if existing company can claim
  @Input() isClaimableMsg: boolean; // 

  @Output() claimCompany: EventEmitter<boolean> = new EventEmitter();
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter();

  constructor( private router: Router) { }

  ngOnInit(): void {}


  onContact() {
    this.router.navigate(['/dashboard']);
  }

  onClaim() {
    this.claimCompany.emit()
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

}
