import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthState } from 'src/app/auth/models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {

  constructor() { }

  fetchToken(): HttpHeaders {

    const localStorageAuthData = localStorage.getItem('authData');

    if (!localStorageAuthData) {
      return
     } 

    const lsData: AuthState = JSON.parse(localStorageAuthData)
    const lsToken = lsData.token;
    const header: HttpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${lsToken}`,
    });
    return header
  }

  
}
