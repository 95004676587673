<ng-container *ngIf="isResult; else noResult">

  <ng-container *ngIf="isSuccess; else noPayment">

    <div class="payment-message" *ngIf="paymentResult">

      <div class="payment-message__title">
        <h5>
          Company Name: {{ paymentResult.name }} Company No.: {{ paymentResult.company_no }}
        </h5>
      </div>
      <div class="payment-message__body">
        <p>Company is now subscribed to {{ paymentResult.plan_id }} package and is being verified!</p>
      </div>
      <div class="payment-message__btns">
        <button type="button" size="md" mdbBtn color="primary" mdbWavesEffect>Check verification status</button>
      </div>
    </div>

  </ng-container>
  <ng-template #noPayment>

    <div class="payment-message" *ngIf="!paymentResult">
      <div class="payment-message__title">
        <h5>Payment unsuccessfully..</h5>
      </div>
      <div class="payment-message__body">
        <p>Payment Failed</p>
      </div>
      <div class="payment-message__btns">
        <button type="button" mdbBtn color="danger" mdbWavesEffect>Finish</button>
      </div>
    </div>

  </ng-template>


</ng-container>
<ng-template #noResult>

  <form [formGroup]="adsPlanForm">
    <div class="row text-center ads-form-mb" style="padding-top: 3rem;">
      <div class="switch col-md-12">
        <label>
            Billed Monthly
            <input type="checkbox" mdbInput formControlName="payment_term" (click)="changePaymentTerm($event)"  [checked]="false">
            <span class="lever"></span> Billed Annually 
        </label>
      </div>
    </div>

    <div class="pricing-package" [ngClass]="{'preloader-trigger': isLoading}">
      <div class="preloader">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
      </div>

      <div class="pricing-package__item" *ngFor="let plan of pricingPlans" [ngClass]="{'gold-border': plan.type === 'GOLD', 'silver-border': plan.type === 'SILVER','bronze-border': plan.type === 'BRONZE'}">
        <div class="form-check pricing-package__form-check" [ngClass]="{div__disabled: plan.isDisable}">
          <input (change)="onSelectPricingPlan(plan)" type="radio" class="form-check-input with-gap pricing-package__form-check-input" id="{{plan.id}}"
            mdbInput formControlName="package" [value]="plan.id">        
          <label class="form-check-label pricing-package__form-check-label" for="{{plan.id}}">
            <div class="pricing-package__card"
              [ngClass]="{'gold-border': plan.type === 'GOLD', 'silver-border': plan.type === 'SILVER','bronze-border': plan.type === 'BRONZE','choosed': plan.id === package.value}">
              <div class="pricing-package__card--header">
            
                <div class="pricing-package__card--header-type" [ngClass]="{'gold-color': plan.type === 'GOLD', 
                'silver-color': plan.type === 'SILVER',
                'bronze-color': plan.type === 'BRONZE'}">
                    <div class="merchant-badge">
                  <div class="icon"></div>
                </div>
                  <div class="pricing-package__card--header-type--title">
                    {{ plan.type | uppercase }}
                  </div>
                </div>
               
              <div class="pricing-package__card--body">
                <div class="pricing-package__card--body-list">
                  <ul>
                    <li *ngFor="let feature of plan.features">
                      <div class="pricing-package__card--body-list--icon">
                        <app-icon *ngIf="feature.isIncluded" name="check" size="15"></app-icon>
                        <app-icon *ngIf="!feature.isIncluded" name="non-check" size="15"></app-icon>
                      </div>
                      <div class="pricing-package__card--body-list--text">
                        <div class="feature">

                          <div class="line1" *ngIf="feature.id == 1">
                            {{ feature.amount }} {{ feature.feature }} / {{ feature.adsPost }}
                          </div>

                          <div class="line2" *ngIf="feature.id == 2">
                            <strong>{{ feature.position }}</strong>
                            Search Ranking
                            <i class="fa fa-circle" style="font-size:15px;color:#dce5e5;"></i>
                            <i style="font-size:13px;color:black;margin-left: -10px; visibility: visible;" aria-hidden="true" data-toggle="tooltip" title="{{feature.place}}">i</i>
                          </div>

                          <div class="line2" *ngIf="feature.id == 3">
                            <strong>{{ feature.amount }}</strong> {{ feature.feature }}
                          </div>

                          <div class="line2" *ngIf="feature.id == 4">
                            <strong>{{ feature.amount }}</strong> {{ feature.feature }}
                          </div>

                          <div class="line2" *ngIf="feature.id == 5">
                            {{ feature.feature }}
                          </div>

                          <div class="line2" *ngIf="feature.id == 6">
                            {{ feature.feature }}
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <div class="pricing-package__card--body-btn" *ngIf="adsPlanForm.value.package!==plan.id">
                  <a type="button" mdbBtn mdbWavesEffect size="sm" block="true" [ngClass]="{'pricing-package__card--body-btn-gold': plan.type === 'GOLD', 
                                      'pricing-package__card--body-btn-silver': plan.type === 'SILVER',
                                      'pricing-package__card--body-btn-bronze': plan.type === 'BRONZE'}">choose me</a>
                </div>
                <div class="pricing-package__card--body-btn" *ngIf="adsPlanForm.value.package==plan.id">
                  <a type="button" mdbBtn mdbWavesEffect size="sm" block="true" [ngClass]="{'pricing-package__card--body-btn-gold': plan.type === 'GOLD', 
                                      'pricing-package__card--body-btn-silver': plan.type === 'SILVER',
                                      'pricing-package__card--body-btn-bronze': plan.type === 'BRONZE'}">SELECTED</a>
                </div> -->
              </div>
              <div class="pricing-package__card--header-price">

                <!-- <div class="pricing-package__card--header-price--amount"> -->
                <div class="pricing-package__card--header-price--amount-currency">RM</div>
                <div class="pricing-package__card--header-price--amount"*ngIf="paymentTerm === 'mo'">{{ plan.monthlyPrice }}</div>
                <div class="pricing-package__card--header-price--amount-duration" *ngIf="paymentTerm === 'mo'">/MONTH</div>
                <div class="pricing-package__card--header-price--amount"*ngIf="paymentTerm === 'yr'">{{ plan.price }}</div>
                <div class="pricing-package__card--header-price--amount-duration" *ngIf="paymentTerm === 'yr'">/YEAR</div>
                <!-- </div> -->
              </div>

            </div>
              <div class="pricing-package__card--body-btn" ng-click="onSelectPricingPlan()" *ngIf="adsPlanForm.value.package!==plan.id">
                <a type="button" (click)="onSelectPricingPlan(plan)" class="yp-button blue sm font" block="true" [ngClass]="{'pricing-package__card--body-btn-gold': plan.type === 'GOLD', 
                                    'pricing-package__card--body-btn-silver': plan.type === 'SILVER',
                                    'pricing-package__card--body-btn-bronze': plan.type === 'BRONZE'}">choose me</a>
              </div>
              <div class="pricing-package__card--body-btn" *ngIf="adsPlanForm.value.package==plan.id">
                <a type="button" class="yp-button blue sm font"   block="true" [ngClass]="{'pricing-package__card--body-btn-gold': plan.type === 'GOLD', 
                                    'pricing-package__card--body-btn-silver': plan.type === 'SILVER',
                                    'pricing-package__card--body-btn-bronze': plan.type === 'BRONZE'}">SELECTED</a>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="discount-message">
      Get 28% discount when using FPX with annual plans. Valid from 1st April 2022 to 31st May 2022.
    </div>
  </form>
  <div class="pricing-btns" *ngIf="isNewCompanyPayment">
    <button type="button" mdbBtn size="md" color="dark" mdbWavesEffect>Back</button>
    <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect [disabled]="!adsPlanForm.valid"
      (click)="onPayment()">Procced to Payment</button>
  </div>

</ng-template>