  <table class="">
    <tr>
      <td>
        <div> <select class="form-control custom-select-sm excel" name="format" [(ngModel)]="exportFormat">
            <option value="excel">Excel</option>
            <option value="csv">CSV</option>
          </select></div>
      </td>
      <td>
        <button class="yp-button blue sm font" (click)="exportAllPostData()">
          Export
        </button>
      </td>
      <td style="width: 50%;">
        <div style="display: flex;">

        <div class="pagination__spacing">
          <select class='form-control' #mySelect [(ngModel)]="perPage" (change)='onPageCountChange(mySelect.value)'>
            <option *ngFor='let option of perPageCountArr' [value]="option">{{option}}
            </option>
          </select>
        </div> 
        <p class="pagination__spacing hide">Records Per Page</p>
      </div>

      </td>
      <td class="pagination_num">        
        <div class="pagination-yp ">
          <div style="text-align: end;">

        <div >
          <nav aria-label="Page navigation example pagination__spacing" style="margin-top: 10px;">
            <ul class="pagination">
              <li class="page-item " [ngClass]="page === activePage ? 'active' : 'none'"  *ngFor="let page of pageInfo?.meta?.links" aria-current="page" (click)="selectPage(page)">
                <a class="page-link" (click)="updateDataTable(page?.url)">
                  <span>{{getPageLabel(page)}} </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div style="text-align: end;" class="yp-pagination-count">
          <p class="yp-font"> <span class="pagination-yp">{{pageInfo?.meta?.current_page}}</span> - <span class="pagination-yp">{{pageInfo?.data?.length}}</span> of <span class="pagination-yp">{{pageInfo?.meta?.total}}</span> Results</p>
        </div>
        </div>
          <!-- <p class="pagination__spacing hide">{{pageInfo?.meta?.total}} Records Found.</p> -->
        </div>
      </td>
    </tr>
  </table>
