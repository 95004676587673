<div class="company-result" >
    <!-- Case One New company -->
    <ng-container *ngIf="isNewCompanyForm; else messages">
        <div class="company-result__new-company-form">
            <app-new-company-form 
            [companyValues]="companyValues" 
            (closeModalEventEmitter)="closeModal()"
            ></app-new-company-form>
        </div>
    </ng-container>

    <ng-template #messages>
        <!-- company already exist has 2 cases -->
        <div class="company-result__messages">
            <ng-container *ngIf="canClaim; else cannotClaim">
               
              <ng-container *ngIf="isClaimableMsg; else claimcompany">

                <div class="company-result__message-can-claim" >
                    <div class="message">
                        <div class="message__header">
                            <div>Company Name: {{ companyValues.name}}</div>
                            <div>Company No.: {{ companyValues.company_no}}</div>
                        </div>
                        <div class="message__body">
                            <p>We have found this record in our YellowPages system. Do you want to claim it?</p>
                        </div>
                        <div class="message__btns">
                            <button type="button" mdbBtn size="md" color="dark" mdbWavesEffect >Cancel</button>
                            <button type="button" mdbBtn size="md" color="primary" mdbWavesEffect (click)="onClaim()">Proceed</button>
                        </div>
                    </div>
                </div>
                  
              </ng-container>
        
               <ng-template #claimcompany>
                   <app-claim-company-profile 
                   [checkCompanyRespone]="checkCompanyRespone"
                   (closeModalTrigger)="closeModal()"
                   ></app-claim-company-profile>
               </ng-template>
               
            </ng-container>
            <ng-template #cannotClaim>
                <div class="company-result__message-cannot-claim" *ngIf="checkCompanyRespone">
                    <div class="message">
                        <div class="message__header">
                            <div>Company Name: {{ companyValues.name}} </div>
                            <div>Company No.: {{ companyValues.company_no}}</div>
                        </div>
                        <div class="message__body">
                            <div>This company has already been claimed by other Advertiser.Please contact YellowPages admin for further assistance</div>
                            <div class="url">Company URL: <a routerLink="/">http://yellowpages.my/{{checkCompanyRespone.url}}</a></div>
                        </div>
        
                        <div class="message__btns">
                            <button type="button" size="md" mdbBtn color="primary" mdbWavesEffect (click)="onContact()">Contact yellowpages</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        
    </ng-template>
    
    

</div>