import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
// import { apiUrl } from '../../data/token';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { City } from 'src/app/shared/interfaces/city';
import { IOption } from 'ng-uikit-pro-standard';
import { ApiTokenService } from 'src/app/auth/services/api-token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CityService {


  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }

  fetchCitiesOfState(route: string): Observable<IOption[]> {

    return this.http.get<IOption[]>(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()})
    .pipe(
      map(result => {

        if (result.hasOwnProperty('data')) {
          const obj = result['data'];
          return Object.keys(obj).map(k => obj[k]) as City[];
        } 
      }),
      map(cities => {
        return cities.map(city => {
          const cityOption: IOption = {
            value: (city.id).toString(),
            label: city.name,
            icon: ''
          };
          return cityOption;
        })
      })
    );
  }

}
