import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {apiUrl} from '../../data/token';
import { environment } from 'src/environments/environment';
import {CheckCompany, CheckCompanyRespone, CreateCompanyProfilePayload, CreateCompanyProfileResult} from 'src/app/shared/interfaces/advertiser-profile';
import {map} from 'rxjs/operators';
import {ApiTokenService} from 'src/app/auth/services/api-token.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }

  checkCompany(route: string, data: CheckCompany) {
     
    const apiData = {
      name: data.name,
      company_no: data.company_no
    };
    return this.http.post(`${environment.apiUrl}/${route}`, apiData, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(res => res.hasOwnProperty('data') ? res['data'] as CheckCompanyRespone : null)
      );
  }
  checkIndividual(route: string, data: any) {
    return this.http.post(`${environment.apiUrl}/${route}`, data, {headers: this.apiHeader.fetchToken()})
      .pipe(
        map(res => res.hasOwnProperty('data') ? res['data'] as any : null)
      );
  }

  createNewCompany(data) {
  // createNewCompany(data: CreateCompanyProfilePayload) {
    return this.http.post(`${environment.apiUrl}/advertiser/profile`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] as CreateCompanyProfileResult : null)
    );
  }

  createProfile(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/advertiser/profile`, data, {headers: this.apiHeader.fetchToken()});
  }

  updateCompany(id, payload): Observable<CreateCompanyProfileResult> {
    return this.http.put<CreateCompanyProfileResult>(`${environment.apiUrl}/advertiser/profile/${id}`, payload, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }

  updateProfile(id, payload): Observable<any> {
    return this.http.put(`${environment.apiUrl}/advertiser/profile/${id}`, payload, {headers: this.apiHeader.fetchToken()});
  }

  validateCompanyNo(companyNo): any {
    return this.http.post<Boolean>(`${environment.apiUrl}/admin/company/number/validate`, {company_no: companyNo}, {headers: this.apiHeader.fetchToken()});
  }

  validateCompanyName(companyName): any {
    return this.http.post<Boolean>(`${environment.apiUrl}/admin/company/name/validate`, {company_name: companyName}, {headers: this.apiHeader.fetchToken()});
  }

  caliculatePlanPrice(payload): Observable<CreateCompanyProfileResult> {
    return this.http.post<CreateCompanyProfileResult>(`${environment.apiUrl}/advertiser/subscribe/calculate-price`,
      payload, {headers: this.apiHeader.fetchToken()}).pipe(
        map(res => res.hasOwnProperty('data') ? res['data'] : null)
      );
  }

  subscribePlan(payload): Observable<CreateCompanyProfileResult> {
    return this.http.post<CreateCompanyProfileResult>(`${environment.apiUrl}/advertiser/subscribe`,
      payload, {headers: this.apiHeader.fetchToken()}).pipe(
        map(res => res.hasOwnProperty('data') ? res['data'] : null)
      );
  }

    getProfileDetails(id): Observable<CreateCompanyProfileResult> {
    return this.http.get(`${environment.apiUrl}/advertiser/profile/${id}/details`,
      {headers: this.apiHeader.fetchToken()}).pipe(
        map(res => res.hasOwnProperty('data') ? res['data'] : null)
      );
  }

}
