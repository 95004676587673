import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { NavigationStart, Router, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('bar', {static: true}) bar: ElementRef;

  get element(): HTMLElement {
    return this.bar.nativeElement;
  }

  constructor(private router: Router, private ngZone: NgZone) {}

  ngOnInit(): void { }

  ngAfterViewInit() {

    this.ngZone.runOutsideAngular(() => {
      this.router.events.subscribe(e => {
        if (e instanceof NavigationStart) {
          this.element.classList.remove('loading-bar--start', 'loading-bar--complete', 'loading-bar--reset');
          this.element.getBoundingClientRect(); // force reflow
          this.element.classList.add('loading-bar--start');
        }

        if (e instanceof NavigationEnd || e instanceof NavigationError || e instanceof NavigationCancel) {
          if (this.element.classList.contains('loading-bar--start')) {
            this.element.classList.remove('loading-bar--start');
            this.element.classList.add('loading-bar--complete');
          }
        }
      });
    });

  }

  ngOnDestroy() {}

}
