import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PayByPost } from 'src/app/shared/interfaces/plan';
import { AddOnsService } from 'src/app/shared/services/api/add-ons.service';
import { AddonUtilsService } from 'src/app/shared/utils/addon-utils.service';

@Component({
  selector: 'app-pricing-by-post',
  templateUrl: './pricing-by-post.component.html',
  styleUrls: ['./pricing-by-post.component.scss'],
})
export class PricingByPostComponent implements OnInit {
  @ViewChild('sampleModal') sampleModal: any;
  @Input() payByPostPlans: PayByPost[];
  @Input() PBPFormGroup: FormGroup;
  @Input() addOnsFormGroup: FormGroup;
  @Input() showHeader;
  @Input() isPlanTab;
  @Input() basicPostPrice;

  addonPlans = [];
  postingPrice = {
    effective_day: 0,
    price: 0,
  };

  sampleImagePath: any;

  getSub(ev) {
    console.log(this.addOnsFormGroup.controls);
    console.log(ev);
    this.addOnsFormGroup.controls[ev.category].patchValue(ev.value);
  }

  deselectaddOnPlan(ev) {
    console.log(ev);
    this.addOnsFormGroup.controls[ev.category].patchValue('');
  }

  get payByPostPackage() {
    return this.PBPFormGroup.get('payByPostPackage');
  }

  constructor(private addOnsSer: AddOnsService, private addonUtils: AddonUtilsService) {}

  ngOnInit(): void {
    this.addOnsSer.fetchAllAddOns(`addonitems/individual`).subscribe((addonPlans) => {
      const postingPriceIndex = addonPlans.findIndex(
        (addonPlan) => addonPlan.category === 'ADS_POSTING',
      );
      const postingPrice = addonPlans.splice(postingPriceIndex, 1);
      this.postingPrice = postingPrice[0].addOns[0];
      this.addonPlans = addonPlans.map((d) => {
        d.label = this.addonUtils.convertAddonCategory(d.category);

        return {
          ...d,
          selection: d.addOns.map((a) => {
            if (!this.isPlanTab) {
              return {
                value: a.id,
                label: a.effective_day + ' days (RM' + a.price + ')',
                icon: '',
                category: d.category,
              };
            } else {
              return {
                value: a.id,
                label: a.effective_day + ' days (' + a.token_consume + ' Tokens)',
                icon: '',
                category: d.category,
              };
            }
          }),
        };
      });
    });
  }

  openExampleModal(feature: string) {
    this.sampleImagePath = null;

    if (feature == "Bumps Up") {
      this.sampleImagePath = "bumps-up-example.jpg";
    }

    if (feature == "Featured Post Home") {
      this.sampleImagePath = "featured-example.jpg";
    }

    this.sampleModal.show();
  }
}
