import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  api = environment.apiUrl + '/';
  public responseCache = new Map();
  constructor(private http: HttpClient) { }

  locationAutocomplete(query): Observable<any> {
    return this.http.post(`${this.api}cities/autocomplete`, {query});
  }
  countries(): Observable<any> {
    const url = `${this.api}countries`;
    const CountryCache = this.responseCache.get(url);
    if (CountryCache) {
      return of(CountryCache);
    }
    return this.http.get(url).pipe(tap(d => {
      this.responseCache.set(url, d);
    }));
  }
  states(): Observable<any> {
    const url = `${this.api}states`;
    const StateCache = this.responseCache.get(url);
    if (StateCache) {
      return of(StateCache);
    }
    return this.http.get(url).pipe(tap(d => {
      this.responseCache.set(url, d);
    }));
  }
}
