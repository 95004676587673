import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/auth/models/user';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap, filter } from 'rxjs/operators';
import { StorageService } from './storage.service';
// import { apiUrl } from 'src/app/shared/data/token';
import { NavigationEnd, Router } from '@angular/router';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service'


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // private userSub$: Subject<User> = new Subject();
    user$: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private router: Router
    ) {
        console.log('AuthService');
        this.user$.subscribe(
            x => {}
        );
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
            x => {
                this.checkLogin();
            }
        );
    }

    checkLogin() {
        try {
            this.cookieInit().subscribe(res => {
                this.getUser().subscribe(user => {
                    this.user$.next(user);
                    this.storage.store('user', user);
                    
                    let urlValid = false;

                    if (this.router.url.includes("?plan=")) {
                        urlValid = true;
                    }

                    if (this.router.url === '' || this.router.url === '/' || urlValid) {
                        if (user.roles.filter(role => role.name.toLowerCase() === 'admin').length > 0) {
                            this.router.navigate(['/admin/dashboard']);
                        } else {
                            this.router.navigate(['/advertiser/dashboard']);
                        }
                    }
                },
                error => {
                    // console.log("Not logged in");
                })
            });
        } catch (error) {
            console.log(error);
        }
    }

    getUser() {
        return this.http.get(`${environment.apiUrl}/user`).pipe(
            map(res => res['data']),
            catchError(e => throwError(e))
        );
    }

    initializeUserStorage() {
        console.log("initializeUserStorage");
        this.cookieInit().subscribe(res => {
            this.http.get(`${environment.apiUrl}/user`).pipe(
                map(res => res['data']),
                catchError(e => throwError(e))
            ).subscribe(user => {
                this.user$.next(user);
                this.storage.store('user', user);
            })
        });
    }

    login(userEmail: string, userPassword: string) {
        return this.http.post(`${environment.apiUrl}/auth/login`, { email: userEmail, password: userPassword }).pipe(
            map(res => {
                const user = res['data'];
                this.storage.store('user', user);
                this.user$.next(user);
            }),
            catchError(err => throwError(err))
        );
    }

    logout() {
        this.storage.remove('user');
        this.user$ = new BehaviorSubject(null);
        return this.http.get(`${environment.apiUrl}/auth/logout`).toPromise();
    }
    updatePassword(payLoad) {
        return this.http.patch(`${environment.apiUrl}/account/password`, payLoad).pipe(
          map(res => {
            if (res?.hasOwnProperty('data')) {
                const obj = res;
                return obj;
              }
          }),
          catchError(err => throwError(err))
        );
    }

    updateProfile(payLoad) {
        return this.http.put(`${environment.apiUrl}/advertiser/account/profile`, payLoad).pipe(
            map(res => {
              if (res?.hasOwnProperty('data')) {
                  const obj = res;
                  return obj;
                }
            }),
            catchError(err => throwError(err))
          );
    }
    checkUser() {
        return this.http.get(`${environment.apiUrl}/user`);
    }

    checkPermission(authorities: any) {
        return this.http.post(`${environment.apiUrl}/permission`, { authorities: authorities });
    }



    private handleError(errorRes: HttpErrorResponse) {
        // this.user$ = of(null);
        // console.log(errorRes.error.message)
        // let errorMessage = 'Unauthorised';
        // if (!errorRes.error ) {
        //   return throwError(errorMessage);
        // }
        // // switch (errorRes.error.message) {
        // //   case 'Unauthorised':
        // //     errorMessage = 'Unauthorised';
        // //     break;
        // //   case 'EMAIL_NOT_FOUND':
        // //     errorMessage = 'This email does not exist.';
        // //     break;
        // //   case 'INVALID_PASSWORD':
        // //     errorMessage = 'This password is not correct.';
        // //     break;
        // // }
        return throwError(errorRes.error.message);
    }

    cookieInit() {
        return this.http.get<void>(`${environment.apiUrl}/sanctum/csrf-cookie`);
    }

    requestVerificationCode(payload) {
        return this.http.post(`${environment.apiUrl}/account/verification`, payload).pipe(
            map(res => {
                if (res?.hasOwnProperty('data')) {
                    const obj = res;
                    return obj;
                  }
            }),
            catchError(err => throwError(err))
        );
    }

    verifyVerificationCode(payload) {
        return this.http.post(`${environment.apiUrl}/advertiser/code/verify`, payload).pipe(
            map(res => {
                if (res?.hasOwnProperty('data')) {
                    const obj = res;
                    return obj;
                  }
            }),
            catchError(err => throwError(err))
        );
    }

    public password_verification(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/password/verification`, data);
    }

    public reset(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/password/reset`, data);
    }

}
