import { Component, Input, OnInit } from '@angular/core';
import { Inquiry } from 'src/app/shared/interfaces/inquiry';

@Component({
  selector: 'app-inquiries-widget',
  templateUrl: './inquiries-widget.component.html',
  styleUrls: ['./inquiries-widget.component.scss']
})
export class InquiriesWidgetComponent implements OnInit {

  @Input() inquiries: Inquiry[];

  constructor() { }

  ngOnInit(): void {
  }

}
