import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { apiUrl } from 'src/app/shared/data/token';
import { CheckCompanyRespone, CreateCompanyProfilePayload, CreateCompanyProfileResult } from 'src/app/shared/interfaces/advertiser-profile';
import { CompaniesService } from 'src/app/shared/services/api/companies.service';


@Component({
  selector: 'app-claim-company-profile',
  templateUrl: './claim-company-profile.component.html',
  styleUrls: ['./claim-company-profile.component.scss']
})
export class ClaimCompanyProfileComponent implements OnInit {

  isClaimForm = true;
  isLoading = false;
  isSuccess = false;
  apiRespone: CreateCompanyProfileResult;

  @Input() checkCompanyRespone: CheckCompanyRespone;
  @Output() closeModalTrigger: EventEmitter<boolean> = new EventEmitter()

  constructor(private companiesService: CompaniesService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeModalTrigger.emit();
  }

  onClaimCompany(e: FormGroup) {
    this.isLoading = true;

    const payload: CreateCompanyProfilePayload = {
      id: this.checkCompanyRespone.company.id,
      name: this.checkCompanyRespone.company.name,
      company_no: this.checkCompanyRespone.company.company_no,
      contact_email: e.value.companyEmail,
      contact_phone: e.value.companyContactNo,
      url: e.value.companyWebsite,
      address_line1: e.value.address.billingAddress1,
      address_line2: e.value.address.billingAddress2,
      city_id: e.value.address.city,
      state_id: e.value.address.country,
      postcode: e.value.address.postcode,
      country_id: e.value.address.state,
    }

    this.companiesService.updateCompany("",payload).subscribe(
      result => {
        this.isLoading = true;
        this.isClaimForm = false;
        this.isSuccess = true;
        this.apiRespone = result;
    },
    err => console.log(err),
    () => console.log('Done ..')

    );


  }

}
