import { Component, Input, OnInit } from '@angular/core';
import { Link } from 'src/app/shared/interfaces/link';

@Component({
  selector: 'app-side-nav-link-item',
  templateUrl: './side-nav-link-item.component.html',
  styleUrls: ['./side-nav-link-item.component.scss']
})
export class SideNavLinkItemComponent implements OnInit {

  @Input() link: Link;

  constructor() { }

  ngOnInit(): void {
    // console.log(this.link)
  }

}
