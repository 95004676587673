import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service';
import { StorageService } from 'src/app/auth/services/storage.service';

@Component({
  selector: 'app-header-user-dropdown',
  templateUrl: './header-user-dropdown.component.html',
  styleUrls: ['./header-user-dropdown.component.scss']
})
export class HeaderUserDropdownComponent implements OnInit {
  isAdmin: boolean;
  user: any;

  constructor(
    public auth: AuthService, 
    private router: Router, 
    private userUtilsService: UserUtilsService,
    private storage: StorageService
    ) { }

  ngOnInit(): void {
    this.user = this.storage.retrieve('user');
  }

  logout() {
    this.auth.logout().then(
      res => {
        this.router.navigate(['/']);
      }
    );

  }
  forgotPassword(user) {
    // this.isAdmin = this.userUtilsService.checkUserIsAdmin();
    // if (this.isAdmin) {
    //   this.router.navigate(['admin/change-password']);
    // } else {
    //   this.router.navigate(['advertiser/change-password']);
    // }
    this.auth.getUser().subscribe(res => {
      if (this.userUtilsService.verifyUserRole(res.roles, "admin")) {
        this.router.navigate(['admin/change-password']);
      } else {
        this.router.navigate(['advertiser/change-password']);
      }
    });
  }

}
