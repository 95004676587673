import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { env } from 'process';
import { endWith } from 'rxjs/operators';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  constructor() { }

  @Output() fileDroped = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragOver(evt){
    evt.preventDefault();
    evt.stopPropagation();

    console.log("drag over");
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt){
    evt.preventDefault();
    evt.stopPropagation();

    console.log("drag leave");
  }

  @HostListener('drop', ['$event']) onDrop(evt){
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;

    if(files){
      this.fileDroped.emit(files)
    }

    console.log("drop", files);
  }

}
