import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { apiUrl } from '../../data/token';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
// import { State } from '../../interfaces/state';
// import { any } from 'ng-uikit-pro-standard';
import { ApiTokenService } from 'src/app/auth/services/api-token.service';
import { Observable } from 'rxjs';
import { IProfile } from 'src/app/models/profile';
import { StorageService } from 'src/app/auth/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SearchProfileService {
  profileData: IProfile[];

  constructor(private http: HttpClient, private storage: StorageService, private apiHeader: ApiTokenService) { }
  fetchAllProfile(data: {}, path = '/advertiser/search/profile') {
    // return this.http.post(`${path}`, data, {headers: this.apiHeader.fetchToken()}).pipe(
    return this.http.post(`${environment.apiUrl}${path}`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  fetchAllUserProfile(data: {}, path = '/admin/search/user') {
    // return this.http.post(`${path}`, data, {headers: this.apiHeader.fetchToken()}).pipe(
    return this.http.post(`${environment.apiUrl}${path}`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  fetchCompanyDetails(pID) {
    return this.http.get(`${environment.apiUrl}/advertiser/profile/${pID}/details`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }

  fetchInquiryListById(path: string, data: {}) {
    return this.http.post(`${environment.apiUrl}${path}`, data).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  fetchInquiryDetailById(path) {
    console.log(`${environment.apiUrl}/${path}`);
    return this.http.get(`${environment.apiUrl}/${path}`).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  fetchUserListById(path: string, data: {}) {
    return this.http.post(`${environment.apiUrl}/${path}`, data).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
  fetchTokenUsageHistoryListById(path: string, data: {}) {
    return this.http.post(`${environment.apiUrl}/${path}`, data).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
  getRequiredProfileDetails() {
    const user = this.storage.retrieve('profile');
    return user;
  }
  getRequiredPostDetails() {
    const post = this.storage.retrieve('post');
    return post;
  }

  suspendProfile(id, data: {}) {
    return this.http.put(`${environment.apiUrl}/advertiser/profile/${id}/suspend`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  unSuspendProfile(id, data: {}) {
    return this.http.put(`${environment.apiUrl}/advertiser/profile/${id}/unsuspend`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('data') ? res['data'] : null)
    );
  }
  suspendAUserProfile(id, data: {}) {
    return this.http.put(`${environment.apiUrl}/admin/user/${id}/suspend`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('message') ? res['message'] : null)
    );
  }
  unSuspendAUserProfile(id, data: {}) {
    return this.http.put(`${environment.apiUrl}/admin/user/${id}/unsuspend`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('message') ? res['message'] : null)
    );
  }
  resetDeleteAUserProfile(id, data: {}) {
    return this.http.put(`${environment.apiUrl}/admin/user/${id}/cancel-pending-delete`, data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(res => res.hasOwnProperty('message') ? res['message'] : null)
    );
  }
  commonProxyPostCall(path: string, data?: {}) {
    return this.http.post(`${environment.apiUrl}/${path}`, data).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res;
          return obj;
        }
      })
    );
  }
  commonProxyGetCall(path: string, data?: {}) {
    return this.http.get(`${environment.apiUrl}/${path}`).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res;
          return obj;
        }
      })
    );
  }

  getData(path: string) {
    return this.http.get(`${environment.apiUrl}/${path}`).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
  postData(path: string, data?: {}) {
    return this.http.post(`${environment.apiUrl}/${path}`, data).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
  postDataFotUserOverView(path: string, data?: {}) {
    return this.http.post(`${environment.apiUrl}/${path}`, data).pipe(
      map(res => {
        if (res) {
          const obj = res;
          return obj;
        }
      })
    );
  }
  adminSearchProfile(payload) {
    return this.http.post(`${environment.apiUrl}/admin/search/profile`, payload).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
  followUpInquiry(path, payload) {
    return this.http.put(`${environment.apiUrl}/${path}`, payload).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }

  getJobApplicationCv(id: number) {
    return this.http.get(`${environment.apiUrl}/advertiser/job-application/${id}/cv`, {responseType: "blob"});
  }

  getHireMeDocument(id: number) {
    return this.http.get(`${environment.apiUrl}/advertiser/hire-me/${id}/doc`, {responseType: "blob"});
  }

  getProfileDocument(id: number) {
    return this.http.get(`${environment.apiUrl}/admin/profile/profile-document/${id}`, {responseType: "blob"});
  }

  getAdvertiserIndProfile(uid: number){
    return this.http.get(`${environment.apiUrl}/admin/profile/individual/${uid}`).pipe(
      map(res => {
        if (res.hasOwnProperty('data')) {
          const obj = res['data'];
          return obj;
        }
      })
    );
  }
}
