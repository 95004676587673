import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebsiteHomePageComponent } from './pages/website-home-page/website-home-page.component';
import { DashboardHomePageComponent } from './pages/dashboard-home-page/dashboard-home-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RootComponent } from './components/root/root.component';
import { MainNavBlockComponent } from './blocks/main-nav-block/main-nav-block.component';
import { LoginComponent } from './auth/components/login/login.component';
import { AccessDeniedComponent } from './auth/components/access-denied/access-denied.component';
import { AuthGuard } from './auth/services/auth.guard';
import { UnauthGuard } from './auth/services/unauth.guard';
import { UserRouteAccessService } from 'src/app/core/user-access-route-service';
import { CheckProfileCompleteService } from 'src/app/core/check-profile-complete.service';

const routes: Routes = [
    // START / ONLY_FOR_DEMO_YOU_CAN_DELETE_IT
    {
        path: '',
        pathMatch: 'full',
        component: LoginComponent,
        canActivate: [UnauthGuard]
    },
    // END / ONLY_FOR_DEMO_YOU_CAN_DELETE_IT
    {
        path: 'dashboard',
        component: RootComponent,
        canActivate: [UserRouteAccessService],
        data: {
            // Header layout. Choose one of ['site', 'dashboard'].
            headerLayout: 'site'
        },
    },
    // ========================================== ADVERTISER ==========================================
    {
        path: 'advertiser/dashboard',
        component: RootComponent,
        loadChildren: () => import('./advertiser/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-dashboard'
                }
            ],
            pageTitle: 'Dashboard'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'advertiser/change-password',
        component: RootComponent,
        loadChildren: () => import('./admin/change-password/change-password.module').then(m => m.ChangePasswordModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-manage-post'
                }
            ],
            pageTitle: 'Change Password'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'advertiser/analytics',
        component: RootComponent,
        loadChildren: () => import('./advertiser/analytics/analytics.module').then(m => m.AnalyticsModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-analytics'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    
    {
        path: 'advertiser/manage-post',
        component: RootComponent,
        loadChildren: () => import('./advertiser/posts/manage-post/manage-post.module').then(m => m.ManagePostModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-manage-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/create-post',
        component: RootComponent,
        loadChildren: () => import('./advertiser/posts/create-post/create-post.module').then(m => m.CreatePostModule),
        // loadChildren: () => import('./modules/posts/posts.module').then(m => m.PostsModule),

        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-create-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/create-profile',
        component: RootComponent,
        loadChildren: () => import('./advertiser/profile/create-profile/create-profile.module').then(m => m.CreateProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-create-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/post/boost/:id',
        component: RootComponent,
        loadChildren: () => import('./advertiser/posts/boost-plan/boost-plan.module').then(m => m.BoostPlanModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-boost-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/post/edit/:id',
        component: RootComponent,
        loadChildren: () => import('./advertiser/posts/create-post/create-post.module').then(m => m.CreatePostModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-edit-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/manage-profile',
        component: RootComponent,
        loadChildren: () => import('./advertiser/profile/manage-profile/manage-profile.module').then(m => m.ManageProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-manage-profile'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/profile/edit/:id',
        component: RootComponent,
        loadChildren: () => import('./advertiser/profile/update-profile/update-profile.module').then(m => m.UpdateProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-edit-profile'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/profile/topup/:id',
        component: RootComponent,
        loadChildren: () => import('./advertiser/profile/topup/topup.module').then(m => m.TopupModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-topup-token'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/profile/plan/:id',
        component: RootComponent,
        loadChildren: () => import('./advertiser/profile/plan-upgrade/plan-upgrade.module').then(m => m.PlanUpgradeModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-subscribe-plan'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/billing',
        component: RootComponent,
        loadChildren: () => import('./advertiser/billing/billing.module').then(m => m.BillingModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-billing'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'advertiser/yPoints',
        component: RootComponent,
        loadChildren: () => import('./advertiser/y-points/y-points.module').then(m => m.YPointsModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-y-point'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'payment/request',
        component: RootComponent,
        loadChildren: () => import('./shared/components/payment/payment.module').then(m => m.PaymentModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-y-point'
                }
            ],
            pageTitle: 'Payment'
        },
    },
    {
        path: 'payment/response',
        component: RootComponent,
        loadChildren: () => import('./shared/components/payment-response/payment.module').then(m => m.PaymentModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'advertiser-y-point'
                }
            ],
            pageTitle: 'Payment'
        },
    },
    // ========================================== ADMIN ==========================================
    {
        path: 'admin/dashboard',
        component: RootComponent,
        loadChildren: () => import('./advertiser/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-dashboard'
                }
            ],
            pageTitle: 'Dashboard'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/analytics',
        component: RootComponent,
        loadChildren: () => import('./admin/analytics/analytics.module').then(m => m.AnalyticsModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-analytics'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/change-password',
        component: RootComponent,
        loadChildren: () => import('./admin/change-password/change-password.module').then(m => m.ChangePasswordModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'change-password'
                }
            ],
            pageTitle: 'Change Password'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/create-post',
        component: RootComponent,
        loadChildren: () => import('./admin/posts/create-post/create-post.module').then(m => m.CreatePostModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/manage-post',
        component: RootComponent,
        loadChildren: () => import('./admin/posts/manage-post/manage-post.module').then(m => m.ManagePostModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/post/edit/:id',
        component: RootComponent,
        loadChildren: () => import('./admin/posts/create-post/create-post.module').then(m => m.CreatePostModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-post'
                }
            ],
            pageTitle: 'Post'
        },
        canActivate: [UserRouteAccessService, CheckProfileCompleteService]
    },
    {
        path: 'admin/create-ind-profile',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/create-individual-profile/create-ind-profile.module')
        .then(m => m.CreateIndividualProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-profile'
                }
            ],
            pageTitle: 'CreateProfile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/create-profile',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/create-profile/create-profile.module').then(m => m.CreateProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-profile'
                }
            ],
            pageTitle: 'CreateProfile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/manage-profile',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/manage-profile/manage-profile.module').then(m => m.ManageProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-profile'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/user-management',
        component: RootComponent,
        loadChildren: () => import('./admin/user-management/user-management.module').then(m => m.UserManagementModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-manage-user'
                }
            ],
            pageTitle: 'Member Management'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/ind-profile/edit/:id',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/update-individual-profile/update-ind-profile.module').
        then(m => m.UpdateIndividualProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-edit-profile'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/profile/edit/:id',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/update-profile/update-profile.module').then(m => m.UpdateProfileModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-edit-profile'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/profile/topup/:id',
        component: RootComponent,
        loadChildren: () => import('./admin/profile/topup/topup.module').then(m => m.TopupModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-topup-token'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/billing',
        component: RootComponent,
        loadChildren: () => import('./admin/billing/billing.module').then(m => m.BillingModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-billing'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/report',
        component: RootComponent,
        loadChildren: () => import('./admin/report/report.module').then(m => m.ReportModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-report'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'admin/yPoints',
        component: RootComponent,
        loadChildren: () => import('./admin/y-points/y-points.module').then(m => m.YPointsModule),
        data: {
            headerLayout: 'site',
            authorities: [
                {
                    permissionCodes: ['V'],
                    resourceClassName: 'admin-y-point'
                }
            ],
            pageTitle: 'Profile'
        },
        canActivate: [UserRouteAccessService]
    },
    // ========================================== COMMON ==========================================
    {
        path: 'accessdenied',
        pathMatch: 'full',
        component: AccessDeniedComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
