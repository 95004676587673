<app-loading-bar></app-loading-bar>

<!-- <app-quickview></app-quickview>

<a [routerLink]="[ '/path' ]">name</a> -->


<!-- mobilemenu -->
<!-- <app-mobile-menu></app-mobile-menu> -->
<!-- mobilemenu / end -->

<div class="site">
    <header *ngIf="showProxyHeader" style="height: auto !important;">
        <div style="background: #b4b3aa;">
                <div class="row">
                        <div style="width: 100%;margin: 5px 30px 10px 0px;text-align: center;">
                            <div style="float: right">
                               <span style="color: white;">Proxy as</span> <span style="font-weight: 500;color: white;">&nbsp;{{proxyTitle}}</span>
                                <button type="button" mdbBtn class="btn btn-primary" (click)="logOffProxy()" mdbWavesEffect>LOG OFF PROXY</button>
                            </div>
                        </div>
                </div>
        </div>
    </header>
    <!-- mobile site__header -->
    <header class="site__header d-md-none">
        <app-mobile-header></app-mobile-header>
    </header>
    <!-- End mobile site__header -->

     <!-- desktop site__header -->
    <header *ngIf="headerLayout" class="site__header d-none d-md-block">
        <app-header [layout]="headerLayout"></app-header>
    </header>
    <!-- End desktop site__header -->    

    <!-- site__body -->
    <div class="site__body">
        <!-- <router-outlet></router-outlet> -->
        <div class="home-layout">
            <div class="home-layout__row">
                <div class="home-layout__row--left d-md-block d-none">
                    <app-side-nav-block></app-side-nav-block>
                </div>
                <div class="home-layout__row--right">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <!-- End site__body -->

    <!-- Footer for both desktop and mobile -->
    <footer class="site__footer">
        <app-footer></app-footer>
    </footer>
    <!-- End Site Footer -->

</div>