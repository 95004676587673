import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service'

@Injectable({
  providedIn: 'root'
})
export class UnauthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private userUtilsService: UserUtilsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.user$.pipe(
        take(1),
        map(user => !(!!user)),
        tap(isLoggedin => {
          if(!isLoggedin) {
            if (this.userUtilsService.checkUserIsAdmin()) {
              this.router.navigate(['admin/dashboard']);
            } else {
              this.router.navigate(['advertiser/dashboard']);
            }

            console.log('You already Logged in....');
          } else {            
            this.authService.checkLogin();
          }
        })

      )
    
    // return true;
  }
  
}
