<div class="create-post" *ngIf="isFormVisible">
  <mdb-card class="create-post__card">
    <mdb-card-body class="create-post__card-body">
      <form [formGroup]="planForm">
        <mdb-stepper #profileStepper [vertical]="true" [linear]="true" [disableWaves]="true">
          <mdb-step name="Select a plan for your advertizer">
            <app-pricing [pricingPlans]="pricingPlans" [adsPlanForm]="adsPlanForm"></app-pricing>
            <div class="next-btn">
              <button type="button" class="yp-button blue sm font" color="dark"  size="sm"
                [routerLink]="['/advertiser/manage-profile']">Back</button>
              <button type="button" class="yp-button blue sm font" color="primary"  size="sm" [disabled]="!adsPlanForm.valid"
                (click)="calculatePlanPrice()">Next</button>
            </div>
          </mdb-step>
          <mdb-step name="CHECKOUT">
            <app-check-out [isCompany]="isCompany" [caliculatePrice]="caliculatePrice" [billingFormGroup]="billingFormGroup"
              [adsPlanForm]="adsPlanForm" [showSalesID]="true" (removePromoCodeEvent)="calculatePlanPrice()">
            </app-check-out>
            <div class="next-btn">
              <button type="button" class="yp-button blue sm font"   size="sm"
                (click)="profileStepper.previous()">Back</button>
              <button type="button" class="yp-button blue sm font"   size="sm" [disabled]="(!billingFormGroup.valid || submitButtonDisabled)"
                (click)="checkout()">CHECKOUT</button>
            </div>
          </mdb-step>
        </mdb-stepper>
      </form>

    </mdb-card-body>
  </mdb-card>
</div>

<div class="text-center" *ngIf="!isFormVisible">
  <div class="row">
    <div class="col-12">
      <img src="/assets/images/success.png" alt="" width="50px" height="50px" *ngIf="isPlanUpgraded">
      <img src="/assets/images/failure.png" alt="" width="50px" height="50px" *ngIf="!isPlanUpgraded">
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- <b *ngIf="isProfileCreated">Payment successful!</b>
      <b *ngIf="!isProfileCreated">Payment failed!</b> -->
      <b *ngIf="profileData.company_no">Company Name: {{profileData.name}}</b>
      <b *ngIf="!profileData.company_no">Advertiser Name: {{profileData.name}}</b>
    </div>
    <div class="col-12" *ngIf="profileData.company_no">
      <b>Company No.: {{profileData.company_no}}</b>
    </div>
  </div>
  <br>
  <ng-container *ngIf="isPlanUpgraded">
    <div class="row">
      <div class="col-12 text-center" *ngIf="profileData.company_no">
        The Company successfully subscribed to {{selectedPlan.type}} Plan
      </div>
      <div class="col-12 text-center" *ngIf="!profileData.company_no">
        The Advertiser successfully subscribed to {{selectedPlan.type}} Plan
      </div>
      <!-- </div>
    <div class="row"> -->
      <div class="col-12 text-center">
        and is being verified!
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="!isPlanUpgraded">
    <div class="col-12 text-center">
      <div class="col-12" *ngIf="profileData.company_no">
        <p>The Company failed to subscribed {{selectedPlan.type}} Plan</p>
      </div>
      <div class="col-12" *ngIf="!profileData.company_no">
        <p>The Advertiser failed to subscribed {{selectedPlan.type}} Plan</p>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-12">
      <button class="yp-button blue sm font" (click)="checkVerificationStatus()"> CHECK VERIFICATION STATUS</button>
      <!-- <button class="btn-primary" *ngIf="isProfileCreated" (click)="checkVerificationStatus()"> CHECK VERIFICATION STATUS</button> -->
      <!-- <button class="btn-primary" *ngIf="!isProfileCreated" (click)="makePaymentOnFailure()"> MAKE PAYMENT</button> -->
    </div>
  </div>
</div>


<!-- Money Paid By Unifi Payment Method with Only Plan -->
<div class="modal fade" mdbModal id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true" #unifiPaymentModel="mdbModal">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="previewModelUnifiPaymentClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center">
            <h4>This transaction has been sent to YP Admin for approval. Approval process will take ups to minimum 2
              workings days. Thank you</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <button class="yp-button blue sm font" [routerLink]="['/advertiser/manage-profile']">VIEW ALL PROFILES</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>