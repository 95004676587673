<div class="create-new-company">
    <div class="create-new-company__body" [ngClass]="{'create-new-company__body-loading': isLoading}">
        <ng-container *ngIf="(isForm | async) || isLoading; else result">
            <div class="create-new-company__body-preloader">
                <div class="spinner-container">
                    <mdb-spinner spinnerType="big" spinnerColor="blue"></mdb-spinner>
                </div>
            </div>
            <app-company-form 
            [newCompanyFormGroup]="newCompanyFormGroup" 
            (companyCheck)="compantCheckStatus(newCompanyFormGroup.value)"
            ></app-company-form>
        </ng-container>
        <ng-template #result>
           <app-company-result 
           [isNewCompanyForm]="isNewCompanyForm" 
           [canClaim]="canClaim" 
           [companyValues]="companyValues"
           [checkCompanyRespone]="checkCompanyRespone"
           [isClaimableMsg]="isClaimableMsg"
           (claimCompany)="cliamCompanyProfile()"
           (closeModalEvent)="onCloseModal()"
           ></app-company-result>
        </ng-template>
        
    </div>
</div>