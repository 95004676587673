import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {apiUrl} from '../../data/token';
import { environment } from 'src/environments/environment';
import {AdvertiserProfile, CreateCompanyProfilePayload, CreateCompanyProfileResult} from 'src/app/shared/interfaces/advertiser-profile';
import {map, switchMap} from 'rxjs/operators';
import {ProfilePlan} from 'src/app/shared/interfaces/plan';
import {ApiTokenService} from 'src/app/auth/services/api-token.service';


@Injectable({
  providedIn: 'root'
})
export class AdvertiserInfoService {

  advertiserCompany = {
    advertiser_type: "COMPANY"
  };

  advertiser = {
    advertiser_type: "INDIVIDUAL"
  };

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }

  fetchCompaniesProfile(route: string) {
    return this.http.post(`${environment.apiUrl}/${route}`, this.advertiserCompany, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          let obj = result['data'];
          return Object.keys(obj || {}).map(key => obj[key]) as CreateCompanyProfilePayload[];
        }
      })
    );
  }
  fetchHolidays() {
    return this.http.get(`${environment.apiUrl}/public-holiday`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          let obj = result['data'];
          return obj;
        }
      })
    );
  }

  fetchCompanyProfileById(route: string) {

    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          return result['data'] as CreateCompanyProfilePayload;
        }
      })
    );

  }

  fetchIndividualProfile(route: string) {
    return this.http.post(`${environment.apiUrl}/${route}`, this.advertiser, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          let obj = result['data'];
          return (Object.keys(obj || {}).map(key => obj[key]))[0] as CreateCompanyProfilePayload;
        }
      })
    );
  }

  fetchProfilePlan(id: number) {
    return this.fetchCompanyProfileById(`advertiser/profile/${id}`).pipe(
      switchMap(profile => {
        return this.http.get(`${environment.apiUrl}/advertiser/subscribe/plan-profile/${profile.id}`, {headers: this.apiHeader.fetchToken()})
          .pipe(
            map(result => {
              if (result.hasOwnProperty('data')) {
                const obj = result['data'];
                return obj;
                // return (Object.keys(obj || {}).map(key => obj[key]))[0] as ProfilePlan;
              }
            })
          );
      })
    );
  }

  advertiserDetails(route: string) {
    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          return result['data'];
        }
      })
    );

  }




}
