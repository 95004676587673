import { Component, Input, OnInit } from '@angular/core';
import { PostForDashboard } from 'src/app/shared/interfaces/post';

@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss']
})
export class ProfileWidgetComponent implements OnInit {

  @Input() posts: PostForDashboard[];

  constructor() { }

  ngOnInit(): void {
  }

}
