import {Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {CreateCompanyProfilePayload} from 'src/app/shared/interfaces/advertiser-profile';
import {PricingPostService} from 'src/app/shared/services/pricing-post.service';
import {CityService} from 'src/app/shared/services/api/city.service';
import {StateService} from 'src/app/shared/services/api/state.service';
import {IOption} from 'ng-uikit-pro-standard';
import {FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit, OnChanges {

  @Input() activeProfile: CreateCompanyProfilePayload;
  @Output() public removeAddressOnButtonClick = new EventEmitter<any>();
  @Output() public defaultAddressOnButtonClick = new EventEmitter<any>();
  @Output() public editAddressOnButtonClick = new EventEmitter<any>();

  @Input() newAddresses;
  @Input() adminProfileEdit: boolean;
  optionsState: IOption[];
  optionsCity: IOption[];

  addresses: any[];
  addressForm: FormGroup = new FormGroup({
    address_line1: new FormControl('', [Validators.required]),
    address_line2: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    country_id: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required]),
    company_phone_number: new FormControl('', [Validators.required]),
    phone_number: new FormControl('', [Validators.required]),
    fax_number: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    default: new FormControl('')
  });
  optionsCountry: IOption[] = [
    {value: '1', label: 'Malaysia', icon: ''}
  ];

  constructor(public pricingPostSer: PricingPostService, private stateSer: StateService, private citySer: CityService,) {}

  ngOnInit(): void {
    // this.stateSer.fetchAllState('states').subscribe(x => this.optionsState = x);
  }

  initForm() {

  }

  saveAddress() {
    // alert("aaaa")
  }

  removeAddress(index) {
    this.removeAddressOnButtonClick.emit(index);
  }

  editAddress(index) {
    this.editAddressOnButtonClick.emit(index);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ('activeProfile' in changes && this.activeProfile && this.activeProfile !== undefined) {
      this.addresses = this.activeProfile.locations;
    }
  }

  openAddAddresPopup() {
  }
  onCloseModal() {

  }

  defaultSelection(event, index) {
    if (this.newAddresses.default) {
      delete this.newAddresses.default;
    }
    this.newAddresses.forEach((addr, i) => {
      if (i == index) {
        this.newAddresses[i].default = 1;
      } else {
        this.newAddresses[i].default = 0;
      }
      return this.newAddresses;
    });
    this.defaultAddressOnButtonClick.emit(index);
  }

}
