import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { apiUrl } from '../../data/token';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Plan, PricingItem } from 'src/app/shared/interfaces/plan';
import { ApiTokenService } from 'src/app/auth/services/api-token.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) { }

  fetchAllPlans(route: string) {
    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()})
    .pipe(
      map(result => {

        if (result.hasOwnProperty('data')){
          const obj = result['data'];
          return Object.keys(obj).map(key => obj[key]) as Plan[];
        }

      }),
      map(plans => {
        return plans.map(plan => {

          const pricingItem: PricingItem = {
            id: plan.id,
            type: plan.name,
            price: plan.price,
            monthlyPrice: plan.monthly_price,
            tokens: plan.token_count,
            features: [
              {id: 1, feature: 'Tokens', amount: plan.token_count, isIncluded: true, 
              adsPost: plan.name == 'GOLD' ? plan.token_count + ' top ads post' : plan.token_count + ' ads post'},
              {id: 2, feature: '', isIncluded: true, 
              position: plan.name == 'GOLD' ? '1st' : (plan.name == 'SILVER' ? '2nd' : '3rd'), 
              place: plan.name == 'GOLD' ? 'Top position with most prominent display' : (plan.name == 'SILVER' ? 'Secondary position after Gold' : 'Third liner position after Gold & Silver')},
              {id: 3, feature: 'business categories listing per ad', amount: plan.category_limit , isIncluded: true},
              {id: 4, feature: 'search keywords per post', amount: plan.keyword_limit , isIncluded: true},
              {id: 5, feature: 'Ads valid until subscription expiry', amount: plan.post_validity , isIncluded: true},
              {id: 6, feature: 'Business analytics report', isIncluded: plan.analytic_report == 1 ? true : false},
            ],
          };

          return pricingItem;

        });
      })
    );
  }

  fetchPlanById(id: number) {
    return this.http.get(`${environment.apiUrl}/plan/${id}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          // let obj = result['data'];
          return result['data'];
        }
      })
    );
  }

}
