<div class="profile-widget">
    <mdb-card class="profile-widget__card">
        <mdb-card-header class="profile-widget__card-header" >
            <span>Advertiser Profile <span *ngIf="posts && posts.length > 0"> ({{posts.length}})</span></span>
            <a routerLink="/post" >View all</a>
        </mdb-card-header>
        <ul class="list-group list-group-flush profile-widget__card-ul">
            <li class="list-group-item profile-widget__card-ul--li" *ngFor="let post of posts">
                <div class="profile-widget__card-ul--li-type-{{post.type}}"></div>
                <div class="profile-widget__card-ul--li-title">
                    {{ post.title}} <span *ngIf="post.type === 'individual'">(Individual profile)</span>
                </div>
                <div class="profile-widget__card-li--class" [ngClass]="{'gold': post.class === 'gold', 'silver': post.class === 'silver'}">
                    {{ post.class | uppercase }}
                </div>
            </li>
        </ul>
    </mdb-card>
</div>