import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'ng-uikit-pro-standard';
import { CheckCompanyRespone } from 'src/app/shared/interfaces/advertiser-profile';
import { CityService } from 'src/app/shared/services/api/city.service';
import { StateService } from 'src/app/shared/services/api/state.service';

@Component({
  selector: 'app-claim-company-form',
  templateUrl: './claim-company-form.component.html',
  styleUrls: ['./claim-company-form.component.scss']
})
export class ClaimCompanyFormComponent implements OnInit, OnChanges {

  optionsCountry: IOption[] = [
    {value: '1', label: 'Malaysia', icon: ''}
  ];
  optionsCity: IOption[];
  optionsState: IOption[];

  @Input() checkCompanyRespone: CheckCompanyRespone;
  @Output() claimCompanyTrigger: EventEmitter<FormGroup> = new EventEmitter();

  claimCompanyForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    companyRN: new FormControl(''),
    companyContactNo: new FormControl('', [Validators.required]),
    companyEmail: new FormControl(''),
    companyWebsite: new FormControl(''),
    address: new FormGroup({
      billingAddress1: new FormControl('', [Validators.required]),
      billingAddress2: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    })
    
  });


  get name() { return this.claimCompanyForm.get('name'); }
  get companyRN() { return this.claimCompanyForm.get('companyRN'); }
  get companyContactNo() { return this.claimCompanyForm.get('companyContactNo'); }
  get companyEmail() { return this.claimCompanyForm.get('companyEmail'); }
  get companyWebsite() { return this.claimCompanyForm.get('companyWebsite'); }
  get address() { return this.claimCompanyForm.get('address'); }
  get billingAddress1() { return this.address.get('billingAddress1'); }
  get billingAddress2() { return this.address.get('billingAddress2'); }
  get country() { return this.address.get('country'); }
  get state() { return this.address.get('state'); }
  get city() { return this.address.get('city'); }
  get postcode() { return this.address.get('postcode'); }

  constructor(   private stateSer: StateService, 
    private citySer: CityService,) { }

  ngOnInit(): void {
    this.stateSer.fetchAllState('states').subscribe(x => this.optionsState = x);

  }

  ngOnChanges(changes: SimpleChanges): void {

    if( 'checkCompanyRespone' in changes && this.checkCompanyRespone ) {

      this.name.setValue(this.checkCompanyRespone.company.name);
      this.companyRN.setValue(this.checkCompanyRespone.company.company_no);


      this.name.disable();
      this.companyRN.disable();

    }
    
  }

  onSubmitClaim(e) {

    console.log(e);
    this.claimCompanyTrigger.emit(this.claimCompanyForm)
  }

}
