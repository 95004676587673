import { CompareService } from './shared/services/compare.service';
import { WishlistService } from './shared/services/wishlist.service';
import { Product } from './shared/interfaces/product';
import { Component, Inject, NgZone, PLATFORM_ID, OnDestroy, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { CurrencyService } from './shared/services/currency.service';
import { CartService } from './shared/services/cart.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './auth/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject();

    constructor(@Inject(PLATFORM_ID) platformId: any,
        private router: Router,
        private ngZone: NgZone,
        private currency: CurrencyService,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private toastr: ToastService,
        private auth: AuthService
    ) {
        // this.auth.cookieInit().subscribe();
        // this.auth.checkUser().subscribe();
        if (isPlatformBrowser(platformId)) {

            this.ngZone.runOutsideAngular(() => {
                this.router.events.pipe(takeUntil(this.destroy$), filter(event => event instanceof NavigationEnd), first()).subscribe(() => {

                    const preloader = document.querySelector('.site-preloader');

                    preloader.addEventListener('transitionend', (event: TransitionEvent) => {
                        if (event.propertyName === 'opacity') {
                            preloader.remove();
                        }
                    });
                    preloader.classList.add('site-preloader__fade');
                });
            });
        }

    }

    ngOnInit(): void {
        // properties of the CurrencyFormatOptions interface fully complies
        // with the arguments of the built-in pipe "currency"
        // https://angular.io/api/common/CurrencyPipe
        this.currency.options = {
            code: 'USD',
            // display: 'symbol',
            // digitsInfo: '1.2-2',
            // locale: 'en-US'
        };

        // this.cart.onAdding$.pipe(takeUntil(this.destroy$)).subscribe((product: Product) => {
        //   this.toastr.success(`Product "${product.name}" added to cart!`);
        // });

        // this.wishlist.onAdding$.pipe(takeUntil(this.destroy$)).subscribe((product: Product) => {
        //   this.toastr.success(`Product "${product.name}" added to wish list!`);
        // });

        // this.compare.onAdding$.pipe(takeUntil(this.destroy$)).subscribe((product: Product) => {
        //   this.toastr.success(`Product "${product.name}" added to compare!`)
        // });




    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
