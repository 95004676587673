<div class="traffic-widget">
    <mdb-card class="traffic-widget__card">
        <mdb-card-header class="traffic-widget__card-header">

            <div class="traffic-widget__card-header--dropdown">
                <div class="dropdown" mdbDropdown>
                
                  <button mdbDropdownToggle mdbBtn flat="true" class="dropdown-toggle waves-light" type="button"
                    mdbWavesEffect>
                    Last 7 Days
                  </button>
                
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Total</a>
                    <a class="dropdown-item" href="#">Last 1 year</a>
                    <a class="dropdown-item" href="#">Last 1 month</a>
                  </div>
                </div>
            </div>
            <div class="traffic-widget__card-header--heads">
                <div class="traffic-widget__card-header--head-impressions">Impressions</div>
                <div class="traffic-widget__card-header--head-view">view</div>
                <div class="traffic-widget__card-header--head-leads">leads</div>
            </div>
            
        </mdb-card-header>
        <ul class="list-group list-group-flush traffic-widget__card-ul">
            <li class="list-group-item traffic-widget__card-ul--li" *ngFor="let traffic of traffics">
                <div class="traffic-widget__card-ul--li-title">{{ traffic.postTitle }}</div>
                <div class="traffic-widget__card-ul--li-data">
                    <div class="traffic-widget__card-ul--li-data--impressions">{{ traffic.impressions }}</div>
                    <div class="traffic-widget__card-ul--li-data--view">{{ traffic.view }}</div>
                    <div class="traffic-widget__card-ul--li-data--leads">{{ traffic.leads }}</div>
                </div>
            </li>
        </ul>
    </mdb-card>
</div>