import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IOption, ToastService } from 'ng-uikit-pro-standard';
import { pairwise, startWith } from 'rxjs/operators';
import { AddOnsService } from 'src/app/shared/services/api/add-ons.service';
import { AdvertiserInfoService } from 'src/app/shared/services/api/advertiser-info.service';
import { CompaniesService } from 'src/app/shared/services/api/companies.service';
import { PaymentUtilsService } from 'src/app/shared/utils/payment-utils.service';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss'],
})
export class TopupComponent implements OnInit {
  profileData: any;
  caliculatePrice: any;
  profile_id: string;
  tokensTopups: any;
  selectedTopUp: any;

  isFormVisible = true;
  isTopUpSuccess = false;

  submitButtonDisabled = false;

  constructor(
    private addOnsSer: AddOnsService,
    private route: ActivatedRoute,
    private router: Router,
    private compService: CompaniesService,
    private adsInfo: AdvertiserInfoService,
    private message: ToastService,
    private paymentUtilsService: PaymentUtilsService,
  ) {}
  optionsTopup: IOption[] = [];

  topupForm: FormGroup = new FormGroup({
    token_id: new FormControl('', []),
  });

  billingFormGroup: FormGroup = new FormGroup({
    billing_name: new FormControl('', [Validators.required]),
    billing_address_line1: new FormControl('', [Validators.required]),
    billing_address_line2: new FormControl(''),
    billing_payment_method: new FormControl('', [Validators.required]),
    update_billing_info: new FormControl(''),
    // billing_city_id: new FormControl('', [Validators.required]),
    billing_city: new FormControl('', [Validators.required]),
    billing_state_id: new FormControl('', [Validators.required]),
    billing_country_id: new FormControl('1', [Validators.required]),
    billing_postcode: new FormControl('', [Validators.required]),
    unifi_id: new FormControl('NA', [Validators.required]),
    unifi_name: new FormControl('NA', [Validators.required]),
    unifi_phone_number: new FormControl('NA', [Validators.required]),
    unifi_authorized: new FormControl(true, [Validators.requiredTrue]),
    promo_code: new FormControl('', []),
    referral_code: new FormControl(),
    sales_id: new FormControl(),
    referral: new FormControl(),
    t_c: new FormControl('', Validators.requiredTrue),
    faq: new FormControl('', Validators.requiredTrue),
  });

  ngOnInit(): void {
    this.getTopup();
    this.listenForFormChanges();
    this.profile_id = this.route.snapshot.paramMap.get('id');
    this.getProfileDetails(this.profile_id);
  }

  getProfileDetails(profileId) {
    this.compService.getProfileDetails(profileId).subscribe(
      (res) => {
        this.profileData = res;
        if (this.profileData?.advertiser_type == 'COMPANY') {
          this.fetchCompanyProfile(this.profileData?.id);
        }
        if (this.profileData?.advertiser_type == 'INDIVIDUAL') {
          this.fetchIndividualProfile();
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getTopup() {
    this.addOnsSer.fetchAllTopup(`advertiser/token/price`).subscribe((topup) => {
      this.tokensTopups = topup;
      this.optionsTopup = topup.map((d: any) => ({
        ...d,
        value: d.id,
        label: `Token ${d.token_count} - RM ${d.price}`,
      }));
    });
  }

  listenForFormChanges() {
    this.topupForm
      .get('token_id')
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([old_token_id, token_id]) => {
        this.calculateTopupPrice(token_id);
      });
  }

  calculateTopupPrice(token_id) {
    // this.caliculatePrice = null;
    this.selectedTopUp = this.tokensTopups.find((topup) => topup.id === token_id);
    this.addOnsSer.calculateTopupPrice({ token_id }).subscribe((summary) => {
      this.caliculatePrice = summary;
    });
  }

  tokenAmountDeselected(event) {
    this.caliculatePrice = null;
  }

  checkout() {
    this.submitButtonDisabled = true;

    const payload = {
      profile_id: this.profile_id,
      ...this.topupForm.value,
      ...this.billingFormGroup.value,
    };
    if (payload.update_billing_info) {
      payload.update_billing_info = '1';
    }

    this.addOnsSer.postToken(payload).subscribe(
      (summary) => {
        this.isTopUpSuccess = true;
        // this.isFormVisible = false;

        this.message.success('Topup token submitted successfully', null, {positionClass: 'md-toast-bottom-right'});

        if (summary.paymentCode) {
          this.paymentUtilsService.doPaymentRequest(summary.paymentCode, false); // Send to payment gateway
        } else {
          this.isFormVisible = false;
        }
      },
      (err) => {
        this.submitButtonDisabled = false;
        
        if (err.error?.errors) {
          err.error.errors.forEach(element => {
            this.message.error(element, null, {positionClass: 'md-toast-bottom-right'});
          });
        } else {
          this.message.error(err.error.message, null, {positionClass: 'md-toast-bottom-right'});
        }
      },
    );
  }

  gotoManageAdvertiserProfile() {
    this.router.navigate(['/advertiser/manage-profile']);
  }

  fetchIndividualProfile() {
    this.adsInfo.fetchIndividualProfile(`advertiser/profiles`).subscribe((data: any) => {
      this.setBillingInfo(data);
    });
  }

  fetchCompanyProfile(selectedCompany) {
    this.adsInfo.fetchCompanyProfileById(`advertiser/profile/${selectedCompany}`).subscribe((data: any) => {
      console.log(data);
      // this.stepper.next();
      this.setBillingInfo(data);
    });
  }

  setBillingInfo(profileInfo) {
    if (profileInfo && profileInfo.profile_billing_info) {
      this.billingFormGroup.patchValue({
        billing_name: profileInfo?.profile_billing_info?.name,
        billing_address_line1: profileInfo?.profile_billing_info?.address_line1,
        billing_address_line2: profileInfo?.profile_billing_info?.address_line2,
        billing_city: profileInfo?.profile_billing_info?.city?.name,
        billing_state_id: '' + profileInfo?.profile_billing_info?.state_id,
        billing_country_id: profileInfo?.profile_billing_info?.country?.id,
        billing_postcode: profileInfo?.profile_billing_info?.postcode,
      });
    }
  }
}
