import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchProfileService } from 'src/app/shared/services/api/search-profile.service';
import { StorageService } from 'src/app/auth/services/storage.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, OnDestroy {

  destroy$: Subject<void> = new Subject();
  headerLayout = 'site | dashboard';
  showProxyHeader: boolean;
  proxyTitle: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private storage: StorageService,
    public userProfileService: SearchProfileService,
    private auth: AuthService

  ) {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe(data => this.headerLayout = data.headerLayout);
  }

  ngOnInit() {
    // this.router.events.subscribe(s => console.log(s));
    // this.proxyCheckHelper();
    this.checkProxyHeader();
    // this.auth.initializeUserStorage();
  }

  // private proxyCheckHelper() {
  //   if (localStorage.getItem('authData') === 'true') {
  //     this.showProxyHeader = true;
  //   } else if (localStorage.getItem('authData') === 'false') {
  //     this.checkProxyHeader();
  //   } else {
  //     this.showProxyHeader = false;
  //   }
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  checkProxyHeader() {
    this.userProfileService
      .getData(`auth/check-proxy`)
      .pipe(
        map((data) => {
          const res = data;
          if (res && res.proxy_from && res.proxy_name && res.proxy_type && res.proxy_user_id) {
            this.showProxyHeader = true;
            this.proxyTitle = res.proxy_name;
          } else {
            this.showProxyHeader = false;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((options) => {
      });
  }
  logOffProxy() {
    this.userProfileService
      .commonProxyGetCall(`auth/exit-proxy`)
      .pipe(
        map((data) => {
          const res = data;
          if (res && res['message'] === 'Success') {
            this.showProxyHeader = false;
            const user = res['data'];
            this.storage.store('user', user);
            localStorage.setItem('enableProxy', 'false');
            this.router.navigate(['']);
          } else {
            this.showProxyHeader = true;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((options) => {
      });
  }

}
