import { Component, Input, OnInit } from '@angular/core';
import { PayByPost } from 'src/app/shared/interfaces/plan';

@Component({
  selector: 'app-pay-by-post-plan-summary',
  templateUrl: './pay-by-post-plan-summary.component.html',
  styleUrls: ['./pay-by-post-plan-summary.component.scss']
})
export class PayByPostPlanSummaryComponent implements OnInit {

  @Input() payByPostPlan: PayByPost;

  constructor() { }

  ngOnInit(): void {
  }

}
