import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PricingItem} from 'src/app/shared/interfaces/plan';
import {AddOnsService} from 'src/app/shared/services/api/add-ons.service';
import {AdvertiserInfoService} from 'src/app/shared/services/api/advertiser-info.service';
import {CompaniesService} from 'src/app/shared/services/api/companies.service';
import {PlanService} from 'src/app/shared/services/api/plan.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { PaymentUtilsService } from 'src/app/shared/utils/payment-utils.service';
import { StateService } from 'src/app/shared/services/api/state.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-plan-upgrade',
  templateUrl: './plan-upgrade.component.html',
  styleUrls: ['./plan-upgrade.component.scss']
})
export class PlanUpgradeComponent implements OnInit {

  constructor(
    private pricingService: PlanService,
    private compService: CompaniesService,
    private addOnsSer: AddOnsService,
    private adsInfo: AdvertiserInfoService,
    private route: ActivatedRoute,
    private router: Router,
    private message: ToastService,
    private paymentUtilsService: PaymentUtilsService,
  ) { }

  @ViewChild('profileStepper') profileStepper: any;
  @ViewChild('unifiPaymentModel') unifiPaymentModel: any;

  pricingPlans: PricingItem[];
  caliculatePrice: any;
  profile_id: string;
  isFormVisible = true;
  isPlanUpgraded = false;
  profileData: any;
  selectedPlan: any;
  isCompany: boolean = false;
  submitButtonDisabled = false;

  planForm: FormGroup = new FormGroup({
    plan_id: new FormControl('', []),
  });
  adsPlanForm: FormGroup = new FormGroup({
    package: new FormControl(null, Validators.required),
    payment_term: new FormControl('', []),
  });

  billingFormGroup: FormGroup = new FormGroup({
    billing_name: new FormControl('', [Validators.required]),
    billing_address_line1: new FormControl('', [Validators.required]),
    billing_address_line2: new FormControl(''),
    billing_payment_method: new FormControl('', [Validators.required]),
    update_billing_info: new FormControl(''),
    // billing_city_id: new FormControl('', [Validators.required]),
    billing_city: new FormControl('', [Validators.required]),
    billing_state_id: new FormControl('', [Validators.required]),
    billing_country_id: new FormControl('1', [Validators.required]),
    billing_postcode: new FormControl('', [Validators.required]),
    unifi_id: new FormControl('NA', [Validators.required]),
    unifi_name: new FormControl('NA', [Validators.required]),
    unifi_phone_number: new FormControl('NA', [Validators.required]),
    unifi_authorized: new FormControl(true, [Validators.requiredTrue]),
    promo_code: new FormControl(''),
    referral_code: new FormControl(),
    sales_id: new FormControl(),
    referral: new FormControl(),
    t_c: new FormControl('', Validators.requiredTrue),
    faq: new FormControl('', Validators.requiredTrue),
  });
  ngOnInit(): void {
    this.profile_id = this.route.snapshot.paramMap.get('id');
    this.getProfileDetails(this.profile_id);
  }

  getProfileDetails(profileId) {
    this.compService.getProfileDetails(profileId).subscribe(res => {
      this.profileData = res;
      this.fetchAllPlans();
      if (this.profileData?.advertiser_type == 'COMPANY') {
        this.fetchCompanyProfile(this.profileData?.id);
        this.isCompany = true;
      }
      if (this.profileData?.advertiser_type == 'INDIVIDUAL') {
        this.fetchIndividualProfile();
        this.isCompany = false;
      }
    }, err => {
      console.log(err);
    });
  }

  calculatePlanPrice() {
    // this.caliculatePrice = null;
    console.log(this.adsPlanForm.get('payment_term').value);
    const payload = {
                      plan_id: this.adsPlanForm.get('package').value, 
                      payment_term: this.adsPlanForm.get('payment_term').value ? 'yr' : 'mo' // PAYMENT_TERM
                      // payment_term: "yr"
                    };

    this.compService.caliculatePlanPrice(payload)
      .subscribe((summary) => {
        this.caliculatePrice = summary;
        this.profileStepper.next();
      });
  }

  fetchAllPlans() {
    this.pricingService.fetchAllPlans('plan').subscribe(x => {
      this.pricingPlans = x;
      const planId = this.profileData.active_plan?.plan_id || 0;
      this.pricingPlans.forEach(plan => {
        if (plan.id >= planId && planId != 0) {
          plan.isDisable = true;
        } else {
          plan.isDisable = false;
        }
      });
    });
  }

  checkout() {
    this.submitButtonDisabled = true;

    this.selectedPlan = this.pricingPlans.find(plan => plan.id === this.adsPlanForm.value.package);
    const payload = {
      profile_id: this.profile_id,
      plan_id: this.adsPlanForm.value.package,
      payment_term: this.adsPlanForm.value.payment_term ? 'yr' : 'mo', // PAYMENT_TERM
      promo_code: this.billingFormGroup.get('promo_code').value,
      // payment_term: "yr",
      ...this.billingFormGroup.value
    };
    if (payload.update_billing_info) {
      payload.update_billing_info = '1';
    }
    this.compService.subscribePlan(payload).subscribe((summary) => {
      // if (this.adsPlanForm.value.package) {
      //   this.unifiPaymentModel.show();
      //   return;
      // }
      // this.isFormVisible = false;
      this.isPlanUpgraded = true;

      this.message.success('Subscribe plan submitted successfully', null, {positionClass: 'md-toast-bottom-right'});

      if (summary.paymentCode) {
        this.paymentUtilsService.doPaymentRequest(summary.paymentCode, false); // Send to payment gateway
      } else {
        this.isFormVisible = false;
      }
    }, (err) => {
      this.submitButtonDisabled = false;

      if (err.error?.errors) {
        err.error.errors.forEach(element => {
          this.message.error(element, null, {positionClass: 'md-toast-bottom-right'});
        });
      } else {
        this.message.error(err.error.message, null, {positionClass: 'md-toast-bottom-right'});
      }
    });
  }

  checkVerificationStatus() {
    this.router.navigate(['/advertiser/manage-profile']);
  }

  previewModelUnifiPaymentClose() {
    this.router.navigate(['/advertiser/manage-profile']);
  }

    fetchIndividualProfile() {
    this.adsInfo.fetchIndividualProfile(`advertiser/profiles`).subscribe((data: any) => {
      this.setBillingInfo(data);
    });
  }

  fetchCompanyProfile(selectedCompany) {
    this.adsInfo.fetchCompanyProfileById(`advertiser/profile/${selectedCompany}`).subscribe((data: any) => {
      console.log(data);
      // this.stepper.next();
      this.setBillingInfo(data);
    });
  }

  setBillingInfo(profileInfo) {
    if (profileInfo && profileInfo.profile_billing_info) {
      this.billingFormGroup.patchValue({
        billing_name: profileInfo?.profile_billing_info?.name,
        billing_address_line1: profileInfo?.profile_billing_info?.address_line1,
        billing_address_line2: profileInfo?.profile_billing_info?.address_line2,
        billing_city: profileInfo?.profile_billing_info?.city?.name,
        billing_state_id: '' + profileInfo?.profile_billing_info?.state_id,
        billing_country_id: profileInfo?.profile_billing_info?.country?.id,
        billing_postcode: profileInfo?.profile_billing_info?.postcode,
      });
    }
  }

}
