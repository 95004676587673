// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: 'S-1.0.87',
  production: false,
  apiUrl: '//api.yp.appasia.net.my',
  cdnUrl: 'https://cdn.yp.appasia.net.my/yp-dev/',
  advertiserURL: 'https://advertiser.yp.appasia.net.my/',
  homePage: 'https://yp.appasia.net.my/',
  ipay88RequestUrl: 'https://payment.ipay88.com.my/ePayment/entry.asp',
  ipay88RecurringRequestUrl: 'https://payment.ipay88.com/recurringpayment2.0/subscription.asp',
  ipay88MerchantCode: 'M27521',
  ipay88ResponseURL: 'https://api.yp.appasia.net.my/payment/response',
  ipay88BackendURL: 'https://api.yp.appasia.net.my/payment/response/backend',
  appAsiaUrl: 'https://appasia.com',
  faqPageUrl: 'https://assets.yellowpages.my/guides/Frequently_Asked_Questions_Yellow_Pages_2021_V6_29July.pdf',
  tutorialPageUrl: 'https://assets.yellowpages.my/guides/How_to_register_as_Advertiser_(Tutotrial_1).pdf',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
