import { Component, Input, OnInit } from '@angular/core';
import { YPoints } from 'src/app/shared/interfaces/y-points';

@Component({
  selector: 'app-y-points-widget',
  templateUrl: './y-points-widget.component.html',
  styleUrls: ['./y-points-widget.component.scss']
})
export class YPointsWidgetComponent implements OnInit {

  @Input() yPoints: YPoints[];

  constructor() { }

  ngOnInit(): void {
  }

}
