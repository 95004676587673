import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { error } from 'protractor';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service';

@Injectable({ providedIn: 'root' })

export class PrincipalService {

    constructor(
        private auth: AuthService,
        private userUtilsService: UserUtilsService
    ) {}

    hasAnyAuthority(authorities: any[]): Promise<boolean> {
        // return this.auth
        // .checkPermission(authorities)
        // .toPromise()
        // .then((res: any) => {
        //     console.log('checkPermissions res = ', res);
        //     if (res.status) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });
        return new Promise((resolve, reject) => {
            this.auth.checkPermission(authorities).subscribe(
                (res: any) => {
                    if (res.status) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error => {
                    resolve(false);
                }
            );
        });
    }

    hasCompleteProfile(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.auth.getUser().subscribe(
                (res: any) => {
                    if (this.userUtilsService.verifyUserRole(res.roles, "admin")) {
                        resolve(true);
                    }

                    if (!this.userUtilsService.isProfileComplete(res)) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                },
                error => {
                    resolve(false);
                }
            );
        });
    }
}