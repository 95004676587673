import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
  @ViewChild('confirmationModal') confirmationModal: any;
  host = environment.homePage;
 
  constructor(public auth: AuthService, private router: Router) { }

  ngOnInit(): void {}

  showConfirmationModal() {
    this.confirmationModal.show();
  }
  hideConfirmationModal() {
    this.confirmationModal.hide();
  }
  redirectToMainPage() {
    this.hideConfirmationModal();
    window.open(this.host);
  }
  logout() {
    
  }

}
