import { filter, first, last } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { SearchInvoiceService } from 'src/app/shared/services/api/search-invoice.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-export-pagination',
  templateUrl: './export-pagination.component.html',
  styleUrls: ['./export-pagination.component.scss']
})
export class ExportPaginationComponent implements OnInit, OnDestroy {
  @Input() pageInfo: any;
  @Input() payLoad: any;
  @Input() pathForExportData: any;
  @Input() fileNameAfterExport: any;
  @Output() public updatePerPage = new EventEmitter<any>();
  @Output() public fetchDataTableInfo = new EventEmitter<any>();


  exportFormat = 'excel';
  selectedProfile;
  perPageCountArr = [5, 10, 15, 20, 25];
  perPage: any;
  defaultPerPage = 5;
  activePage;
  constructor(
    public searchInvoiceService: SearchInvoiceService,
  ) { }

  ngOnInit(): void {
    this.perPage = Number(sessionStorage.getItem('perPage')) || this.defaultPerPage;
    if (this.mobileScreen()) {
      this.setPaginationForSmallScreen();
    }
    // console.log(this.pageInfo)
  }
  mobileScreen() {
   return window.innerWidth < 1100;
  }
  setPaginationForSmallScreen() {
    const links = [];
    links.push(this.pageInfo.meta.links[0]);
    links.push(this.pageInfo.meta.links[this.pageInfo.meta.links.length - 1 ]);
    this.pageInfo.meta.links = links;
    console.log('>>>>>>>', links);
  }
  ngOnDestroy() {
    // sessionStorage.setItem('perPage', this.defaultPerPage.toString());
  }
  exportAllPostData(format?: string) {
    const payLoad = this.payLoad;
    if (this.exportFormat === 'excel') {
      payLoad['excel'] = '1';
    }
    if (this.exportFormat === 'csv') {
      payLoad['csv'] = '1';
    }

    this.searchInvoiceService.exportData(payLoad, this.pathForExportData).subscribe((response: any) => {
      if (this.exportFormat == 'excel') {
        return this.saveToFileSystem(response, this.fileNameAfterExport);
      }
      if (this.exportFormat == 'csv') {
        return this.saveToFileSystem(response, this.fileNameAfterExport);
      }
    });
  }
  private saveToFileSystem(response, fileName) {
    const blob = new Blob([response], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }
  getPageLabel(page) {
    let label;
    label =
      page.label === '&laquo; Previous' ? '«' : page.label === 'Next &raquo;' ? '»' : page.label;
    return label;
  }
  onPageCountChange(e) {
    this.perPage = +e;
    this.updatePerPageCount(e);
    this.updateDataTable();
    sessionStorage.setItem('perPage', this.perPage.toString());
  }
  updateDataTable(apiPath?: any) {
    if (apiPath) {
      // this.pathForExportData = apiPath ? apiPath : this.pathForExportData;
      this.pathForExportData = apiPath ;
      this.fetchDataTableInfo.emit(apiPath);
    }
  }
  updatePerPageCount(c) {
    this.updatePerPage.emit(c);
  }
  selectPage(page){
  this.activePage = page;
}
}
