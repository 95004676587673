import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { IOption, ToastService } from 'ng-uikit-pro-standard';
import { map } from 'rxjs/operators';
import { AddOnsGroup } from '../../interfaces/addOns';
import { PostService } from '../../services/api/post.service';
import { StateService } from '../../services/api/state.service';
import { PricingPostService } from '../../services/pricing-post.service';
import * as moment from 'moment';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss'],
})
export class CheckOutComponent implements OnInit, OnChanges {
  @Input() isCompany: boolean;
  @Input() adsPlanForm;
  @Input() addOnsPackages: AddOnsGroup[];
  @Input() optionsSelectState: IOption[];
  @Input() billingFormGroup;
  @Input() caliculatePrice;
  @Input() showSalesID;
  @Input() activeProfileHasPlan;
  @Input() latestDiscountAmount;

  @Output() removePromoCodeEvent = new EventEmitter<boolean>();
  @Output() discountedAmountEvent = new EventEmitter<number>();

  optionsSelectCountry: IOption[] = [{ value: '1', label: 'Malaysia', icon: '' }];
  optionsSelectCity: IOption[];
  optionsCountry: IOption[] = [{ value: '1', label: 'Malaysia', icon: '' }];
  discountedAmount;
  isPromoCodeApplied: boolean;
  selectedDropdownType
  isPayPerPost;
  // TODO: add logic to BE to indicates auto apply code or not
  autoApplyPromoCode: string = 'YPRAYA';
  currentDate: string = moment(new Date()).format("DD/MM/YYYY");
  autoApplyStartDate = '01/04/2022';
  autoApplyEndDate = '31/05/2022';

  constructor(
    public pricingService: PricingPostService,
    private stateService: StateService,
    private postService: PostService,
    private toastService: ToastService,
  ) {
    this.stateService.currentRouteType
    .subscribe(value => {
      this.selectedDropdownType = value.routeType
    });

    stateService.getSelectedPricingPlan().subscribe(data => {
      if(data){
        this.billingFormGroup.get('billing_payment_method').reset();
        this.removePromoCode(true);
        this.isPayPerPost = false;
      }
    })

    stateService.getPayPerPostPlan().subscribe(item => {
      if(item.data){
        this.billingFormGroup.get('billing_payment_method').reset();
        this.removePromoCode(true);
        this.isPayPerPost = true;
      }
    })

    stateService.getPaymentTerm().subscribe(item => {
      if(item.data === 'mo'){
        this.billingFormGroup.get('billing_payment_method').reset();
        this.removePromoCode(true);
        this.isPayPerPost = false;
      }
    })

    stateService.getPromoCode().subscribe(item => {
      this.isPromoCodeApplied = item.isPromoCodeApplied;
    })
  }

  ngOnInit() {
    this.stateService.currentDiscountAmount.subscribe(amount =>{
      if(typeof(amount) !== 'undefined'){
        if(amount.discountAmount !== 0){
          this.discountedAmount = amount.discountAmount;
        }
      }
    })
    // Get State array
    if (!this.optionsSelectState) {
      this.stateService.fetchAllState('states').subscribe((x) => (this.optionsSelectState = x));
    }
    this.listenForFormChanges();
  }

  listenForFormChanges() {
    this.billingFormGroup
      .get('billing_payment_method')
      .valueChanges.subscribe((billing_payment_method) => {
        if (billing_payment_method === 'unifi') {
          this.billingFormGroup.patchValue({
            unifi_id: '',
            unifi_name: '',
            unifi_phone_number: '',
          });
        } else {
          this.billingFormGroup.patchValue({
            unifi_id: 'NA',
            unifi_name: 'NA',
            unifi_phone_number: 'NA',
          });

          if (billing_payment_method === 'fpx') {
            // TODO: update implementation on BE
            const compareDate = moment(this.currentDate, "DD/MM/YYYY"); 
            const startDate = moment(this.autoApplyStartDate, "DD/MM/YYYY");  
            const endDate = moment(this.autoApplyEndDate, "DD/MM/YYYY");  
            const isBetween = compareDate.isBetween(startDate, endDate)  

            if(isBetween && this.adsPlanForm.get('package').value){
              this.billingFormGroup.patchValue({
                promo_code: this.autoApplyPromoCode,
              });
              this.applyPromoCode();
            }
          }
        }
      });
  }

  onSub() {
    console.log(this.addOnsPackages);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if ('optionsSelectAddOns' in changes) {
    }
  }

  onPostCodeEnter(event) {
    this.billingFormGroup.patchValue({billing_postcode: event.target.value});

    this.stateService.fetchAddressByPostCode(event.target.value).subscribe((x) => {
      this.billingFormGroup.patchValue({
        billing_state_id: '' + x.state_id,
        billing_country_id: '' + x.country_id,
        billing_city: x.city,
      });
    });
  }

  get isFpxPayment() {
    const paymentMethod = this.billingFormGroup.get('billing_payment_method').value;
    const isFpxSelected = paymentMethod ===  'fpx';

    if(isFpxSelected){
      return true;
    }
    return false;

  }

  applyPromoCode(){

    // disable applying promo code if not plan
    const planId = this.adsPlanForm.get('package').value;
    if(!planId){
      this.promoCodeToastMessage('Promo code unavailable', 'error');
      this.billingFormGroup.get('promo_code').reset();
      this.billingFormGroup.get('promo_code').enable();
      return;
    }

    this.stateService.setPromoCode(true);
    this.billingFormGroup.get('promo_code').disable();
    this.disablePromoCodeBtn();
    const requestBody = {
      code: this.billingFormGroup.get('promo_code').value,
      amount: this.caliculatePrice.amount,
      payment_method: 'value',
    }
    this.postService.applyPromoCode('post/promo-code/apply', requestBody).subscribe(result => {
      this.caliculatePrice.amount = result.amount_after_discount;
      this.discountedAmount = result.discounted_amount;
      this.stateService.discountAmount(result.discounted_amount);
      this.discountedAmountEvent.emit(this.discountedAmount);
      this.caliculatePrice.subtotal = result.subtotal;
      this.caliculatePrice.sst_total = result.sst_total;
      this.promoCodeToastMessage('Promo Code Applied', 'success');
    }, err => {
      if(err.error.message === null){
        this.promoCodeToastMessage(err.error.errors[0], 'error');
      }
      else {
        this.promoCodeToastMessage(err.error.message, 'error');
      }
      // clear the promo code input and  restore original price of the plan
      this.billingFormGroup.get('promo_code').reset();
      this.billingFormGroup.get('promo_code').enable();
      this.caliculatePrice.amount = this.caliculatePrice.amount;
      this.discountedAmount = 0;
      this.stateService.discountAmount(this.discountedAmount);
      this.discountedAmountEvent.emit(this.discountedAmount);
      this.caliculatePrice.subtotal = this.caliculatePrice.subtotal;
      this.caliculatePrice.sst_total =  this.caliculatePrice.sst_total;
    })
  }

  removePromoCode(isSkip:boolean){
    this.resetForms();
    if(isSkip) {
      return;
    }
    this.caliculatePrice.amount = this.caliculatePrice?.amount;
    this.caliculatePrice.subtotal = this.caliculatePrice?.subtotal;
    this.caliculatePrice.sst_total =  this.caliculatePrice?.sst_total;

  }

  resetForms() {
    this.discountedAmount = 0;
    this.discountedAmountEvent.emit(this.discountedAmount);
    this.stateService.discountAmount(this.discountedAmount);
    this.stateService.setPromoCode(false)
    this.removePromoCodeEvent.emit(true);
    if(this.billingFormGroup.get('promo_code').value){
      this.promoCodeToastMessage('Promo Code Removed', 'error');
    }
    // clear the promo code input and  restore original price of the plan
    this.billingFormGroup.get('promo_code').reset();
    this.billingFormGroup.get('promo_code').enable();
  }

  disablePromoCodeBtn() {
    this.stateService.isPromoCodeAppliedState
    .pipe(map(state => state.isPromoCodeApplied))
    .subscribe(value => {
      this.isPromoCodeApplied = value
    })
  }

  promoCodeToastMessage(toastMessage:string, toastType:string){
    if(toastType === 'error'){
      this.toastService.error(toastMessage, null, {positionClass: 'md-toast-bottom-right'});
    }
    if(toastType === 'success'){
      this.toastService.success(toastMessage, null, {positionClass: 'md-toast-bottom-right'});
    }
  }
  
}
