<div class="company-form">

    <form [formGroup]="newCompanyFormGroup" >
        <!-- Grid row -->
        <div class="company-form__form-row form-row">
            <!-- Default input -->
            <div class="md-form md-outline col-12">
                <input mdbInput type="text" class="form-control" id="inputcompany" formControlName="name" [autocomplete]="'off'" mdbValidate>
                <label for="inputcompany">Company Name <strong class="red-text">*</strong></label>
                <mdb-error *ngIf="name.invalid && (name.dirty || name.touched)">
                    <span *ngIf="name.errors.required">Company Name can't be blank, it's required</span>
                    <span *ngIf="name.errors.minlength">Company Name must be at least 7 characters</span>
                </mdb-error>
            </div>
            <!-- Default input -->
            <div class="md-form md-outline col-12">
                <input mdbInput type="text" class="form-control" id="inputcompanyRN" formControlName="company_no" [autocomplete]="'off'" mdbValidate>
                <label for="inputcompanyRN4">Company Registration Number <strong class="red-text">*</strong></label>
                <mdb-error *ngIf="company_no.invalid && (company_no.dirty || company_no.touched)">
                    <span *ngIf="company_no.errors.required">Company registration number is required</span>
                    <span *ngIf="company_no.errors.minlength">Company registration number must be at least 7 characters</span>
                </mdb-error>
            </div>
        </div>
      <!-- Grid row -->
      <div class="company-form__form-btn">
          <button type="submit" size="md" mdbBtn color="primary" mdbWavesEffect [disabled]="!newCompanyFormGroup.valid" (click)="onSubmitValues(newCompanyFormGroup.value)">next</button>
      </div>
    </form>


</div>