import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PricingItem } from 'src/app/shared/interfaces/plan';
import { CreateCompanyProfilePayload } from 'src/app/shared/interfaces/advertiser-profile';
import { StateService } from '../../services/api/state.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {



@Input() pricingPlans: PricingItem[]; // Plans data 
@Input() adsPlanForm: FormGroup; // Plan form group
@Input() isLoading: boolean = false;
@Input() isResult: boolean = false;
@Input() isSuccess: boolean = false;
@Input() isNewCompanyPayment = false;

@Input() paymentResult: CreateCompanyProfilePayload = null;

@Output() onPaymentTrigger: EventEmitter<boolean> = new EventEmitter()

get package() {return this.adsPlanForm.get('package');} // to control which plan selected

  paymentTerm: string = 'mo'; // PAYMENT_TERM
  // paymentTerm: string = 'yr';
  isPayPerPost: boolean = false;

  constructor(
    private stateService: StateService,
  ) { 
    stateService.getPayPerPostPlan().subscribe(item => {
      this.isPayPerPost = item.data;
      this.paymentTerm = 'mo';
    })
  }

  ngOnInit(): void {
    if (this.adsPlanForm.get('payment_term').value === "") {
      this.adsPlanForm.get('payment_term').setValue(false); // PAYMENT_TERM
      // this.adsPlanForm.get('payment_term').setValue(true);
    }

    if (this.adsPlanForm.get('payment_term').value === true) {
      this.paymentTerm = 'yr';
    }

    if (this.adsPlanForm.get('payment_term').value === false) {
      this.paymentTerm = 'mo';
    }
  }

  onPayment() {
    this.onPaymentTrigger.emit();
  }

  changePaymentTerm(e) {
    let x = this.adsPlanForm.get('payment_term').value;

    if (x) {
      this.paymentTerm = 'mo';
    } else {
      this.paymentTerm = 'yr';
    }
    this.stateService.onSelectPaymentTerm(this.paymentTerm);
  }

  onSelectPricingPlan(planDetails){
    let obj = {
      paymentTerm:  this.adsPlanForm.get('payment_term').value,
      isPayPerPost: this.isPayPerPost,
    };
    this.stateService.onSelectPricingPlan(obj);
  }

}
