import { Component, OnInit, Input } from '@angular/core';
import { Traffic } from 'src/app/shared/interfaces/traffic';

@Component({
  selector: 'app-traffic-widget',
  templateUrl: './traffic-widget.component.html',
  styleUrls: ['./traffic-widget.component.scss']
})
export class TrafficWidgetComponent implements OnInit {

  @Input() traffics: Traffic[];

  constructor() { }

  ngOnInit(): void {
  }

}
