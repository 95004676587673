<div class="addOns">
    <div class="addOns__title">
        <h5>Boost your advertisement with these add-ons:</h5>
        <button size="sm" type="button" mdbBtn color="primary" mdbWavesEffect (click)="resetAddOnsForm()">reset addOns</button>
    </div>
    <div class="addOns__body">

        <ng-container *ngIf="addOnsGroup && addOnsGroup.length > 0">
            <ng-container *ngFor="let addOnGroup of addOnsGroup">
                <div class="addOns__body--item">

                    <div class="addOns__body--item-category">
                        <div class="addOns__body--item-category--title">
                            {{addOnGroup.category}}
                        </div>
                        <div class="addOns__body--item-category--link">
                            <a type="button">View sample</a>
                        </div>
                    </div>
                    
                  
                    <div class="addOns__body--item-options" [formGroup]="addOnsFormGroup">
        
                        <!--Radio group-->
                        <div class="form-check" *ngFor="let item of addOnGroup.addOns">
                            <input type="radio" class="form-check-input with-gap" id="Bump-up{{item.id}}" formControlName="{{addOnGroup.category}}" [value]="item.id" mdbInput>
                            <label class="form-check-label" for="Bump-up{{item.id}}">{{ item.effective_day }} days - {{ item.token_consume }} Tokens</label>
                        </div>
                        <!--Radio group-->
        
                    </div>

                </div>
            </ng-container>
        </ng-container>

        
    </div>
</div>