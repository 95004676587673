import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BodySideNavMenuService {


  isOpenSub$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  isOpen$: Observable<boolean> = this.isOpenSub$.asObservable();

  constructor() { }

  // Open the menu
  open() {


    // Event emit
    this.isOpenSub$.next(true);
  }

  // Close the menu
  close() {

    // event emit
    this.isOpenSub$.next(false);
  }

  // Toggle the menu
  toggle() {
  }

}
