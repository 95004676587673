import { Component, Output, OnInit, OnDestroy, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompaniesService } from 'src/app/shared/services/api/companies.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckCompany, CheckCompanyRespone,  } from 'src/app/shared/interfaces/advertiser-profile';




@Component({
  selector: 'app-create-new-company',
  templateUrl: './create-new-company.component.html',
  styleUrls: ['./create-new-company.component.scss']
})
export class CreateNewCompanyComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter()

  @Input() isForm: BehaviorSubject<boolean>; // switch btwn form for new company and result after checking company status
  @Input() newCompanyFormGroup: FormGroup;
  isLoading = false; // loading spinner

  isNewCompanyForm = false; // case 1 new company not existing in DB
  canClaim = false; // case 2 existing company but can claim 
  isClaimableMsg: boolean; 

  companyValues: CheckCompany; // new company form data entered
  checkCompanyRespone: CheckCompanyRespone; // checking company result

  


  constructor(private compService: CompaniesService) { }

  ngOnInit(): void {


  }

  compantCheckStatus(val: CheckCompany) {
    // set Loating to true
    this.isLoading = true;
   
    // call Api to check
    this.compService.checkCompany('advertiser/company/validate', val).pipe(takeUntil(this.destroy$))
    .subscribe(x => {
      this.isLoading = false;

      if (x !== null ){
        this.isNewCompanyForm = false;
        x.company.claimed !== 0 ? this.canClaim = false : this.canClaim = true ;

      } else{
        this.isNewCompanyForm = true;
      }
      this.companyValues = val;
      this.isForm.next(false);
      this.isClaimableMsg = true;
      this.checkCompanyRespone = x;
    });
    
  }


  cliamCompanyProfile() {
    this.isClaimableMsg = false;
  }


  onCloseModal(){
    this.closeModal.emit()
  }

  ngOnDestroy(): void {
   this.destroy$.next();
   this.destroy$.complete()
  }

}
