import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavComponent } from 'ng-uikit-pro-standard';
import { Link } from 'src/app/shared/interfaces/link';
import { UserUtilsService } from 'src/app/shared/utils/user-utils.service'
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header-side-nav',
  templateUrl: './header-side-nav.component.html',
  styleUrls: ['./header-side-nav.component.scss']
})
export class HeaderSideNavComponent implements OnInit {

  @ViewChild('sidenav') sidenav: SidenavComponent;
 

  // sideNavItems: Link[] = [
  //   {title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/dashboard' },
  //   {title: 'Business Analytics', icon: 'business-analytics' , hasSubLink: false, subLinks: [], url: '/dashboard/analytics' },
  //   {title: 'Create Post', icon: 'create-post' , hasSubLink: false, subLinks: [], url: '/dashboard/post/create-post' },
  //   {title: 'Manage Post', icon: 'manage-post' , hasSubLink: false, subLinks: [], url: '/dashboard/post' },
  //   {title: 'Manage Advertiser Profile', icon: 'profile' , hasSubLink: false, subLinks: [], url: '/dashboard/account' },
  //   {title: 'Billing', icon: 'billing' , hasSubLink: false, subLinks: [], url: '/dashboard/billing' },
  //   {title: 'YPoints', icon: 'yPoints' , hasSubLink: false, subLinks: [], url: '/dashboard/yPoints'},
  // ];

  sideNavItems: Link[] = [];

  constructor(
    private userUtilsService: UserUtilsService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    sessionStorage.removeItem('perPage');

    this.auth.getUser().subscribe(res => {
      if (this.userUtilsService.verifyUserRole(res.roles, "admin")) {
        this.addAdminMenus();
      } else {
        this.addAdvertiserMenus();
      }
    });
  }

  hideSidenavAfterClick() {
    if (window.innerWidth <= 768) {
      this.sidenav.hide();
    }
  }

  addAdminMenus() {
    this.sideNavItems.push({title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/admin/dashboard' });
    this.sideNavItems.push({title: 'Business Analytics', icon: 'analytics' , hasSubLink: true, subLinks: [
     
    ], url: '/admin/analytics' });
    // this.links.push({title: 'Create Post', icon: 'create-post' , hasSubLink: false, subLinks: [], url: '/advertiser/create-post' });
    this.sideNavItems.push({title: 'Manage Post', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/admin/manage-post' });
    this.sideNavItems.push({title: 'Manage Advertiser Profile', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/admin/manage-profile' });
    this.sideNavItems.push({title: 'Member Management', icon: 'groups' , hasSubLink: false, subLinks: [], url: '/admin/user-management' });
    this.sideNavItems.push({title: 'Billing', icon: 'receipt_long' , hasSubLink: false, subLinks: [], url: '/admin/billing' });
    this.sideNavItems.push({title: 'Report', icon: 'analytics' , hasSubLink: false, subLinks: [], url: '/admin/report' });
    this.sideNavItems.push({title: 'yPoints', icon: 'paid' , hasSubLink: false, subLinks: [], url: '/admin/yPoints'});
    console.log(this.sideNavItems)

  }

  addAdvertiserMenus() {
    this.sideNavItems.push({title: 'Dashboard', icon: 'dashboard' , hasSubLink: false, subLinks: [], url: '/advertiser/dashboard' });
    this.sideNavItems.push({title: 'Business Analytics', icon: 'analytics' , hasSubLink: true, subLinks: [
      {title: 'Demographics Analytics', url: '/advertiser/analytics/demographics' },
      {title: 'Behaviour Analytics', url: '/advertiser/analytics/behaviour' },
      {title: 'Geolocation Analytics', url: '/advertiser/analytics/geolocation' },
      {title: 'Technology Analytics', url: '/advertiser/analytics/technology' }
    ], url: '/advertiser/analytics' });
    this.sideNavItems.push({title: 'Create Post', icon: 'drive_file_rename_outline' , hasSubLink: false, subLinks: [], url: '/advertiser/create-post' });
    this.sideNavItems.push({title: 'Manage Post', icon: 'note_alt' , hasSubLink: false, subLinks: [], url: '/advertiser/manage-post' });
    this.sideNavItems.push({title: 'Manage Advertiser Profile', icon: 'groups' , hasSubLink: false, subLinks: [], url: '/advertiser/manage-profile' });
    this.sideNavItems.push({title: 'Billing', icon: 'receipt_long' , hasSubLink: false, subLinks: [], url: '/advertiser/billing' });
    this.sideNavItems.push({title: 'yPoints', icon: 'paid' , hasSubLink: false, subLinks: [], url: '/advertiser/yPoints'});
  }

}