import { Component, Input, OnInit } from '@angular/core';
import { PricingPostService } from 'src/app/shared/services/pricing-post.service';

@Component({
  selector: 'app-no-plan-summary',
  templateUrl: './no-plan-summary.component.html',
  styleUrls: ['./no-plan-summary.component.scss']
})
export class NoPlanSummaryComponent implements OnInit {

  postAmountToken: number;
  postAmountRM: number;

  addOnsAmountToken: number;
  addOnsAmountRM: number;
  @Input() caliculatePrice;

  constructor(public pricingPost: PricingPostService) { }

  ngOnInit(): void {

    this.pricingPost.item$.subscribe(item=> {
      this.postAmountToken = item.currentPostPrice.reduce((a, v) => a = a + v.token, 0)
    });

    this.pricingPost.item$.subscribe(item=> {
      this.postAmountRM = item.currentPostPrice.reduce((a, v) => a = a + v.price, 0)
    });

    this.pricingPost.item$.subscribe(item=> {
      this.addOnsAmountToken = item.selectedAddOns.reduce((a, v) => a = a + v.token_consume, 0)
    });

    this.pricingPost.item$.subscribe(item=> {
      this.addOnsAmountRM = item.selectedAddOns.reduce((a, v) => a = a + v.price, 0)
    });

  }

}
