import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PrincipalService } from 'src/app/core/principal.service';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private principal: PrincipalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        console.log('canActivate ');
        this.auth.initializeUserStorage();
        const authorities = route.data['authorities'];
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    }

    checkLogin(authorities: any[], url: string): Promise<boolean> {
        console.log('checkLogin :', url);
        console.log('authorities need check :', authorities);

        return Promise.resolve(
            this.principal.hasAnyAuthority(authorities).then(response => {
                if (response) {
                    return true;
                }

                // this.router.navigate(['accessdenied']);
                this.auth.logout();
                this.router.navigate(['']);

                return false;
            })
        );
    }
}
