import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BodySideNavMenuService } from 'src/app/shared/services/body-side-nav-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() layout: 'website' | 'dashboard' = 'dashboard';
  @ViewChild('confirmationModal') confirmationModal: any;
  host = environment.homePage;
  tutorialPageUrl = environment.tutorialPageUrl;
  faqPageUrl = environment.faqPageUrl;

  constructor(public bodySideNav: BodySideNavMenuService) { }

  ngOnInit(): void {

  }
  showConfirmationModal() {
    this.confirmationModal.show();
  }
  hideConfirmationModal() {
    this.confirmationModal.hide();
  }
  redirectToMainPage() {
    this.hideConfirmationModal();
    window.open(this.host);
  }

}
