<ng-container *ngIf="payByPostPlan">

    <div class="post-summary__summary--body-details">
        <div class="post-summary__summary--body-details--item">
    
            <div class="post-summary__summary--body-details--item-text">{{ payByPostPlan.name }} Plan</div>
            <div class="post-summary__summary--body-details--item-amount">{{ payByPostPlan.price | currencyFormat }}</div>
    
        </div>
    
            <div class="post-summary__summary--body-details--item" *ngIf="payByPostPlan.description">
    
                <div class="post-summary__summary--body-details--item-text">{{ payByPostPlan.description}}</div>
                <div class="post-summary__summary--body-details--item-amount" >0 Token</div>
        
            </div>
    
    
    </div>
    
    <div class="post-summary__summary--body-divider"></div>
    
    <div class="post-summary__summary--body-subtotal">
        <div class="post-summary__summary--body-subtotal--title">Subtotal</div>
        <div class="post-summary__summary--body-subtotal--amount">{{ payByPostPlan.price | currencyFormat}}</div>
    </div>
    
    <div class="post-summary__summary--body-divider"></div>
    
    <div class="post-summary__summary--body-total">
        <div class="post-summary__summary--body-total--title">Total</div>
        <div class="post-summary__summary--body-total--amount">{{ payByPostPlan.price | currencyFormat}}</div>
    </div>

</ng-container>