<div class="mobile-side-header">
    <div class="mobile-site-header__container">
        <div class="mobile-site-header__container--sidenav" >
            <app-header-side-nav></app-header-side-nav>
            <!-- <app-icon name="menu-18x14" size="18x14" ></app-icon>          -->
        </div>

        <div class="mobile-site-header__container--logo">
            <a class="mobile-header__logo" routerLink="/home">
                <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="30px" viewBox="0 0 162.1 85.558">
                    <g id="Group_1324" data-name="Group 1324" transform="translate(-51.887 -102.807)">
                      <path id="Path_1" data-name="Path 1" d="M412.905,347.3l2.614-5.227c.8.362,1.522.743,2.283,1.031a13.8,13.8,0,0,0,7.948.714,8.526,8.526,0,0,0,6.473-5.831,40.549,40.549,0,0,0,1.046-4.179c.014-.059-.021-.13-.048-.276-.167.138-.308.234-.424.353a11.417,11.417,0,0,1-6.24,3.356,10.211,10.211,0,0,1-7.409-1.188,8.8,8.8,0,0,1-3.982-5.989,15.951,15.951,0,0,1,.46-7.786,18.994,18.994,0,0,1,5.207-8.8,14.731,14.731,0,0,1,9.011-4.233,8.971,8.971,0,0,1,5.684,1.141,5.745,5.745,0,0,1,2.136,2.357c.1.2.212.4.393.739.488-1.25.7-2.451,1.083-3.573a32.368,32.368,0,0,1,6.054,0c-.028.167-.044.344-.088.514-1.328,5.134-2.281,10.348-3.322,15.543-.6,2.979-1.153,5.967-1.817,8.93a22,22,0,0,1-3.724,8.62,14.34,14.34,0,0,1-7.33,5.05,21.859,21.859,0,0,1-7.788.946,17.909,17.909,0,0,1-7.421-1.738C413.463,347.647,413.238,347.5,412.905,347.3Zm23.067-27.686-.076-.012c0-.16,0-.32,0-.48a4.545,4.545,0,0,0-4.734-4.566,7.481,7.481,0,0,0-5.505,2.533,12.65,12.65,0,0,0-3.094,6.02,10.451,10.451,0,0,0-.136,5.15,4.856,4.856,0,0,0,5.35,3.936,7.624,7.624,0,0,0,6.872-5.872c.367-1.531.659-3.08.969-4.624C435.756,321.007,435.855,320.308,435.972,319.612Z" transform="translate(-281.942 -161.176)"/>
                      <path id="Path_2" data-name="Path 2" d="M609.058,188.566c-.523-6.667-1.516-13.255-1.33-19.933l-.185-.03c-.11.261-.216.525-.331.784-1.247,2.8-2.423,5.627-3.761,8.379-1.69,3.473-3.514,6.88-5.275,10.319-.167.326-.346.562-.767.559-1.876-.014-3.752-.005-5.628-.01a2.809,2.809,0,0,1-.447-.082c-.063-.526-.134-1.04-.184-1.556-.108-1.113-.2-2.227-.308-3.341q-.252-2.692-.509-5.384c-.107-1.136-.209-2.273-.316-3.409q-.253-2.692-.509-5.384c-.153-1.636-.3-3.273-.454-4.909-.074-.8-.169-1.589-.229-2.385-.031-.405-.005-.814-.005-1.217a49.364,49.364,0,0,1,7.038-.04c.014.222.04.463.045.705q.085,4.009.166,8.017.064,3.048.136,6.1c.029,1.255.085,2.51.091,3.765,0,.8-.062,1.6-.095,2.394a.9.9,0,0,0,.064.418c3.168-7.269,6.789-14.308,10.248-21.446h5.732c.589,7.162,1.333,14.274,1.448,21.445,2.672-7.338,6.164-14.3,9.276-21.447h6.883c-.12.29-.195.513-.3.72q-2.738,5.3-5.481,10.6-2.909,5.637-5.806,11.28c-.785,1.524-1.575,3.046-2.354,4.573-.176.345-.36.6-.815.6-1.853-.018-3.706-.007-5.56-.011A4.427,4.427,0,0,1,609.058,188.566Z" transform="translate(-419.317 -45.306)"/>
                      <path id="Path_3" data-name="Path 3" d="M146.856,334.766q-1.413,7.016-2.824,14.016a43.842,43.842,0,0,1-7.012.086c2.465-13,5.419-25.9,7.63-38.975h6.134l-.5,4.22a3.242,3.242,0,0,0,.419-.3,13.223,13.223,0,0,1,10.762-4.51,8.434,8.434,0,0,1,7.89,5.71,13.574,13.574,0,0,1,.717,6.272,21.9,21.9,0,0,1-2.089,7.487,16.6,16.6,0,0,1-9.647,8.84,12.866,12.866,0,0,1-3.969.709,9.452,9.452,0,0,1-4.466-.878,6.147,6.147,0,0,1-2.665-2.392c-.065-.11-.15-.209-.226-.314Zm1.509-7.349.079.016a4.841,4.841,0,0,0,4.971,5.444,7.593,7.593,0,0,0,5.437-2.067,12.27,12.27,0,0,0,3.489-6.183,10.9,10.9,0,0,0,.154-6.082,4.827,4.827,0,0,0-5.176-3.732,7.852,7.852,0,0,0-5.6,2.707,9.293,9.293,0,0,0-2.357,4.793C149.045,324.016,148.7,325.715,148.365,327.417Z" transform="translate(-66.485 -161.251)"/>
                      <path id="Path_4" data-name="Path 4" d="M450.1,187.207a14.186,14.186,0,0,1-5.24-1,9.44,9.44,0,0,1-6.009-7.66,17.956,17.956,0,0,1,1.46-9.9,17.1,17.1,0,0,1,3.718-5.546,16.849,16.849,0,0,1,6.075-3.89,17.851,17.851,0,0,1,7.686-1.038,10.97,10.97,0,0,1,6.477,2.506,9.452,9.452,0,0,1,2.975,4.824,14.694,14.694,0,0,1,.1,6.789,19.025,19.025,0,0,1-3.265,7.7,16.98,16.98,0,0,1-10.587,6.806C452.383,187.027,451.234,187.074,450.1,187.207Zm10.215-17.89a21.253,21.253,0,0,0-.379-2.395,4.59,4.59,0,0,0-3.956-3.684,7.268,7.268,0,0,0-6.329,2.477,13.374,13.374,0,0,0-3.065,5.752,12.515,12.515,0,0,0-.352,6.227,5.788,5.788,0,0,0,1.751,3.19,5.022,5.022,0,0,0,4.446,1.052,8.031,8.031,0,0,0,5.109-3.549A15.826,15.826,0,0,0,460.317,169.316Z" transform="translate(-302.057 -43.191)"/>
                      <path id="Path_5" data-name="Path 5" d="M56.66,161.117H64.38l2.6,20.111,9.933-20.11h7.446c-.391.708-.723,1.323-1.067,1.931q-3.956,6.979-7.916,13.957c-2.466,4.357-4.938,8.711-7.77,12.847a38.221,38.221,0,0,1-5.267,6.391,19.744,19.744,0,0,1-9.153,5.017c-.238.055-.484.078-.842.134-.236-1.956-.309-3.856-.453-5.782.247-.1.43-.179.621-.239a16.152,16.152,0,0,0,6.185-3.572,28.182,28.182,0,0,0,2.285-2.452,3.073,3.073,0,0,0,.707-2.8c-.663-3.08-1.245-6.178-1.86-9.269q-1.538-7.728-3.075-15.457C56.706,161.6,56.692,161.379,56.66,161.117Z" transform="translate(0 -45.538)"/>
                      <path id="Path_6" data-name="Path 6" d="M313.284,337.617h-6.158c-.257-.98.1-1.932-.009-2.985a4.117,4.117,0,0,0-.485.32,12.537,12.537,0,0,1-6.711,3.2,9.266,9.266,0,0,1-5.76-.685,5.761,5.761,0,0,1-3.343-5.277c-.109-4.534,2.117-7.73,5.91-9.946a19.626,19.626,0,0,1,7.88-2.414c1.474-.161,2.961-.2,4.442-.292.135-.009.269-.035.392-.052a3.526,3.526,0,0,0-2.037-4.92,8.9,8.9,0,0,0-3.458-.327,17.914,17.914,0,0,0-6.556,1.862,2.187,2.187,0,0,1-.33.1l-.446-4.47c.215-.127.4-.26.6-.353a23.482,23.482,0,0,1,7.726-2,18.027,18.027,0,0,1,5.927.3,6.954,6.954,0,0,1,5.054,3.942,9.079,9.079,0,0,1,.707,3.194,23.276,23.276,0,0,1-.73,6.1c-.693,3.472-1.415,6.938-2.073,10.416-.237,1.253-.324,2.534-.48,3.8C313.331,337.264,313.313,337.4,313.284,337.617Zm-4.587-13.5c-.341-.02-.566-.053-.789-.042-.753.035-1.508.057-2.256.136a12.393,12.393,0,0,0-4.785,1.353,5.239,5.239,0,0,0-2.823,3.758,5.144,5.144,0,0,0-.016,1.767,2.785,2.785,0,0,0,2.811,2.17,7,7,0,0,0,5.191-1.933,5.737,5.737,0,0,0,1.952-3.225c.2-1.077.425-2.151.632-3.227C308.655,324.661,308.663,324.437,308.7,324.122Z" transform="translate(-186.594 -161.234)"/>
                      <path id="Path_7" data-name="Path 7" d="M211.5,185.356a16.315,16.315,0,0,1-3.475,1.077,27.83,27.83,0,0,1-7.628.674,12.993,12.993,0,0,1-6.69-1.989,8.61,8.61,0,0,1-3.94-6.678,19.021,19.021,0,0,1,6.555-16.423,14.742,14.742,0,0,1,10.433-3.852,12.221,12.221,0,0,1,3.78.568,7.777,7.777,0,0,1,5.153,5.367,14.932,14.932,0,0,1,.212,7.389,13.507,13.507,0,0,1-.914,3.141H196.535a1.772,1.772,0,0,0-.181.421c-.323,3,.827,5.2,3.743,6.231a9.452,9.452,0,0,0,2.89.516,24.333,24.333,0,0,0,7.917-1.053c.171-.052.347-.085.593-.144Zm-1.9-15.677a7.058,7.058,0,0,0,.171-2.927A4.031,4.031,0,0,0,206.177,163a6.94,6.94,0,0,0-5.111,1.353,10.368,10.368,0,0,0-3.439,4.775,5.2,5.2,0,0,0-.143.55Z" transform="translate(-107.613 -43.231)"/>
                      <path id="Path_8" data-name="Path 8" d="M590.074,325.724h-18.55a5.287,5.287,0,0,0,2.278,6.065,8.525,8.525,0,0,0,3.653,1.067,23.746,23.746,0,0,0,8.52-1.05,2.43,2.43,0,0,1,.266-.067,1.23,1.23,0,0,1,.212.012v4.718c-.116.05-.314.151-.523.224a24.031,24.031,0,0,1-6.422,1.36c-1.459.089-2.925.168-4.384.113a12.28,12.28,0,0,1-6.422-1.937,8.668,8.668,0,0,1-3.891-6.124,18.155,18.155,0,0,1,1.592-10.357,18.5,18.5,0,0,1,3.673-5.509,15.666,15.666,0,0,1,9.587-4.877,13.789,13.789,0,0,1,5.371.327,7.8,7.8,0,0,1,5.685,5.774,15.461,15.461,0,0,1,.093,7.332C590.622,323.753,590.338,324.692,590.074,325.724Zm-5.56-4.985a.924.924,0,0,0,.132-.21,6.5,6.5,0,0,0-.2-3.839,3.651,3.651,0,0,0-2.147-2.269,6.053,6.053,0,0,0-4.862.22,9.536,9.536,0,0,0-4.891,5.729,1.242,1.242,0,0,0,0,.369Z" transform="translate(-400.419 -161.218)"/>
                      <path id="Path_9" data-name="Path 9" d="M704.67,315.781a11.546,11.546,0,0,0-3.713-1.4,7.087,7.087,0,0,0-4.051.223,3.466,3.466,0,0,0-2.311,2.75,1.753,1.753,0,0,0,.458,1.646,5.361,5.361,0,0,0,1.316.965c1,.5,2.041.922,3.068,1.368a10.36,10.36,0,0,1,3.591,2.345,5.456,5.456,0,0,1,1.534,3.779,9.641,9.641,0,0,1-5.2,8.876,15.54,15.54,0,0,1-8.116,1.887A17.208,17.208,0,0,1,685,337.137c-.567-.219-1.106-.511-1.728-.8l2.468-5.134c.288.149.506.257.72.374a12.68,12.68,0,0,0,5.893,1.637,5.985,5.985,0,0,0,3.722-.973,3.432,3.432,0,0,0,1.481-3.138,1.9,1.9,0,0,0-.952-1.59,28.247,28.247,0,0,0-2.984-1.519,14.977,14.977,0,0,1-3.656-2,5.843,5.843,0,0,1-2.2-6.278,10.2,10.2,0,0,1,5.469-6.852,16.088,16.088,0,0,1,12.893-.456c.306.133.59.316.947.51Z" transform="translate(-493.089 -161.227)"/>
                      <path id="Path_10" data-name="Path 10" d="M382.555,143.293h-6.808a.883.883,0,0,1-.092-.086.133.133,0,0,1-.028-.062,1.024,1.024,0,0,1-.011-.27q4-19.849,8-39.7a2.728,2.728,0,0,1,.14-.372h6.793a.446.446,0,0,1,.072.059.143.143,0,0,1,.025.063.647.647,0,0,1,.019.2c-.236,1.211-.471,2.422-.715,3.631Q386.261,124.992,382.555,143.293Z" transform="translate(-252.816)"/>
                      <path id="Path_11" data-name="Path 11" d="M322.383,143.282h-6.876c-.059-.533,7.707-39.439,8.078-40.471h6.943Z" transform="translate(-205.877 -0.003)"/>
                    </g>
                </svg>                      
            </a>
        </div>

        <!-- <div class="mobile-site-header__container--search-icon">
            <app-header-search></app-header-search>
        </div> -->
        <div class="mobile-site-header__container--search-icon">
          <i class="fas fa-home" style="font-size: xx-large;cursor: pointer;" (click)="showConfirmationModal()"></i>
        </div>
     

        <div class="mobile-site-header__container--messages-icon">
            <app-icon name="msg" size="20"></app-icon>
            <div class="mobile-site-header__counter">4</div>
        </div>

        <div class="mobile-site-header__container--dropdown" *ngIf="auth.user$ |async let user">
            <div class="btn-group" mdbDropdown>
                <a mdbDropdownToggle mdbWavesEffect type="button" class="dropdown-toggle waves-light" mdbWavesEffect
                   style="height: auto; width: 30px">
                  <img src="/assets/images/avatar.jpeg" alt=""
                       class="img-fluid rounded-circle z-depth-0" ></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="#">{{ user.name }}</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" type="button" (click)="logout()">logout</a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- side Nav -->
<!-- <mdb-side-nav #sidenav [fixed]="true" class="fixed">
  <li>
    <div class="logo-wrapper waves-light">
      <a href="#"><img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" class="img-fluid flex-center"></a>
    </div>
  </li>
  <li>
    <ul class="social">
      <li><a class="icons-sm fb-ic"><i class="fab fa-facebook-f"> </i></a></li>
      <li><a class="icons-sm pin-ic"><i class="fab fa-pinterest"> </i></a></li>
      <li><a class="icons-sm gplus-ic"><i class="fab fa-google-plus"> </i></a></li>
      <li><a class="icons-sm tw-ic"><i class="fab fa-twitter"> </i></a></li>
    </ul>
  </li>


  <div class="sidenav-bg mask-strong"></div>
</mdb-side-nav> -->
<div class="modal fade" mdbModal id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="suspendAModelCenterTitle"
  aria-hidden="true" #confirmationModal="mdbModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideConfirmationModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="col-12" style="padding: 15px;">
            Please save all your works. Click 'Yes' if you are ready.
        </div>
        <div class="row justify-content-md-center">
            <div style="margin: auto;">
                <button type="button" mdbBtn style="background-color: black !important;" class="btn btn-primary" (click)="hideConfirmationModal()" mdbWavesEffect>CANCEL</button>
                <button type="button" mdbBtn  class="btn btn-primary" (click)="redirectToMainPage()" mdbWavesEffect>YES</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>