import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {apiUrl} from '../../data/token';
import { environment } from 'src/environments/environment';
import {AddOns} from 'src/app/shared/interfaces/addOns';
import {groupBy, map, mergeMap, switchMap, toArray} from 'rxjs/operators';
import {ApiTokenService} from 'src/app/auth/services/api-token.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddOnsService {

  constructor(private http: HttpClient, private apiHeader: ApiTokenService) {}

  fetchAddOns(route: string) {
    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          let obj = result['data'];
          return Object.keys(obj).map(key => obj[key]) as AddOns[];
        }
      }),
      switchMap(x => x),
      groupBy(addOn => addOn.category),
      mergeMap(group => group.pipe(toArray()))
    );
  }

  fetchAllAddOns(route: string) {

    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          const obj = result['data'];
          return Object.keys(obj).map(key => {
            return {addOns: obj[key] as AddOns[], category: key, label: key};
          });
        }
      })
    );

  }


  fetchAllTopup(route: string) {

    return this.http.get(`${environment.apiUrl}/${route}`, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          const obj = result['data'];
          return obj
        }
      })
    );

  }

  calculateTopupPrice(data) {

    return this.http.post(`${environment.apiUrl}/advertiser/token/calculate-price`,data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          const obj = result['data'];
          return obj
        }
      })
    );
  }

  postToken(data) {
    return this.http.post(`${environment.apiUrl}/advertiser/token`,data, {headers: this.apiHeader.fetchToken()}).pipe(
      map(result => {
        if (result.hasOwnProperty('data')) {
          const obj = result['data'];
          return obj
        }
      })
    );
  }


  

  



}
