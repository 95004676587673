import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderModule } from 'src/app/header/header.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';



@NgModule({
  declarations: [MobileHeaderComponent, MobileMenuComponent],
  imports: [
    CommonModule,
    SharedModule,
    MDBBootstrapModulesPro,
    HeaderModule
  ],
  exports: [MobileHeaderComponent, MobileMenuComponent]
})
export class MobileModule { }
