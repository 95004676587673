import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PostService } from 'src/app/shared/services/api/post.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.scss'],
})
export class ViewPostComponent implements OnInit {
  @Input() previewPostData;
  @Input() previewPostId;
  @Input() rawData;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedReviewValue = 0;
  constructor(private postService: PostService) {}

  selectedImage;
  host = `${environment.cdnUrl}`;
  placeholder = 'http://placehold.it/200x200';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('previewPostId' in changes) {
      this.getPost(this.previewPostId);
    }
    if ('previewPostData' in changes) {
      this.previewPostData = this.previewPostData;
      this.selectedImage = this.previewPostData?.images[0];
    }
  }

  getPost(postId) {
    this.postService.getPostId(`advertiser/post/${postId}/edit`).subscribe(
      (data) => {
        this.previewPostData = data;
        this.selectedImage = data.images[0];
      },
      (err) => {
        console.log(err);
      },
    );
  }
  countStar(star) {
    this.selectedReviewValue = star;
    console.log('Value of star', star);
  }
}
