import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
import { SideNavBlockComponent } from './side-nav-block/side-nav-block.component';
import { MainNavBlockComponent } from './main-nav-block/main-nav-block.component';



@NgModule({
  declarations: [
    SideNavBlockComponent,
    MainNavBlockComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro,
    SharedModule
  ],
  exports: [
    SideNavBlockComponent,
    MainNavBlockComponent
  ]
})
export class BlocksModule { }
