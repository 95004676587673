<div class="inquiries-widget">
    <mdb-card class="inquiries-widget__card">
        <mdb-card-header class="inquiries-widget__card-header">
            <div class="inquiries-widget__card-header--icon">
                <app-icon name="inquiry" size="27x22"></app-icon>
            </div>
            <span class="inquiries-widget__card-header--title">
                New Enquiries <span *ngIf="inquiries && inquiries.length > 0">({{ inquiries.length }})</span>
            </span>
            <div class="inquiries-widget__card-header--link">
                <a routerLink="/messages">View all</a>
            </div>
        </mdb-card-header>
        <ul class="list-group list-group-flush inquiries-widget__card-ul">
            <li class="list-group-item inquiries-widget__card-ul--li" *ngFor="let inquiry of inquiries">

                <div class="inquiries-widget__card-ul--li-avatar">
                    <app-icon name="avatar" size="65"></app-icon>
                </div>

                <div class="inquiries-widget__card-ul--li-title">

                    <div class="inquiries-widget__card-ul--li-title--sender">
                        {{ inquiry.sender }}
                    </div>
                    <div class="inquiries-widget__card-ul--li-title--subject">
                        {{ inquiry.subject }}
                    </div>
                    <div class="inquiries-widget__card-ul--li-title--date">
                        {{ inquiry.date | date}}
                    </div>

                </div>

                <div class="inquiries-widget__card-ul--li-icons">
                    <span [ngClass]="{'stared': inquiry.star}">
                        <app-icon name="star" size="20x26"></app-icon>
                    </span>
                    <app-icon name="trash" size="14x26"></app-icon>
                </div>
                
            </li>
        </ul>
    </mdb-card>
</div>