import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CurrencyService } from 'src/app/shared/services/currency.service';


@Pipe({
  name: 'currencyFormat',
  pure: false
})
export class CurrencyFormatPipe implements PipeTransform {

  currencyPipe: CurrencyPipe = new CurrencyPipe(this.localeId);

  constructor(@Inject(LOCALE_ID) private localeId: string,  private cursencyService: CurrencyService) {}

  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    currencyCode = currencyCode || this.cursencyService.options.code;
    display = display || this.cursencyService.options.display;
    digitsInfo = digitsInfo || this.cursencyService.options.digitsInfo;
    locale = locale || this.cursencyService.options.locale;

    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
  }

}
