import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Product } from 'src/app/shared/interfaces/product';

interface CompareData {
  items: Product[];
}

@Injectable({
  providedIn: 'root'
})
export class CompareService {
  private data: CompareData = {
    items: []
  };

  private destroy$: Subject<void> = new Subject();
  private itemsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  private onAddingSubject$: Subject<Product> = new Subject();

  readonly items$: Observable<Product[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
  readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: any ) {
    if (isPlatformBrowser(this.platformId)) {
      this.load();
    }
   }

   add(product: Product): Observable<void> {
    // timer only for demo
    return timer(1000).pipe(map(() => {
        this.onAddingSubject$.next(product);

        const index = this.data.items.findIndex(item => item.id === product.id);

        if (index === -1) {
            this.data.items.push(product);
            this.save();
        }
    }));
}

remove(product: Product): Observable<void> {
    // timer only for demo
    return timer(1000).pipe(map(() => {
        const index = this.data.items.findIndex(item => item.id === product.id);

        if (index !== -1) {
            this.data.items.splice(index, 1);
            this.save();
        }
    }));
}

  //  Privates
  private save() {
    localStorage.setItem('compareItems', JSON.stringify(this.data.items));
    this.itemsSubject$.next(this.data.items);
  }

  private load() {
    const compareItems = localStorage.getItem('compareItems');

    if (compareItems) {
      this.data.items = JSON.parse(compareItems);
      this.itemsSubject$.next(this.data.items);
    }
  }
}
