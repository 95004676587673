import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CreateCompanyProfilePayload, CreateCompanyProfileResult } from 'src/app/shared/interfaces/advertiser-profile';
import { CityService } from 'src/app/shared/services/api/city.service';
import { CompaniesService } from 'src/app/shared/services/api/companies.service';
import { StateService } from 'src/app/shared/services/api/state.service';

interface CheckCompany {
  // isLoading: boolean;
  name: string;
  company_no: string;
}


@Component({
  selector: 'app-new-company-form',
  templateUrl: './new-company-form.component.html',
  styleUrls: ['./new-company-form.component.scss']
})
export class NewCompanyFormComponent implements OnInit, OnChanges, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  isLoading = false; // loading spinner once submit
  isResult = false; // result or form
  isSuccess = false; // 
  createCompanyResult: CreateCompanyProfileResult;
  isNewCompanyPayment= true;

  @Input() companyValues: CheckCompany; // new company name and RN
  @Output() closeModalEventEmitter: EventEmitter<boolean> = new EventEmitter();

  optionsCountry: IOption[] = [
    {value: '1', label: 'Malaysia', icon: ''}
  ];
  optionsCity: IOption[];
  optionsState: IOption[];

  createNewCompanyFormGroup: FormGroup = new FormGroup({
    name: new FormControl(),
    companyRN: new FormControl(),
    companyContactNo: new FormControl('', [Validators.required]),
    companyEmail: new FormControl(''),
    companyWebsite: new FormControl(''),
    address: new FormGroup({
      billingAddress1: new FormControl('', [Validators.required]),
      billingAddress2: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    })
    
  });


  get name() { return this.createNewCompanyFormGroup.get('name'); }
  get companyRN() { return this.createNewCompanyFormGroup.get('companyRN'); }
  get companyContactNo() { return this.createNewCompanyFormGroup.get('companyContactNo'); }
  get companyEmail() { return this.createNewCompanyFormGroup.get('companyEmail'); }
  get companyWebsite() { return this.createNewCompanyFormGroup.get('companyWebsite'); }
  get address() { return this.createNewCompanyFormGroup.get('address'); }
  get billingAddress1() { return this.address.get('billingAddress1'); }
  get billingAddress2() { return this.address.get('billingAddress2'); }
  get country() { return this.address.get('country'); }
  get state() { return this.address.get('state'); }
  get city() { return this.address.get('city'); }
  get postcode() { return this.address.get('postcode'); }


  constructor(
    private stateSer: StateService, 
    private citySer: CityService,
    private companiesSer: CompaniesService,
    ) { }

  ngOnInit(): void {
    this.stateSer.fetchAllState('states').pipe(takeUntil(this.destroy$)).subscribe(x => this.optionsState = x);
  }

  ngOnChanges(changes: SimpleChanges){

    if ('companyValues' in changes && this.companyValues) {
      this.name.setValue(this.companyValues.name);
      this.companyRN.setValue(this.companyValues.company_no);
      this.name.disable();
      this.companyRN.disable();
    }

  }

  onPayment() {
    this.isLoading = true;

    const createCompanyPayload: CreateCompanyProfilePayload = {
      name: this.companyValues.name,
      company_no: this.companyValues.company_no,
      // plan_id: this.package.value,
      contact_email: this.companyEmail.value,
      contact_phone: this.companyContactNo.value,
      whatsapp : '',
      url : this.companyWebsite.value,
      address_line1: this.billingAddress1.value,
      address_line2: this.billingAddress2.value,
      city_id: this.city.value,
      state_id: this.state.value,
      postcode: this.postcode.value,
      country_id: this.country.value,
      images: [],
      business_hours: [],
      specializations: [],
      about: '',
      slug: [],
      document: [],
      facebook:'',
      instagram:'',
      twitter: '',
      linkedin:'',
      wechat: '',
      youtube: '',
      physical_product: 0,
      virtual_product: 0,
      physical_service: 0,
      virtual_service: 0,
    };

    setTimeout(() => {this.isLoading = false}, 5000)

    this.companiesSer.createNewCompany(createCompanyPayload).pipe(
      takeUntil(this.destroy$)
    ).subscribe(x => {
      this.isLoading = false;
      this.isResult = true;
      if (x) {
        this.isSuccess = true;
        this.createCompanyResult = x;
      }
    });

  }

  closeModal(){
    this.closeModalEventEmitter.emit()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
