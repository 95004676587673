<div class="claim-company-profile">
   
    <ng-container *ngIf="isClaimForm; else claimResult">

        <div class="claim-company-form" [ngClass]="{'preloader-trigger': isLoading}">
            <div class="preloader d-flex justify-content-center align-items-center">
                <mdb-spinner spinnerColor="blue"></mdb-spinner>
            </div>
            <app-claim-company-form [checkCompanyRespone]="checkCompanyRespone" (claimCompanyTrigger)="onClaimCompany($event)"></app-claim-company-form>
        </div>
     
        
    </ng-container>
    <ng-template #claimResult>


        <div class="payment-message" >

            <div class="payment-message__title">
                <h5 *ngIf="isSuccess && apiRespone.profile" >Company Name: {{ apiRespone.profile.name }} Company No.: {{ apiRespone.profile.company_no }}</h5>
                <h5 *ngIf="!isSuccess">Creating company profile unsuccessfully..</h5>
            </div>
            <div class="payment-message__body">
                <p *ngIf="isSuccess">Company is now created successfully, will wait for approval, by the way you can continue creating post with draft profile and approval can be don for both.</p>
                <p *ngIf="!isSuccess">Creating company profile unsuccessfully</p>
            </div>
            <div class="payment-message__btns">
                <button type="button" size="md" mdbBtn color="primary" mdbWavesEffect (click)="closeModal()" *ngIf="isSuccess">continue with creating post</button>
                <button type="button" mdbBtn color="danger" mdbWavesEffect (click)="closeModal()" *ngIf="!isSuccess">Finish</button>
            </div>
        </div>

        
    </ng-template>
    

</div>